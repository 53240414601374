import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { CardHeader } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGet } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { setClientContract } from '../../../store/clients';
import DialogAddNewContract from '../../common/dialogs/newContract';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import errorHandler from '../errorHandler';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import AttachNewProductToContract from '../../common/dialogs/attachNewProductToContract';
import { CustomCell } from '../../common/tables/CustomCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableWrapBox = styled(Box)(({ theme }) => ({}));

const RentContract = ({ setRefresh, getActualBalanceOfClient }) => {
  const { itemId } = useParams();
  const { productId } = useParams();
  const open = useSelector((state) => state.layout.open);
  const clientContract = useSelector((state) => state.clients.clientContract);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFetch, setIsFetch] = useState(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [contractId, setContractId] = useState('');
  const [userAccess, setUserAccess] = useState('');

  const getContracts = () => {
    setIsFetch(false);
    apiGet({
      url: `/client-contract/view?id=${itemId}`,
    })
      .then((res) => {
        if (res.error) return;
        dispatch(setClientContract(res.data.client.contract));
        setIsFetch(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    if (itemId === undefined) {
      apiGet({
        url: `/product/view?id=${productId}`,
      })
        .then((res) => {
          if (res.error) return;
          dispatch(setClientContract(res.data.rentalContract.contract));
          setIsFetch(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (productId === undefined) getContracts();
  }, []);

  const deleteContract = (id) => {
    setContractId(id);
    setShowConfirmDeleteDialog(true);
  };

  const confirmDeleteContract = () => {
    apiGet({
      url: `/client-contract/delete?id=${contractId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      getContracts();
      toast.success('Успешно удалено');
      closeDialog();
    });
  };

  const closeDialog = () => {
    setShowConfirmDeleteDialog(false);
  };

  const openContractPage = (id) => {
    navigate(`/contract/${id}`);
  };

  if (!isFetch) {
    return (
      <Card
        raised
        sx={{ my: 2, textAlign: 'left' }}
      >
        <CardHeader title={'Активного договора нет'} />
        {userAccess['client-contract']?.create && (
          <Box sx={{ display: 'flex', m: 1, justifyContent: 'start' }}>
            {itemId === undefined ? (
              <></>
            ) : (
              <DialogAddNewContract
                getContract={getContracts}
                userId={itemId}
              />
            )}
          </Box>
        )}
      </Card>
    );
  } else {
    return (
      <Card
        className={!open ? 'Overflow-box-each-show-menu' : 'Overflow-box-each'}
        sx={{ overflow: 'auto', mt: 2 }}
        raised
      >
        <TableWrapBox>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>Договор</StyledTableCell>

                  <StyledTableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}
                    >
                      Действия
                      {userAccess['client-contract']?.create && (
                        <DialogAddNewContract
                          getContract={getContracts}
                          userId={itemId}
                          isIconButton={true}
                        />
                      )}
                    </Box>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientContract.map((contract) => (
                  <StyledTableRow key={contract.id}>
                    <CustomCell
                      cursor='pointer'
                      onClick={() => openContractPage(contract.id)}
                      align='left'
                    >
                      {contract.number}
                    </CustomCell>

                    <CustomCell align='center'>
                      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <AttachNewProductToContract
                          setRefresh={setRefresh}
                          buttonType={true}
                          contract_Id={contract.id}
                          getActualBalanceOfClient={getActualBalanceOfClient}
                        />
                        {userAccess['client-contract'].delete && (
                          <Tooltip title='Удалить договор'>
                            <IconButton
                              sx={{
                                backgroundColor: '#fff',
                                mr: 1,
                                '&:hover': { backgroundColor: '#c2c2c2' },
                              }}
                              color={'abortButton'}
                              component='span'
                              onClick={() => deleteContract(contract.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </CustomCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapBox>
        <Dialog
          fullWidth
          open={showConfirmDeleteDialog}
          maxWidth='sm'
          scroll='body'
          onClose={closeDialog}
        >
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 2, sm: 4 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Подтвеждаете удаление?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              variant='contained'
              color='abortButton'
              sx={{ marginRight: 1 }}
              onClick={confirmDeleteContract}
            >
              Удалить
            </Button>

            <Button
              variant='contained'
              color='primary'
              onClick={closeDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
};

export default RentContract;
