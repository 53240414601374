import React, { useEffect, useState } from 'react';
import './index.scss';
import Divider from '@mui/material/Divider';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  useMediaQuery,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { theme } from '../../../common/componentColors';
import { apiGet } from '../../../../api';
import errorHandler from '../../../layouts/errorHandler';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNotificationsCounter,
  setAllNotificationsList,
} from '../../../../store/notifications';
import { Link } from 'react-router-dom';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const ArchiveNotifications = () => {
  const userProfile = window.localStorage.getItem('userRole');
  const dispatch = useDispatch();
  const notificationsCounter = useSelector(
    (state) => state.notifications.notificationsCounter,
  );
  const allNotificationList = useSelector(
    (state) => state.notifications.allNotificationsList,
  );
  const [notificationTypeList, setNotificationTypeList] = useState([]);
  const [notificationStatusList, setNotificationStatusList] = useState([]);

  // Пагинация
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  // тип уведомления
  const [typeSearch, setTypeSearch] = useState('');
  const [activeButton, setActiveButton] = useState('');

  // Поиск по названию уведомления
  const [searchField, setSearchField] = useState('');

  // Даты для поиска
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchStopDate, setSearchStopDate] = useState(null);

  //Поиск по статусу просм ,нов
  const [statusSearch, setStatusSearch] = useState('');
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const searchFieldOnChange = (e) => {
    setSearchField(e.target.value);
    setPage(1);
  };

  const PaginationOnChange = (event, value) => {
    setPage(value);
  };

  const startDateSearch = (e) => {
    setSearchStartDate(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setSearchStartDate(arr.join('-'));
    setPage(1);
  };

  const stopDateSearch = (e) => {
    setSearchStopDate(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setSearchStopDate(arr.join('-'));
    setPage(1);
  };

  const statusOnChangeSearch = (e) => {
    setStatusSearch(e.target.value);
    setPage(1);
  };

  const typeOnChangeSearch = (key) => {
    setActiveButton(key);
    setTypeSearch(key);
    setPage(1);
  };

  const clearFilters = () => {
    setStatusSearch('');
    setActiveButton('');
    setSearchField('');
    setSearchStopDate(null);
    setSearchStartDate(null);
    setTypeSearch('');
  };

  const handleChekedNotification = (id) => {
    // Отметить уведомление как прочитанное

    apiGet({
      url: `/notifications/view?id=${id}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
    });
    const newList = allNotificationList.map((i) =>
      i.id === id ? { ...i, status: 2 } : i,
    );
    dispatch(setAllNotificationsList(newList));
    dispatch(setNotificationsCounter(notificationsCounter - 1));
  };

  useEffect(() => {
    // Получить все уведомления
    let url = '';
    searchStartDate === null
      ? (url = `/notifications/index?&type=${typeSearch}&status=${statusSearch}&text=${searchField}&page=${page}`)
      : (url = `/notifications/index?fromDateCreate=${searchStartDate}&toDateCreate=${searchStopDate}&type=${typeSearch}&status=${statusSearch}&text=${searchField}&page=${page}`);

    apiGet({
      url: url,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      // setNotificationList(res.data?.models);
      dispatch(setAllNotificationsList(res.data?.models));
      setLastPage(res.data?.lastPage);
    });

    // Получить все статусы уведомления
    apiGet({
      url: `/notifications/status-list`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setNotificationStatusList(res.data);
    });

    // Получить все типы уведомления
    apiGet({
      url: `/notifications/type-list`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setNotificationTypeList(res.data);
    });
  }, [page, statusSearch, searchStopDate, typeSearch, searchField]);

  return (
    <div>
      {userProfile === 'driver' && (
        <Link
          className='notification-link'
          to='/taskPage'
        >
          <ChevronLeftRoundedIcon className='notification-chevron' />
          <Typography>Назад</Typography>
        </Link>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.between(0, 808)]: {
            flexDirection: 'column-reverse',
            alignItems: 'start',
          },
        }}
      >
        <TextField
          sx={{
            width: '60%',
            [theme.breakpoints.between(0, 808)]: {
              marginTop: 2,
              width: '100%',
            },
          }}
          id='input-with-icon-textfield'
          fullWidth
          value={searchField}
          autoComplete={'false'}
          color={'primary'}
          onChange={searchFieldOnChange}
          placeholder='Что ищем?'
          label='Поиск'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                {searchField !== '' ? (
                  <IconButton onClick={() => setSearchField('')}>
                    <ClearRoundedIcon />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Typography
          sx={{
            [theme.breakpoints.between(460, 1140)]: { fontSize: 30 },
            [theme.breakpoints.between(0, 808)]: {
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            },
            [theme.breakpoints.between(0, 460)]: { fontSize: 25 },
          }}
          variant={'h4'}
          align={'center'}
        >
          Архив уведомлений
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ my: 2, fontSize: 18, fontWeight: 600 }}>
          Всего уведомлений : {allNotificationList?.length}
        </Typography>
        <Tooltip
          title={'Очистить все фильтры'}
          placement={'top'}
        >
          <IconButton
            sx={{ ml: 0.8 }}
            onClick={clearFilters}
          >
            <TuneRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.between(0, 858)]: {
            flexDirection: 'column',
            alignItems: 'start',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            [theme.breakpoints.between(0, 520)]: { flexDirection: 'column' },
          }}
        >
          <TextField
            select
            fullWidth
            sx={{ width: 180 }}
            label={'Статус'}
            defaultValue={''}
            onChange={statusOnChangeSearch}
          >
            {notificationStatusList?.map((i) => (
              <MenuItem
                key={i?.key}
                value={i?.key}
              >
                {i?.value}
              </MenuItem>
            ))}
            <MenuItem value={''}>Все</MenuItem>
          </TextField>
          <Box
            sx={{
              marginLeft: 2,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.between(0, 520)]: {
                marginLeft: 0,
                marginTop: 2,
              },
              [theme.breakpoints.between(0, 1060)]: { marginLeft: 0.8 },
            }}
          >
            {notificationTypeList?.map((i) => (
              <Box
                className='notification-btn'
                sx={{
                  backgroundColor: activeButton === i.key ? '#546e7a' : 'none',
                  color: activeButton === i.key ? '#fff' : 'none',
                  '& hover': {
                    backgroundColor: '#6991a2',
                  },
                  alignItems: 'center',
                  display: 'flex',
                  mx: 0.7,
                  [theme.breakpoints.between(0, 1060)]: { mx: 0.3 },
                }}
                key={i?.key}
                onClick={() => typeOnChangeSearch(i.key)}
                value={i?.key}
              >
                {i?.value}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.between(0, 858)]: { marginTop: 2 },
            [theme.breakpoints.between(0, 460)]: { flexDirection: 'column' },
          }}
        >
          <Box>
            <LocalizationProvider
              localeText={{
                okButtonLabel: 'Ок',
                cancelButtonLabel: 'Отменить',
              }}
              dateAdapter={AdapterDateFns}
              adapterLocale={ru}
            >
              <Box sx={{ mx: 1.5 }}>
                <DatePicker
                  inputProps={{
                    placeholder: 'дд.мм.гггг',
                  }}
                  name='date'
                  label='С какого'
                  minDate={new Date().setMonth(new Date().getMonth() - 1)}
                  value={searchStartDate}
                  onChange={(newValue) => startDateSearch(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.between(0, 460)]: {
                marginLeft: 0,
                marginTop: 2,
              },
            }}
          >
            <LocalizationProvider
              localeText={{
                okButtonLabel: 'Ок',
                cancelButtonLabel: 'Отменить',
              }}
              dateAdapter={AdapterDateFns}
              adapterLocale={ru}
            >
              <Box>
                <DatePicker
                  inputProps={{
                    placeholder: 'дд.мм.гггг',
                  }}
                  name='date'
                  label='По какое'
                  minDate={new Date().setMonth(new Date().getMonth() - 1)}
                  value={searchStopDate}
                  onChange={(newValue) => stopDateSearch(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }}>Уведомления</Divider>
      {allNotificationList?.length !== 0 ? (
        <>
          {allNotificationList?.map((item) => (
            <Card
              raised
              key={item?.id}
              sx={{
                my: 2,
                py: 2,
                px: 5,
                backgroundColor: item?.status === 1 && 'rgba(156,215,162,0.7)',
                [theme.breakpoints.between(0, 858)]: { px: 1.5 },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    [theme.breakpoints.between(0, 460)]: {
                      flexDirection: 'column',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '600',
                      [theme.breakpoints.between(0, 808)]: { fontSize: 14 },
                      [theme.breakpoints.between(0, 460)]: { fontSize: 12 },
                    }}
                  >
                    {item?.text}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      mr: 5,
                      ml: 10,
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      [theme.breakpoints.between(0, 460)]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                        mx: 0.9,
                        ml: 2,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '600',
                        [theme.breakpoints.between(0, 808)]: { fontSize: 14 },
                        [theme.breakpoints.between(0, 460)]: {
                          fontSize: 12,
                          textAlign: 'center',
                        },
                      }}
                    >
                      {item?.createdAt.split(' ')[0].split('-').join('.')}
                    </Typography>
                    <Typography
                      sx={{
                        mx: 3,
                        fontWeight: '600',
                        [theme.breakpoints.between(0, 808)]: { fontSize: 14 },
                        [theme.breakpoints.between(0, 460)]: {
                          fontSize: 12,
                          textAlign: 'center',
                        },
                      }}
                    >
                      {/*{item?.createdAt.slice(0, -3).split(' ')[1]}*/}
                      {item?.createdAt.split(' ')[1]}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      disabled={item.status === 2 && true}
                      onClick={() => handleChekedNotification(item.id)}
                      sx={{
                        display: 'flex',
                        float: 'right',
                        marginTop: 1,
                        [theme.breakpoints.between(0, 670)]: {
                          display: 'none',
                        },
                        [theme.breakpoints.between(670, 99670)]: {
                          display: 'flex',
                        },
                      }}
                    >
                      {item?.status === 1 ? 'Посмотреть' : 'Просмотрено'}
                    </Button>
                    <IconButton
                      disabled={item?.status === 2 && true}
                      onClick={() => handleChekedNotification(item?.id)}
                      sx={{
                        display: 'flex',
                        float: 'right',
                        marginTop: 1,
                        [theme.breakpoints.between(0, 670)]: {
                          display: 'flex',
                          color: item?.status === 1 && '#0288d1',
                          width: 14,
                          height: 14,
                        },
                        [theme.breakpoints.between(670, 99670)]: {
                          display: 'none',
                        },
                      }}
                    >
                      {item?.status === 1 ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Card>
          ))}
        </>
      ) : (
        <Typography
          sx={{ [theme.breakpoints.between(0, 760)]: { textAlign: 'center' } }}
          variant={'h6'}
        >
          Ничего не найдено &#129300;
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {allNotificationList?.length !== 0 && (
          <Pagination
            page={page}
            onChange={PaginationOnChange}
            count={lastPage}
            size={!matches ? 'small' : 'medium'}
            siblingCount={!matches ? 0 : 1}
            variant='outlined'
            shape='rounded'
          />
        )}
      </Box>
    </div>
  );
};

export default ArchiveNotifications;
