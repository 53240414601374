import { useDispatch, useSelector } from 'react-redux';
import { setUsersList } from '../../../../store/user';
import { apiGet } from '../../../../api';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Pagination, Stack, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { setShowingDialog } from '../../../../store/app';
import ConfirmDialog from '../../dialogs/confirm';
import { useForm } from 'react-hook-form';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#d0d0d0',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const TableWrapBox = styled(Paper)(({ theme }) => ({}));

const StaffTable = () => {
  const open = useSelector((state) => state.layout.open);
  // Для поиска по таблице
  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [lastNameSearch, setLastNameSearch] = useState('');
  const [roleSearch, setRoleSearch] = useState('');
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const [employId, setEmployId] = useState('');

  const [userAccess, setUserAccess] = useState('');
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.user.userList);
  const allRoles = useSelector((state) => state.user.allRoles);

  const navigate = useNavigate();

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const changePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);
  }, []);

  const searchRole = (e) => {
    setRoleSearch(e.target.value);
    setPage(1);
  };
  const searchEmail = (e) => {
    setEmailSearch(e.target.value);
    setPage(1);
  };
  const searchName = (e) => {
    setNameSearch(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    apiGet({
      url: `/user/index?page=${page}&limit=${6}&fullName=${nameSearch}&lastname=${lastNameSearch}&email=${emailSearch}&role=${roleSearch}`,
    }).then((res) => {
      if (res.error) return toast.error('Ошибка получения пользователей');
      dispatch(setUsersList(res.data.models));
      setLastPage(res.data.lastPage);
    });
  }, [page, nameSearch, lastNameSearch, emailSearch, roleSearch]);

  const deleteUser = async (employeerId) => {
    setEmployId(employeerId);
    dispatch(setShowingDialog('user-delete'));
  };

  const confirmHandleClick = () => {
    apiGet({
      url: `/user/delete?id=${employId}`,
    }).then((res) => {
      if (res.error) return toast.error('Ошибка удаления');

      apiGet({
        url: `/user/index?page=${page}&limit=${5}&username=${nameSearch}&lastname=${lastNameSearch}&email=${emailSearch}&role=${roleSearch}`,
      }).then((res) => {
        if (res.error) return toast.error('Ошибка получения пользователей');
        dispatch(setUsersList(res.data.models));
        setLastPage(res.data.lastPage);
      });
      toast.success('Успешно удален');
    });
  };

  const changeToUserPageHandle = (id) => {
    navigate(`/user/${id}`);
  };

  return (
    <div>
      <TableWrapBox
        className={!open ? 'Overflow-box-show-menu' : 'Overflow-box'}
        sx={{ overflow: 'auto' }}
      >
        <Paper>
          <TableContainer>
            <Table
              sx={{ minWidth: 700 }}
              aria-label='customized table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>
                    <TextField
                      label={'Почта'}
                      onChange={searchEmail}
                    />
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    <TextField
                      label={'Фамилия Имя'}
                      onChange={searchName}
                    />
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    <TextField
                      fullWidth
                      defaultValue={''}
                      label={'Роль'}
                      onChange={searchRole}
                      select
                    >
                      {allRoles.map((item) => (
                        <MenuItem
                          key={item.role}
                          value={item.role}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                      <MenuItem value={''}>Все</MenuItem>
                    </TextField>
                  </StyledTableCell>
                  {userAccess?.user?.delete && (
                    <StyledTableCell align='left'>Действия</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {userList.map((user) => (
                  <StyledTableRow key={user.id}>
                    <StyledTableCell
                      sx={{ cursor: 'pointer' }}
                      onClick={() => changeToUserPageHandle(user.id)}
                      align='left'
                    >
                      {user.email}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ cursor: 'pointer' }}
                      onClick={() => changeToUserPageHandle(user.id)}
                      align='left'
                    >
                      {user.profile?.surname} {user.profile?.name}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ cursor: 'pointer' }}
                      onClick={() => changeToUserPageHandle(user.id)}
                      align='left'
                    >
                      {user.role[0].roleTranslate}
                    </StyledTableCell>

                    {userAccess?.user?.delete && (
                      <StyledTableCell align='left'>
                        <Box sx={{ display: 'block' }}>
                          {/*<IconButton*/}
                          {/*    color="primary"*/}
                          {/*    component={RouterLink}*/}
                          {/*    to={"/user/" + user.id}*/}
                          {/*>*/}
                          {/*    <RemoveRedEyeIcon/>*/}
                          {/*</IconButton>*/}
                          <IconButton
                            sx={{
                              backgroundColor: '#fff',
                              mr: 1,
                              '&:hover': { backgroundColor: '#c2c2c2' },
                            }}
                            color={'abortButton'}
                            component='span'
                            onClick={() => deleteUser(user.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <ConfirmDialog
            title={'Удалить пользователя?'}
            name='user-delete'
            buttonTitle={'Удалить'}
            setLastPage={setLastPage}
            event={confirmHandleClick}
          />
        </Paper>
      </TableWrapBox>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          page={page}
          siblingCount={!matches ? 0 : 1}
          onChange={changePage}
          count={lastPage}
          variant='outlined'
          shape='rounded'
        />
      </Box>
    </div>
  );
};

export default StaffTable;
