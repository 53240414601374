export const selectRolesParse = (data) => {
  if (!data) return [];

  return data.map((el) => ({ value: el.name, key: el.role }));
};

export const selectUsersParse = (data) => {
  if (!data) return [];

  return data.map((el) => ({
    value: `${el.profile?.surname} ${el.profile?.name}`,
    key: el.id,
  }));
};

export const selectCategoryParse = (data) => {
  if (!data) return [];

  return data.map((el) => ({ value: el.name, key: el.id }));
};
