import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryList: [],
};

const rentObjectsSlice = createSlice({
  name: 'rentObjects',
  initialState,
  reducers: {
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
  },
});

export const { setCategoryList } = rentObjectsSlice.actions;

export default rentObjectsSlice.reducer;
