import React, { useEffect, useState } from 'react';
import { apiGet, apiPost } from '../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { setUserFullName, setUserProfile } from '../../../../store/user';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { TextFieldController } from '../../../common/formControllers';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import errorHandler from '../../../layouts/errorHandler';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';
import { CircularProgress, Stack } from '@mui/material';
import { Spinner } from '../../../common/spinner/Spinner';

const Profile = () => {
  const dispatch = useDispatch();
  const [isFetch, setIsFetch] = useState(false);
  const { userProfile, userFullName } = useSelector((state) => state.user);
  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const getUserProfile = () => {
    setIsFetch(true);
    apiGet({
      url: '/user/auth',
    }).then((res) => {
      dispatch(setUserProfile(res.data));
      dispatch(setUserFullName(res.data.profile));
      setIsFetch(false);
    });
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const submit = (data) => {
    for (let key in data) {
      if (data[key] === undefined) delete data[key];
    }

    apiPost({
      url: `/user-profile/update?id=${userProfile.id}`,
      postData: data,
    })
      .then((res) => {
        if (res.error) return errorHandler(res.data);
        toast.success('Обновлено');
        dispatch(setUserFullName(res.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (isFetch) {
    return <Spinner />;
  }
  return (
    <div>
      <HeaderPage title='Ваш профиль' />

      <form onSubmit={handleSubmit(submit)}>
        <Stack spacing={3}>
          <TextFieldController
            defaultValue={userFullName?.name}
            name={'name'}
            control={control}
            label={'Имя'}
          />

          <TextFieldController
            defaultValue={userFullName?.surname}
            name={'surname'}
            control={control}
            label={'Фамилия'}
          />

          <TextFieldController
            defaultValue={userFullName?.patronymic}
            name={'patronymic'}
            control={control}
            label={'Отчество'}
          />

          <Button
            sx={{ maxWidth: 180 }}
            type={'submit'}
            variant={'contained'}
            color={'secondary'}
          >
            Сохранить
          </Button>
        </Stack>
      </form>
    </div>
  );
};

export default Profile;
