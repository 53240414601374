import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { apiGet, apiPost } from '../../../../api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserFullName, setUserRole } from '../../../../store/user';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';

const defaultValues = {
  password: '12345678',
  email: 'it@wocom.biz',
};

const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: 400,
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 400,
  },
  [theme.breakpoints.between(0, 600)]: {
    minWidth: 300,
  },
}));
const EmailInput = styled(TextField)`
  & label.Mui-focused {
    color: #b2dfdb;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #b2dfdb;
    }
    &:hover fieldset {
      border-color: #819ca9;
    }
  }
`;
const PasswordInput = styled(TextField)`
  & label.Mui-focused {
    color: #b2dfdb;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #b2dfdb;
    }
    &:hover fieldset {
      border-color: #819ca9;
    }
  }
`;

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'all',
  });

  const submit = (submitData) => {
    apiPost({
      url: '/auth/login',
      postData: submitData,
    })
      .then((res) => {
        if (res.error) {
          return setError('email', {
            type: 'manual',
            message: 'Неправильная почта или пароль',
          });
        } else {
          const token = res.data.access_token;
          window.localStorage.setItem('userRole', res.data.user.role[0].role);
          window.localStorage.setItem('authToken', token);
          dispatch(setUserRole(res.data.user.role[0].role));
          dispatch(setUserFullName(res.data.user.profile));
          toast.success('Успешный вход');

          apiGet({
            url: '/access-user/index',
          }).then((res) => {
            if (res.error) {
              toast.error('Ошибка входа');

              return console.log(
                'нужно обработать ошибку из user redux async thunk',
              );
            }
            window.localStorage.setItem('userAccess', JSON.stringify(res));
            navigate('/', { replace: true });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.paper',
      }}
    >
      <BoxWrapper>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 12,
            minWidth: '24vw',
          }}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(submit)}
        >
          <FormControl fullWidth>
            <Controller
              name='email'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Необходимо заполнить поле Почта',
                },
              }}
              render={({ field: { value, onChange, onBlur } }) => (
                <EmailInput
                  autoFocus
                  type={'email'}
                  label='Почта'
                  inputProps={{ className: 'form_email' }}
                  value={value || ''}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.email)}
                  placeholder='admin@email.com'
                />
              )}
            />
            {errors.email && (
              <FormHelperText sx={{ color: 'error.main' }}>
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            {/*<InputLabel htmlFor="auth-login-v2-password" error={Boolean(errors.password)}>*/}
            {/*  Пароль*/}
            {/*</InputLabel>*/}
            <Controller
              name='password'
              control={control}
              rules={{ required: 'Необходимо заполнить поле "Пароль"' }}
              render={({ field: { value, onChange, onBlur } }) => (
                <PasswordInput
                  value={value || ''}
                  onBlur={onBlur}
                  label='Пароль'
                  onChange={onChange}
                  id='auth-login-v2-password'
                  error={Boolean(errors.password)}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errors.password && (
              <FormHelperText
                sx={{ color: 'error.main' }}
                id=''
              >
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>

          <Button
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              backgroundColor: '#b2dfdb',
              ':hover': { backgroundColor: 'rgba(178,223,219,0.6)' },
            }}
          >
            Войти
          </Button>
        </form>
      </BoxWrapper>
    </Box>
  );
};

export default LoginPage;
