import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { useState, forwardRef, useEffect } from 'react';
import { setAllRoles, setUsersList } from '../../../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { apiGet, apiPost } from '../../../../api';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';
import Fade from '@mui/material/Fade';
import errorHandler from '../../../layouts/errorHandler';
import { styled } from '@mui/material/styles';
import { CloseOutlined } from '@mui/icons-material';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const StyledBox = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between(0, 767)]: {
    width: '100%',
    textAlign: 'center',
  },
}));

const DialogAddNewUser = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const allRoles = useSelector((state) => state.user.allRoles);
  const { handleSubmit, control, reset } = useForm();
  const [disableButton, setDisableButton] = useState(false);

  const closeDialog = () => {
    setIsVisible(false);
    reset();
  };

  const createNewUser = (formData) => {
    setDisableButton(true);
    apiPost({
      url: '/user/create',
      postData: formData,
    })
      .then((res) => {
        if (res.error) {
          Object.keys(res.data).map((key) => {
            errorHandler(res.data[key][0]);
          });
          setDisableButton(false);
        } else {
          toast.success('Пользователь создан');
          closeDialog();
          setDisableButton(false);
        }

        apiGet({
          url: 'user/index',
        })
          .then((res) => {
            if (res.error) {
              Object.keys(res.data).map((key) => {
                errorHandler(res.data[key][0]);
              });
            } else {
              dispatch(setUsersList(res.data.models));
            }
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    apiGet({
      url: '/user-role/index',
    }).then((res) => {
      dispatch(setAllRoles(res.data));
    });
  }, []);

  return (
    <div>
      <StyledBox sx={{ textAlign: 'right' }}>
        <Button
          color={'secondary'}
          variant='contained'
          onClick={() => setIsVisible(true)}
        >
          Создать сотрудника
        </Button>
      </StyledBox>

      <Dialog
        fullWidth
        open={isVisible}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit(createNewUser)}
        >
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <IconButton
              size='small'
              onClick={closeDialog}
              sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
            >
              <CloseOutlined />
            </IconButton>

            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Добавить нового пользователя
              </Typography>
            </Box>

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                sm={4}
                xs={12}
              >
                <Controller
                  name='surname'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      value={value || ''}
                      label='Фамилия'
                      onBlur={onBlur}
                      onChange={onChange}
                      name='surname'
                      required={true}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
              >
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      value={value || ''}
                      label='Имя'
                      onBlur={onBlur}
                      onChange={onChange}
                      name='name'
                      required={true}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
              >
                <Controller
                  name='patronymic'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      value={value || ''}
                      label='Отчество'
                      onBlur={onBlur}
                      onChange={onChange}
                      name='patronymic'
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
              >
                <Controller
                  name='email'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      value={value || ''}
                      label='Почта'
                      onBlur={onBlur}
                      onChange={onChange}
                      name='email'
                      required={true}
                      fullWidth
                      placeholder='Email пользователя'
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
              >
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      value={value || ''}
                      label='Пароль'
                      onBlur={onBlur}
                      onChange={onChange}
                      required={true}
                      name='password'
                      fullWidth
                      placeholder='Пароль пользователя'
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FormControl fullWidth>
                  <Controller
                    name='role'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        select
                        fullWidth
                        label='Выбрать роль'
                        value={value || ''}
                        onBlur={onBlur}
                        required={true}
                        onChange={onChange}
                      >
                        {allRoles.map((role) => (
                          <MenuItem
                            value={role.role}
                            key={role.role}
                          >
                            {role.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              disabled={disableButton}
              variant='contained'
              sx={{ marginRight: 1 }}
              type={'submit'}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={closeDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogAddNewUser;
