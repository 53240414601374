import { format } from 'date-fns';

export const dateReverseFormat = (value, separator = '-') => {
  if (!value) return '';
  return value.split('.').reverse().join(separator);
};

export const dateFormat = (value, formatDate = 'dd.MM.yyyy') => {
  if (!value) return '';

  const newDate = new Date(value);
  return format(newDate, formatDate);
};
