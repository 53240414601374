import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Checkbox, DialogTitle, FormControlLabel, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import { apiGet, apiPost } from '../../../api';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClientInfo,
  setClientStatus,
  setClientTypeStatus,
} from '../../../store/clients';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextFieldController } from '../../common/formControllers';
import InputMask from 'react-input-mask';
import errorHandler from '../errorHandler';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import RentContract from '../rentContract';
import ClientRentObjects from '../clientRentObjects';
import { PartySuggestions } from 'react-dadata';
import './eachClient.scss';
import 'react-dadata/dist/react-dadata.css';
import { setProductStatusList } from '../../../store/products';
import { editBalance } from '../../../helpers/BalanceHelper';
import { setRefetch } from '../../../store/refetch/refetch';
import { ClientPaymentTransactionsTable } from './elems/clientPaymentTransactionsTable';
import { log10 } from 'chart.js/helpers';
import { PermissionWrapper } from '../../common/permissionWrapper/PermissionWrapper';
// import '../../pages/main/eachClient/styles/index.scss'

const EachClientInfo = () => {
  const { itemId } = useParams();
  const navigator = useNavigate();
  const { refetch } = useSelector((state) => state.refetch);
  const [isFetch, setIsFetch] = useState(false);
  const [type, setType] = useState(1);
  const [checkBoxHandler, setCheckBoxHandler] = useState(false);
  const [checkBoxHandlerEmail, setCheckBoxHandlerEmail] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [clientCheckBoxHandler, setClientCheckBoxHandler] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonTransaction, setDisableButtonTransaction] =
    useState(false);
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.clients.clientInfo);

  // Телефон и почта
  const [clientPhone, setClientPhone] = useState('');
  const [newClientPhone, setNewClientPhone] = useState('');
  const [clientType, setClientType] = useState(1);
  const [clientPhoneList, setClientPhoneList] = useState([]);
  const [newClientPhoneList, setNewClientPhoneList] = useState([]);
  const [clientEmail, setClientEmail] = useState('');
  const [clientEmailList, setClientEmailList] = useState([]);

  const [userRole, setUserRole] = useState('');
  const [userAccess, setUserAccess] = useState('');

  // Проверка для показа поля телефона для добавления номера
  // const [isPhoneCheck, setIsPhoneCheck] = useState(false)

  // Все поля после поиска организации
  const [innAfterSearch, setInnAfterSearch] = useState(null);
  const [addressAfterSearch, setAddressAfterSearch] = useState(null);
  const [companyAfterSearch, setCompanyAfterSearch] = useState(null);
  const [supervisorAfterSearch, setSupervisorAfterSearch] = useState(null);
  const [ogrnAfterSearch, setOgrnAfterSearch] = useState(null);
  const [signatoryAfterSearch, setSignatoryAfterSearch] = useState(null);
  const [addressSuggestionsValue, setAddressSuggestionsValue] = useState([]);

  // Список всех организаций
  const [allCompanyList, setAllCompanyList] = useState([]);

  // Id типа оплаты клиента
  const [howClientPay, setHowClientPay] = useState(null);

  // Баланс клиента
  const [totalBalance, setTotalBalance] = useState('');
  const [openBalanceChangeDialog, setOpenBalanceChangeDialog] = useState(false);
  const [amountOfClientBalance, setAmountOfClientBalance] = useState('');
  const [descriptionOfClientBalance, setDescriptionOfClientBalance] =
    useState('');

  // Обновить доп соглашения после создания нового
  const [refresh, setRefresh] = useState(false);
  const [checkChangeRentContract, setCheckChangeRentContract] = useState('');

  // Меняет текст при скролле
  const [colorInnSearchText, setColorInnSearchText] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handleClientChangeCheckBox = (event) => {
    setClientCheckBoxHandler(event.target.checked);
    dispatch(setClientStatus(event.target.checked));
  };

  const handleChangeCheckBox = (event) => {
    setCheckBoxHandler(event.target.checked);
  };
  const handleChangeCheckBoxEmail = (event) => {
    setCheckBoxHandlerEmail(event.target.checked);
  };

  const submit = (clientData) => {
    let url = '';
    clientData.type = type;

    if (clientCheckBoxHandler === false) clientData.status = 1;

    if (clientCheckBoxHandler === true && clientInfo.status === 1)
      clientData.status = 2;

    if (clientData.manager_id === undefined && itemId !== 'new')
      clientData.manager_id = clientInfo.manager.id;

    for (let key in clientData) {
      if (clientData[key] === undefined) delete clientData[key];
    }

    clientData.contract_type = howClientPay;

    if (addressAfterSearch) clientData.the_address = addressAfterSearch;

    if (innAfterSearch) clientData.inn = innAfterSearch;

    if (companyAfterSearch) clientData.company = companyAfterSearch;

    if (supervisorAfterSearch) clientData.supervisor = supervisorAfterSearch;

    if (ogrnAfterSearch) clientData.requisites = ogrnAfterSearch;

    if (signatoryAfterSearch)
      clientData.position_of_the_signatory = signatoryAfterSearch;

    if (!ogrnAfterSearch.split(' ').includes('undefined'))
      clientData.requisites = ogrnAfterSearch;

    if (ogrnAfterSearch.split(' ').includes('undefined'))
      clientData.requisites = clientInfo?.requisites;

    if (checkBoxHandler === false) clientData.problem_client = 1;

    if (checkBoxHandler === true) clientData.problem_client = 2;

    if (checkBoxHandlerEmail === false) clientData.message_email = false;

    if (checkBoxHandlerEmail === true) clientData.message_email = true;

    if (itemId === 'new') {
      const uniqNum = Boolean(
        newClientPhoneList.some((el) => el !== newClientPhone),
      );

      if (!uniqNum && newClientPhoneList.length) {
        toast.error('Такой номер уже есть');
        return;
      }
      if (!newClientPhone || newClientPhone.length < 18) {
        toast.error('Необходимо заполнить номер');
        return;
      }
      clientData.phone = newClientPhoneList.length
        ? [...newClientPhoneList, newClientPhone]
        : [newClientPhone];
      url = '/client/create';
    }

    if (checkBoxHandlerEmail) clientData.sending_by_mail = 1;

    if (!checkBoxHandlerEmail) clientData.sending_by_mail = 2;

    if (itemId !== 'new') url = `/client/update?id=${itemId}`;

    if (itemId === 'new') clientData.email = clientEmail;

    apiPost({
      url: url,
      postData: clientData,
    }).then((res) => {
      if (res.error) {
        setIsFetch(true);
        return errorHandler(res.data);
      } else {
        toast.success('Информация сохранена');
        dispatch(setClientInfo(res.data));
        setIsFetch(true);
        window.scrollTo(0, 0);
        if (itemId === 'new') {
          setTimeout(() => {
            navigator(`/client/${res.data.id}`);
            getAllEmailListId(res.data.id);
            setClientEmail('');
            setNewClientPhoneList([]);
          }, 500);
          window.scrollTo(0, 0);
        }
      }
    });
  };

  // Получить актуальный баланс клиента
  const getActualBalanceOfClient = () => {
    apiGet({
      url: `/client-balance/view-balance?id=${itemId}`,
    }).then((res) => {
      if (res.error) return;
      setTotalBalance(res.data);
    });
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );

    setUserAccess(userAccessLocal);

    if (itemId === 'new') setClientCheckBoxHandler(true);

    // Получить актуальный баланс клиента
    getActualBalanceOfClient();

    // Получение списка названий внутренних фирм
    apiGet({
      url: `/client/contract-type-list`,
    }).then((res) => {
      if (res.error) return;
      setAllCompanyList(res.data);
    });

    dispatch(setClientInfo([]));

    const role = window.localStorage.getItem('userRole');
    setUserRole(role);

    // Получить всеx менеджеров системы
    apiGet({
      url: '/user/index?role=manager',
    }).then((res) => {
      if (res.error) return toast.error('ошибка получения ролей');
      setManagerList(res.data.models);
    });

    // Сравнение - если мы создаем нового клиента
    if (itemId === 'new') return setIsFetch(true);

    getAllEmailList();

    // Сохранение всех данных клиента
    apiGet({
      url: `/client/view?id=${itemId}`,
    }).then((res) => {
      if (res.error) {
        if (res.status === 404) navigator('/404');

        return errorHandler(res.data);
      }

      dispatch(setClientInfo(res.data));

      if (res.data.status !== 1) {
        dispatch(setClientStatus(true));
        setClientCheckBoxHandler(true);
      }

      if (res.data.type === 1 || res.data.type === null) setType(1);

      if (res.data.type === 2) {
        dispatch(setClientTypeStatus(2));
        setType(2);
      }

      if (res.data.problem_client === 1) setCheckBoxHandler(false);
      else setCheckBoxHandler(true);

      if (res.data.sending_by_mail === 2) setCheckBoxHandlerEmail(false);
      else setCheckBoxHandlerEmail(true);

      setIsFetch(true);
    });
  }, []);

  const addEmailOnChange = (e) => {
    setClientEmail(e.target.value);
  };

  const addPhoneNumberOnChange = (e) => {
    setClientPhone(e.target.value);
  };

  const addPhoneNumberNewClient = () => {
    const uniqNum = Boolean(
      newClientPhoneList.some((el) => el !== newClientPhone),
    );

    if (!uniqNum && newClientPhoneList.length) {
      toast.error('Такой номер уже есть');
      return;
    }
    if (!newClientPhone || newClientPhone.length < 18) {
      toast.error('Необходимо заполнить номер');
      return;
    }
    setNewClientPhoneList((prev) => [...prev, newClientPhone]);
    setNewClientPhone('');
  };

  const removePhoneNumberNewClient = (str) => {
    const result = newClientPhoneList.filter((el) => el !== str);
    setNewClientPhoneList(result);
  };

  const deleteItem = (id, item) => {
    if (item === 'phone') {
      // Удалить номер телефона клиента
      apiGet({
        url: `/client-phone/delete?id=${id}`,
      }).then((res) => {
        if (res.error) return;

        // Получение всех телефонов клиента - обновление
        apiGet({
          url: `/client-phone/index?client_id=${itemId}`,
        }).then((res) => {
          if (res.error) return setClientPhoneList([]);
          setClientPhoneList(res.data.models);
        });
        toast.success('Телефон удален');
      });
    }
    if (item === 'email') {
      // Удаление почты клиента
      apiGet({
        url: `/client-email/delete?id=${id}`,
      }).then((res) => {
        if (res.error) return toast.error('Ошибка удалении почты');

        // Получение всех почт клиента - обновление
        apiGet({
          url: `/client-email/index?client_id=${itemId}`,
        }).then((res) => {
          if (res.error) return;
          setClientEmailList(res.data.models);
        });
        toast.success('Почта удалена');
      });
    }
  };

  const getAllEmailListId = (id) => {
    // Инфо о всех почтовых адресах конкретного клиента
    apiGet({
      url: `/client-email/index?client_id=${id}`,
    }).then((res) => {
      if (res.error) return;
      setClientEmailList(res.data.models);
    });
  };

  const getAllEmailList = () => {
    // Инфо о всех почтовых адресах конкретного клиента
    apiGet({
      url: `/client-email/index?client_id=${itemId}`,
    }).then((res) => {
      if (res.error) return;
      setClientEmailList(res.data.models);
    });
  };

  const addEmail = () => {
    let data = {};
    data.email = clientEmail;
    setClientEmail('');

    // Создание почты у конкретного клиента
    apiPost({
      url: `/client-email/create?id=${itemId}`,
      postData: data,
    }).then((res) => {
      if (res.error) return toast.error(res.data.email);
      getAllEmailList();
    });
  };

  useEffect(() => {
    if (itemId !== 'new') {
      getClientNumbers();
      setHowClientPay(clientInfo?.contract_type);
    }
  }, [itemId]);

  const getClientNumbers = () => {
    apiGet({
      url: `/client-phone/index?client_id=${itemId}`,
    }).then((res) => {
      if (res.error) return toast.error('Ошибка получения номера телефона');
      setClientPhoneList(res.data.models);
    });
  };

  const addPhoneNumber = (check) => {
    if (check) return;
    let data = {};
    data.phone = clientPhone;

    setClientPhone('');

    // Создание телефона у конкретного клиента
    apiPost({
      url: `/client-phone/create?id=${itemId}`,
      postData: data,
    }).then((res) => {
      if (res.error) return toast.error(res.data.phone);
      toast.success('Номер добавлен');

      // Инфо о всех телефонов конкретного клиента
      getClientNumbers();
    });
  };

  // START---- Изменения состояний после поиска ИНН
  const innHandleChange = (e) => {
    setInnAfterSearch(e.target.value);
  };

  const addressOnChange = (e) => {
    setAddressAfterSearch(e.target.value);
  };

  const companyOnChange = (e) => {
    setCompanyAfterSearch(e.target.value);
  };

  const supervisorOnChange = (e) => {
    setSupervisorAfterSearch(e.target.value);
  };

  const ogrnOnChange = (e) => {
    setOgrnAfterSearch(e.target.value);
  };

  const signatoryOnChange = (e) => {
    setSignatoryAfterSearch(e.target.value);
  };
  // END------ Изменения состояний после поиска ИНН

  useEffect(() => {
    setInnAfterSearch(addressSuggestionsValue?.data?.inn);
    setAddressAfterSearch(addressSuggestionsValue?.data?.address?.value);
    setCompanyAfterSearch(addressSuggestionsValue?.value);
    setSupervisorAfterSearch(addressSuggestionsValue?.data?.management?.name);

    // Все реквизиты в одном поле
    setOgrnAfterSearch(
      `КПП: ${addressSuggestionsValue?.data?.kpp} \nОГРН: ${addressSuggestionsValue?.data?.ogrn} \nОКАТО: ${addressSuggestionsValue?.data?.okato} \n `,
    );

    setSignatoryAfterSearch(addressSuggestionsValue?.data?.management?.post);
  }, [addressSuggestionsValue]);

  const howClientPayOnChange = (e) => {
    setHowClientPay(e.target.value);
  };

  const typeOnChange = (e) => {
    setType(e.target.value);
    if (e.target.value === 2) {
      window.scroll({
        top: -200,
        behavior: 'smooth',
      });
      setColorInnSearchText(true);
    }
    dispatch(setClientTypeStatus(e.target.value));
  };

  const closeDialog = () => {
    setOpenBalanceChangeDialog(false);
    setAmountOfClientBalance('');
    setDescriptionOfClientBalance('');
  };

  const confirmChangeBalanceHandler = (upDown) => {
    const data = {};
    let url = '';
    data.amount = amountOfClientBalance;
    data.description = descriptionOfClientBalance;
    setDisableButtonTransaction(true);
    if (upDown === 'up') url = `/client-balance/add?id=${itemId}`;
    if (upDown === 'down') url = `/client-balance/down?id=${itemId}`;

    apiPost({
      url,
      postData: data,
    }).then((res) => {
      if (res.error) {
        setDisableButtonTransaction(false);
        return errorHandler(res.data);
      } else {
        toast.success('Баланс изменен');
        getActualBalanceOfClient();
        dispatch(setRefetch(!refetch));
        closeDialog();
        setDisableButtonTransaction(false);
      }
    });
  };
  useEffect(() => {
    apiGet({
      url: '/product/status-list',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      dispatch(setProductStatusList(res.data));
    });
  });

  if (!isFetch) {
    return <></>;
  } else {
    return (
      <Box>
        {type === 2 && (
          <>
            <div>
              <Divider
                sx={
                  !colorInnSearchText ? { my: 2 } : { my: 2, color: '#239113' }
                }
                textAlign={'center'}
              >
                <strong
                  style={{ color: colorInnSearchText ? '#239113' : '#546e7a' }}
                >
                  Поиск по Инн или названию организации
                </strong>
              </Divider>
              <div>
                <PartySuggestions
                  inputProps={{
                    className: 'dadata_input',
                    placeholder: 'Введите название огранизации',
                  }}
                  token='109634391ea4848b9daaad09a62dae411b81b718'
                  value={addressSuggestionsValue}
                  onChange={setAddressSuggestionsValue}
                />
              </div>
            </div>
            <br />
            <Divider />
            <br />
          </>
        )}
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <div>
              <Box sx={{ '& .MuiTextField-root': { m: 2 } }}>
                <form onSubmit={handleSubmit(submit)}>
                  <Card
                    raised
                    sx={{ my: 2 }}
                  >
                    {clientCheckBoxHandler && itemId !== 'new' ? (
                      <>
                        <Divider>Баланс</Divider>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            m: 2,
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <Typography variant={'h6'}>
                              {editBalance(totalBalance || '0')}
                            </Typography>
                          </div>
                          <PermissionWrapper
                            permits={['client-balance', 'add']}
                          >
                            <Button
                              size='small'
                              variant={'contained'}
                              onClick={() => setOpenBalanceChangeDialog(true)}
                            >
                              Изменить баланс
                            </Button>
                          </PermissionWrapper>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                    <Divider sx={{ mt: 2 }}>Информация</Divider>

                    <TextFieldController
                      name={'surname'}
                      commentRequest={!userAccess?.client?.update}
                      defaultValue={clientInfo?.surname}
                      control={control}
                      label='Фамилия'
                    />

                    <TextFieldController
                      name={'name'}
                      commentRequest={!userAccess?.client?.update}
                      defaultValue={clientInfo?.name}
                      control={control}
                      label='Имя *'
                    />

                    <TextFieldController
                      name={'patronymic'}
                      commentRequest={!userAccess?.client?.update}
                      defaultValue={clientInfo?.patronymic}
                      control={control}
                      label='Отчество'
                    />

                    <FormControlLabel
                      disabled={!userAccess?.client?.update}
                      name='checkbox'
                      sx={{ ml: 1 }}
                      label='Проблемный'
                      control={<Checkbox checked={checkBoxHandler} />}
                      onChange={handleChangeCheckBox}
                    />

                    <TextFieldController
                      maxRows={4}
                      commentRequest={!userAccess?.client?.update}
                      multiline={true}
                      name={'comment'}
                      defaultValue={clientInfo?.comment}
                      control={control}
                      label='Комментарий'
                    />

                    {userRole === 'admin' ? (
                      <FormControl fullWidth>
                        <Controller
                          name='manager_id'
                          control={control}
                          render={({ field: { onChange, onBlur } }) => (
                            <TextField
                              disabled={!userAccess?.client?.update}
                              select
                              onBlur={onBlur}
                              onChange={onChange}
                              // required={true}
                              name='manager_id'
                              defaultValue={
                                itemId === 'new'
                                  ? ''
                                  : clientInfo?.manager?.id || ''
                              }
                              label={'Менеджер *'}
                            >
                              {managerList.length === 0 ? (
                                <MenuItem>Пусто</MenuItem>
                              ) : (
                                managerList.map((item) =>
                                  item.profile.name !== null ||
                                  item.profile.surname !== null ? (
                                    <MenuItem
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item?.profile?.name}{' '}
                                      {item?.profile?.surname}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.email}
                                    </MenuItem>
                                  ),
                                )
                              )}
                            </TextField>
                          )}
                        />
                      </FormControl>
                    ) : (
                      <></>
                    )}

                    <FormControlLabel
                      disabled={!userAccess?.client?.update}
                      name='clientCheckbox'
                      sx={{ ml: 1 }}
                      label='Клиент'
                      control={<Checkbox checked={clientCheckBoxHandler} />}
                      onChange={handleClientChangeCheckBox}
                    />
                    {clientCheckBoxHandler ? (
                      <>
                        <FormControl fullWidth>
                          <Controller
                            name='type'
                            control={control}
                            render={({ field: { value, onBlur } }) => (
                              <TextField
                                disabled={!userAccess?.client?.update}
                                select
                                label={
                                  type === 1
                                    ? 'Физическое лицо'
                                    : 'Юридическое лицо'
                                }
                                defaultValue={value || ''}
                                onBlur={onBlur}
                                onChange={typeOnChange}
                                name='type'
                              >
                                <MenuItem value={1}>Физическое лицо</MenuItem>
                                <MenuItem value={2}>Юридическое лицо</MenuItem>
                              </TextField>
                            )}
                          />
                        </FormControl>

                        {type !== 1 && (
                          <FormControl fullWidth>
                            <TextField
                              id={'#type'}
                              disabled={!userAccess?.client?.update}
                              onChange={howClientPayOnChange}
                              select
                              value={howClientPay}
                              label='Тип оплат клиента'
                              name={'contract_type'}
                              defaultValue={clientInfo?.contract_type || ''}
                            >
                              {allCompanyList.map((item) => (
                                <MenuItem
                                  key={item.key}
                                  value={item.key}
                                >
                                  {item.value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        )}

                        {/*{!isPhoneCheck ? <></> :*/}
                        {userAccess?.client?.update && (
                          <Box sx={{ display: 'inline-flex', width: '100%' }}>
                            {itemId === 'new' ? (
                              <FormControl fullWidth>
                                <InputMask
                                  mask='+7 (999) 999 99 99'
                                  value={newClientPhone || ''}
                                  maskChar=' '
                                  onChange={({ target: { value } }) =>
                                    setNewClientPhone(value.trim())
                                  }
                                >
                                  {() => (
                                    <TextField
                                      label={'Добавить новый номер *'}
                                      name='phone'
                                    />
                                  )}
                                </InputMask>
                              </FormControl>
                            ) : (
                              <FormControl fullWidth>
                                <Controller
                                  name='phone'
                                  control={control}
                                  render={({ field: { onBlur } }) => (
                                    <InputMask
                                      mask='+7 (999) 999 99 99'
                                      value={clientPhone || ''}
                                      maskChar=' '
                                      onBlur={onBlur}
                                      onChange={addPhoneNumberOnChange}
                                    >
                                      {() => (
                                        <TextField
                                          label={'Добавить новый номер *'}
                                          // required={true}
                                          name='phone'
                                        />
                                      )}
                                    </InputMask>
                                  )}
                                />
                              </FormControl>
                            )}

                            {itemId === 'new' ? (
                              <>
                                <IconButton
                                  sx={{ borderRadius: 1 }}
                                  color='primary'
                                  onClick={addPhoneNumberNewClient}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                sx={{ borderRadius: 1 }}
                                color='primary'
                                onClick={() => addPhoneNumber(false)}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            )}
                          </Box>
                        )}

                        {!clientPhoneList.length ? (
                          <Box
                            sx={{
                              ml: 2,
                              display: 'inline-flex',
                              flexWrap: 'wrap',
                              '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#21516c',
                              },
                              '& .MuiTextField-root': { ml: 4, my: 1 },
                            }}
                          >
                            {newClientPhoneList.map((item, idx) => (
                              <Box
                                key={idx}
                                sx={{
                                  ml: 2,
                                  display: 'inline-flex',
                                  flexWrap: 'noWrap',
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#21516c',
                                  },
                                  '& .MuiTextField-root': { ml: 4, my: 1 },
                                }}
                              >
                                <InputMask
                                  mask='+7 (999) 999 99 99'
                                  disabled={false}
                                  alwaysShowMask
                                  maskPlaceholder='dd/mm/yy'
                                  value={item}
                                >
                                  {() => (
                                    <>
                                      <TextField
                                        sx={{ my: 0 }}
                                        disabled={true}
                                        name='phone'
                                      />
                                      {userAccess['client-phone']?.delete && (
                                        <IconButton
                                          sx={{ borderRadius: 1, m: 2 }}
                                          color='primary'
                                          onClick={() =>
                                            removePhoneNumberNewClient(item)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </>
                                  )}
                                </InputMask>
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          clientPhoneList.map((item) => (
                            <Box
                              key={item.id}
                              sx={{
                                ml: 2,
                                display: 'inline-flex',
                                flexWrap: 'noWrap',
                                '& .MuiInputBase-input.Mui-disabled': {
                                  WebkitTextFillColor: '#21516c',
                                },
                                '& .MuiTextField-root': { ml: 4, my: 1 },
                              }}
                            >
                              <InputMask
                                mask='+7 (999) 999 99 99'
                                disabled={false}
                                alwaysShowMask
                                maskPlaceholder='dd/mm/yy'
                                value={item.phone}
                                onChange={addPhoneNumberOnChange}
                              >
                                {() => (
                                  <>
                                    <TextField
                                      sx={{ my: 0 }}
                                      disabled={true}
                                      name='phone'
                                    />
                                    {userAccess['client-phone']?.delete && (
                                      <IconButton
                                        sx={{ borderRadius: 1, m: 2 }}
                                        color='primary'
                                        onClick={() =>
                                          deleteItem(item.id, 'phone')
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </>
                                )}
                              </InputMask>
                            </Box>
                          ))
                        )}

                        <Box sx={{ display: 'inline-flex', width: '100%' }}>
                          {userAccess?.client?.update && (
                            <FormControl fullWidth>
                              {/*<Controller*/}
                              {/*    name='email'*/}
                              {/*    control={control}*/}
                              {/*    render={({field: {onBlur}}) => (*/}
                              <TextField
                                name='email'
                                label='Почта'
                                disabled={!userAccess?.client?.update}
                                value={clientEmail || ''}
                                // onBlur={onBlur}
                                onChange={addEmailOnChange}
                              />
                              {/*)}/>*/}
                            </FormControl>
                          )}

                          {itemId === 'new' ? (
                            <></>
                          ) : (
                            userAccess['client-email']?.create && (
                              <IconButton
                                sx={{ borderRadius: 1 }}
                                color='primary'
                                onClick={addEmail}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            )
                          )}
                        </Box>
                        <FormControlLabel
                          disabled={!userAccess?.client?.update}
                          name='checkbox'
                          sx={{ ml: 1 }}
                          label='Не отправлять документы на почту'
                          control={<Checkbox checked={checkBoxHandlerEmail} />}
                          onChange={handleChangeCheckBoxEmail}
                        />

                        <Box
                          sx={{
                            ml: 2,
                            display: 'inline-flex',
                            flexWrap: 'noWrap',
                          }}
                        >
                          <List>
                            {clientEmailList.length === 0 ? (
                              <></>
                            ) : (
                              clientEmailList.map((item) => (
                                <ListItem
                                  key={item.id}
                                  sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                      WebkitTextFillColor: '#21516c',
                                    },
                                    '& .MuiTextField-root': { m: 1 },
                                  }}
                                >
                                  <TextField
                                    disabled={true}
                                    value={item.email}
                                  />
                                  {userAccess['client-email'].delete && (
                                    <IconButton
                                      sx={{ m: 2 }}
                                      color='primary'
                                      onClick={() =>
                                        deleteItem(item.id, 'email')
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </ListItem>
                              ))
                            )}
                          </List>
                        </Box>

                        {addressSuggestionsValue?.data?.inn === undefined ? (
                          <FormControl fullWidth>
                            <TextFieldController
                              name={'inn'}
                              commentRequest={!userAccess?.client?.update}
                              defaultValue={clientInfo?.inn}
                              control={control}
                              // rule={true}
                              label='ИНН'
                            />
                          </FormControl>
                        ) : (
                          <FormControl fullWidth>
                            <TextField
                              disabled={!userAccess?.client?.update}
                              onChange={innHandleChange}
                              name={'inn'}
                              value={innAfterSearch || ''}
                              label='ИНН'
                            />
                          </FormControl>
                        )}

                        {addressSuggestionsValue?.data?.address?.value ===
                        undefined ? (
                          <FormControl fullWidth>
                            <TextFieldController
                              name={'the_address'}
                              commentRequest={!userAccess?.client?.update}
                              defaultValue={clientInfo?.the_address}
                              control={control}
                              label='Адрес'
                            />
                          </FormControl>
                        ) : (
                          <FormControl fullWidth>
                            <TextField
                              disabled={!userAccess?.client?.update}
                              onChange={addressOnChange}
                              name={'the_address'}
                              value={addressAfterSearch || ''}
                              label='Адрес'
                            />
                          </FormControl>
                        )}
                        <TextFieldController
                          name={'actual_address'}
                          commentRequest={!userAccess?.client?.update}
                          defaultValue={clientInfo?.actual_address}
                          control={control}
                          label='Адрес фактический'
                        />

                        <TextFieldController
                          commentRequest={!userAccess?.client?.update}
                          name={'contact_person'}
                          defaultValue={clientInfo?.contact_person}
                          control={control}
                          label='Контактное лицо'
                        />

                        <TextFieldController
                          commentRequest={!userAccess?.client?.update}
                          name={'other_contact_person'}
                          defaultValue={clientInfo?.other_contact_person}
                          control={control}
                          label='Другие контактные данные'
                        />

                        <TextFieldController
                          commentRequest={!userAccess?.client?.update}
                          name={'proxies'}
                          defaultValue={clientInfo?.proxies}
                          control={control}
                          label='Доверенные лица'
                        />

                        {type === 1 ? (
                          <TextFieldController
                            commentRequest={!userAccess?.client?.update}
                            name={'data_of_an_individual'}
                            defaultValue={clientInfo?.data_of_an_individual}
                            control={control}
                            // rule={true}
                            label='Данные физического лица'
                          />
                        ) : (
                          <div>
                            {addressSuggestionsValue?.value === undefined ? (
                              <FormControl fullWidth>
                                <TextFieldController
                                  commentRequest={!userAccess?.client?.update}
                                  name={'company'}
                                  defaultValue={clientInfo?.company}
                                  control={control}
                                  label='Компания'
                                />
                              </FormControl>
                            ) : (
                              <FormControl fullWidth>
                                <TextField
                                  disabled={!userAccess?.client?.update}
                                  onChange={companyOnChange}
                                  name={'company'}
                                  value={companyAfterSearch || ''}
                                  label='Компания'
                                />
                              </FormControl>
                            )}

                            {addressSuggestionsValue?.data?.management?.name ===
                            undefined ? (
                              <FormControl fullWidth>
                                <TextFieldController
                                  name={'supervisor'}
                                  commentRequest={!userAccess?.client?.update}
                                  defaultValue={clientInfo?.supervisor}
                                  control={control}
                                  label='Руководитель (подписант), ФИО'
                                />
                              </FormControl>
                            ) : (
                              <FormControl fullWidth>
                                <TextField
                                  disabled={!userAccess?.client?.update}
                                  onChange={supervisorOnChange}
                                  name={'supervisor'}
                                  value={supervisorAfterSearch || ''}
                                  label='Руководитель (подписант), ФИО'
                                />
                              </FormControl>
                            )}
                            {addressSuggestionsValue?.data?.ogrn ===
                            undefined ? (
                              <FormControl fullWidth>
                                <TextFieldController
                                  multiline
                                  commentRequest={!userAccess?.client?.update}
                                  name={'requisites'}
                                  defaultValue={clientInfo?.requisites}
                                  label='Реквизиты'
                                  control={control}
                                />
                              </FormControl>
                            ) : (
                              <FormControl fullWidth>
                                <TextField
                                  disabled={!userAccess?.client?.update}
                                  multiline
                                  onChange={ogrnOnChange}
                                  name={'requisites'}
                                  value={ogrnAfterSearch || ''}
                                  label='Реквизиты'
                                />
                              </FormControl>
                            )}
                            {addressSuggestionsValue?.data?.management?.post ===
                            undefined ? (
                              <FormControl fullWidth>
                                <TextFieldController
                                  commentRequest={!userAccess?.client?.update}
                                  name={'position_of_the_signatory'}
                                  defaultValue={
                                    clientInfo?.position_of_the_signatory
                                  }
                                  control={control}
                                  label='Должность подписанта'
                                />
                              </FormControl>
                            ) : (
                              <FormControl fullWidth>
                                <TextField
                                  disabled={!userAccess?.client?.update}
                                  onChange={signatoryOnChange}
                                  name={'position_of_the_signatory'}
                                  value={signatoryAfterSearch || ''}
                                  label='Должность подписанта'
                                />
                              </FormControl>
                            )}
                            <TextFieldController
                              commentRequest={!userAccess?.client?.update}
                              name={'acts_on_the_basis'}
                              defaultValue={clientInfo?.acts_on_the_basis}
                              control={control}
                              label='Действует на основании'
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Card>

                  {userAccess.client.update && (
                    <Button
                      variant='contained'
                      disabled={disableButton}
                      fullWidth
                      type={'submit'}
                    >
                      Сохранить
                    </Button>
                  )}
                </form>
              </Box>

              <Dialog
                fullWidth
                open={openBalanceChangeDialog}
                maxWidth='sm'
                scroll='body'
                onClose={closeDialog}
              >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                  <IconButton
                    aria-label='close'
                    onClick={closeDialog}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{
                    pb: 8,
                    px: { xs: 2, sm: 4 },
                    pt: { xs: 8, sm: 12.5 },
                    position: 'relative',
                  }}
                >
                  <Box sx={{ mb: 8, textAlign: 'center' }}>
                    <Typography
                      variant='h5'
                      sx={{ mb: 3 }}
                    >
                      Изменить баланс
                    </Typography>
                  </Box>

                  <TextField
                    fullWidth
                    type={'number'}
                    required={true}
                    label={'Сумма'}
                    name={'amount'}
                    onChange={(e) => setAmountOfClientBalance(e.target.value)}
                  />

                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    label={'Назначение'}
                    required={true}
                    name={'description'}
                    onChange={(e) =>
                      setDescriptionOfClientBalance(e.target.value)
                    }
                  />
                </DialogContent>
                <DialogActions
                  sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
                >
                  <Button
                    variant='contained'
                    disabled={disableButtonTransaction}
                    color={'success'}
                    onClick={() => confirmChangeBalanceHandler('up')}
                  >
                    Пополнить счет
                  </Button>

                  <Button
                    variant='contained'
                    disabled={disableButtonTransaction}
                    color='abortButton'
                    onClick={() => confirmChangeBalanceHandler('down')}
                  >
                    Снять со счета
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
          {itemId !== 'new' && clientCheckBoxHandler && (
            <Grid
              item
              xs={12}
              lg={6}
            >
              <RentContract
                setRefresh={setRefresh}
                id={itemId}
                getActualBalanceOfClient={getActualBalanceOfClient}
              />
              <ClientRentObjects
                refresh={refresh}
                itemId={itemId}
              />
              <ClientPaymentTransactionsTable />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
};

export default EachClientInfo;
