import React, { useEffect, useState } from 'react';
import { apiGet, apiPost } from '../../../api';
import EachProductWrapper from '../../common/eachProductWrapper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {
  ButtonGroup,
  CardHeader,
  Checkbox,
  FormControl,
  Pagination,
  useMediaQuery,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import errorHandler from '../errorHandler';
import toast from 'react-hot-toast';
import ConfirmDialog from '../../common/dialogs/confirm';
import { setShowingDialog } from '../../../store/app';
import { useDispatch, useSelector } from 'react-redux';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Tooltip from '@mui/material/Tooltip';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import InvoiceForPaymentDialog from '../../common/dialogs/CreateAnInvoiceForPayment';
import { ClientPaymentTransactionsTable } from '../eachClients/elems/clientPaymentTransactionsTable';
import { editBalance } from '../../../helpers/BalanceHelper';
import { CustomCell } from '../../common/tables/CustomCell';
import { API_LIMIT } from '../../../constants/apiLimit';
import { dateFormat } from '../../../helpers/DateHelper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TableWrapBox = styled(Box)(({ theme }) => ({}));

const ClientRentObjects = ({
  refresh,
  itemId = '',
  contract_id = '',
  product_id = '',
  checkChangeRentContract,
}) => {
  const open = useSelector((state) => state.layout.open);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const [productList, setProductList] = useState([]);
  const [rentalProductId, setRentalProductId] = useState('');

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  // Dialog Подтверждение закрытия доп соглашения
  const [closeAddId, setCloseAddId] = useState('');
  const [isOpenCloseAdditionalDialog, setIsOpenCloseAdditionalDialog] =
    useState(false);
  const [userRole, setUserRole] = useState('');

  // Открыть модалку - добавление оплаты в ручную
  const [showDialogCreateNewPayment, setShowDialogCreateNewPayment] =
    useState(false);

  const [invoiceList, setInvoiceList] = useState([]);
  const [idInvoiceTo, setIdInvoiceTo] = useState('');
  const [sumOfCreateNewPayment, setSumOfCreateNewPayment] = useState('');
  const [startDateOfCreateNewInvoice, setStartDateOfCreateNewInvoice] =
    useState(null);
  const [stopDateOfCreateNewInvoice, setStopDateOfCreateNewInvoice] =
    useState(null);
  const [
    showChooseDocsForDownloadingDialog,
    setShowChooseDocsForDownloadingDialog,
  ] = useState(false);

  // const [downloadAllDocs, setDownloadAllDocs] = useState({transferAcceptanceAct: false, additionalAgreements: false})
  // const [downloadAllDocs, setDownloadAllDocs] = useState([])
  const [valueTabs, setValueTabs] = useState('1');
  const [additionalDocId, setAdditionalDocId] = useState('');

  const [userAccess, setUserAccess] = useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getRentalProduct = () => {
    apiGet({
      url: `/rental-product/index?client_id=${itemId}&page=${page}&contract_id=${contract_id}&product_id=${product_id}&limit=${API_LIMIT}`,
    })
      .then((res) => {
        if (res.error) return errorHandler(res.data);
        setLastPage(res.data.lastPage);
        setProductList(res.data.models);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getInvoiceList = () => {
    apiGet({
      url: '/company-accounts/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
    });
  };

  useEffect(() => {
    const userRoleAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userRoleAccessLocal);

    // Все доп соглашения
    getRentalProduct();
  }, [checkChangeRentContract, refresh, page]);

  useEffect(() => {
    const userRoleLocal = window.localStorage.getItem('userRole');
    setUserRole(userRoleLocal);

    if (itemId === 'new') return;

    // Список всех счетов
    getInvoiceList();
  }, []);

  const deleteRentalContract = (id) => {
    dispatch(setShowingDialog('deleteRentalContract'));
    setRentalProductId(id);
  };

  const confirmDeleteRentalContract = () => {
    apiGet({
      url: `/rental-product/delete?id=${rentalProductId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Удалено');

      // Все доп соглашения
      getRentalProduct();
    });
  };

  const closeDialog = () => {
    setIsOpenCloseAdditionalDialog(false);
    setShowDialogCreateNewPayment(false);
    setIdInvoiceTo('');
    setSumOfCreateNewPayment('');
    setStartDateOfCreateNewInvoice(null);
    setStopDateOfCreateNewInvoice(null);
    setShowChooseDocsForDownloadingDialog(false);
    setAdditionalDocId('');
  };

  const closeRentalContract = () => {
    apiGet({
      url: `/rental-product/close?id=${closeAddId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      // Все доп соглашения
      getRentalProduct();
      toast.success('Доп соглашение закрыто');
    });
    closeDialog();
  };

  const openAdditionalConractPage = (id) => {
    navigate(`/additional-agreement/${id}`);
  };

  const chooseDocsAndIdForDownLoadingHandler = (id) => {
    setShowChooseDocsForDownloadingDialog(true);
    setAdditionalDocId(id);
  };

  const anAcceptanceCertificate = (downloadItem) => {
    let url = '';

    if (downloadItem === 'anAcceptanceCertificate')
      url = '/legal-document/an-acceptance-certificate';

    if (downloadItem === 'additionalAgreements')
      url = '/legal-document/additional-agreements';

    if (downloadItem === 'transferAcceptanceAct')
      url = '/legal-document/transfer-acceptance-act';

    if (downloadItem === 'contract') url = '/legal-document/contract';

    apiGet({
      url: `${url}?type=1&id=${additionalDocId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      window.open(`${res.data.link}`);
      toast.success('Скачано');
      // closeDialog()
    });
  };

  return (
    <Card
      className={!open ? 'Overflow-box-each-show-menu' : 'Overflow-box-each'}
      sx={{ overflowX: 'auto', my: 2 }}
      raised
    >
      {productList.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          <CardHeader title={'Доп соглашения не найдены'} />
        </Box>
      ) : (
        <>
          <TableWrapBox>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='center'>
                      Номер соглашения
                    </StyledTableCell>
                    <StyledTableCell align='center'>Товар</StyledTableCell>
                    <StyledTableCell align='center'>
                      Баланс бытовки
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Дата закрытия
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Соглашение заключено
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Ежемесячный платеж
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Доставка / Возврат
                    </StyledTableCell>
                    <StyledTableCell align='center'>Действия</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ whiteSpace: 'nowrap' }}>
                  {productList.map((item) => (
                    <TableRow key={item?.id}>
                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { cursor: 'pointer', py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                cursor: 'pointer',
                                py: 0.5,
                              }
                        }
                        onClick={() => openAdditionalConractPage(item.id)}
                        align='center'
                      >
                        {item.id}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        sx={
                          item.closed_at === null
                            ? { width: 200, py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                width: 200,
                                py: 0.5,
                              }
                        }
                      >
                        <Box sx={{ display: 'flex' }}>
                          {item.products.map((product) => (
                            <div key={product?.product?.id}>
                              <EachProductWrapper
                                product_id={product?.product?.id}
                                status={product?.product?.status}
                              >
                                {product?.product?.name}
                              </EachProductWrapper>
                            </div>
                          ))}
                        </Box>
                      </CustomCell>

                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { cursor: 'pointer', py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                cursor: 'pointer',
                                py: 0.5,
                              }
                        }
                        onClick={() => openAdditionalConractPage(item.id)}
                        align='center'
                      >
                        {item.products.map((product, id) => (
                          <div key={id}>
                            {editBalance(product?.product?.price)}
                          </div>
                        ))}
                      </CustomCell>

                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { cursor: 'pointer', py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                cursor: 'pointer',
                                py: 0.5,
                              }
                        }
                        onClick={() => openAdditionalConractPage(item.id)}
                        align='center'
                      >
                        {dateFormat(item.closed_at) || '-'}
                      </CustomCell>

                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { cursor: 'pointer', py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                cursor: 'pointer',
                                py: 0.5,
                              }
                        }
                        onClick={() => openAdditionalConractPage(item.id)}
                        align='center'
                      >
                        {item.paymentTypeText}
                      </CustomCell>

                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { cursor: 'pointer', py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                cursor: 'pointer',
                                py: 0.5,
                              }
                        }
                        onClick={() => openAdditionalConractPage(item.id)}
                        align='center'
                      >
                        {editBalance(item?.sum)}
                      </CustomCell>

                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { cursor: 'pointer', py: 0.5 }
                            : {
                                backgroundColor: '#d3a9a9',
                                cursor: 'pointer',
                                py: 0.5,
                              }
                        }
                        onClick={() => openAdditionalConractPage(item.id)}
                        align='center'
                      >
                        {editBalance(item?.delivery_price) || 0} /{' '}
                        {editBalance(item?.return_price) || 0}
                      </CustomCell>

                      <CustomCell
                        sx={
                          item.closed_at === null
                            ? { py: 0.5 }
                            : { backgroundColor: '#d3a9a9', py: 0.5 }
                        }
                        align='center'
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          {item.closed_at === null ? (
                            <>
                              {userAccess['legal-document'][
                                'an-acceptance-certificate'
                              ] ? (
                                <Tooltip
                                  title={'Выбрать документы для скачивания'}
                                >
                                  <IconButton
                                    sx={{
                                      backgroundColor: '#fff',
                                      mr: 1,
                                      '&:hover': { backgroundColor: '#c2c2c2' },
                                    }}
                                    color={'success'}
                                    component='span'
                                    onClick={() =>
                                      chooseDocsAndIdForDownLoadingHandler(
                                        item.id,
                                      )
                                    }
                                  >
                                    <FilePresentIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <></>
                              )}

                              {userAccess['payment-transaction'].create && (
                                <InvoiceForPaymentDialog
                                  rentalProductId={item.id}
                                  invoiceList={invoiceList}
                                />
                              )}

                              {userAccess['rental-product'].close && (
                                <>
                                  <Tooltip title={'Закрыть доп соглашение'}>
                                    <IconButton
                                      setIsOpenCloseAdditionalDialog
                                      sx={{
                                        backgroundColor: '#fff',
                                        mr: 1,
                                        '&:hover': {
                                          backgroundColor: '#c2c2c2',
                                        },
                                      }}
                                      onClick={() => {
                                        setCloseAddId(item.id);
                                        setIsOpenCloseAdditionalDialog(true);
                                      }}
                                      color='abortButton'
                                    >
                                      <DoDisturbIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {userAccess['rental-product'].delete ? (
                            <Tooltip title={'Удалить доп соглашение'}>
                              <IconButton
                                sx={{
                                  backgroundColor: '#fff',
                                  mr: 1,
                                  '&:hover': { backgroundColor: '#c2c2c2' },
                                }}
                                color='warning'
                                component='span'
                                onClick={() => deleteRentalContract(item.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </CustomCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {lastPage > 1 && (
                <Pagination
                  sx={{ p: 1 }}
                  siblingCount={!matches ? 0 : 1}
                  size={!matches ? 'small' : 'medium'}
                  page={page}
                  onChange={(_, p) => setPage(p)}
                  count={lastPage}
                  variant='outlined'
                  shape='rounded'
                />
              )}
            </TableContainer>
          </TableWrapBox>
        </>
      )}
      <ConfirmDialog
        title={'Удалить доп соглашение?'}
        buttonTitle={'Удалить'}
        event={confirmDeleteRentalContract}
        name={'deleteRentalContract'}
      />

      <Dialog
        fullWidth
        open={isOpenCloseAdditionalDialog}
        maxWidth='sm'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Закрыть доп соглашение?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='abortButton'
            sx={{ marginRight: 1 }}
            onClick={closeRentalContract}
          >
            Закрыть соглашение
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>

      {/*Start ----------Выбрать документы для скачивания*/}
      <Dialog
        fullWidth
        open={showChooseDocsForDownloadingDialog}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Выбрать документы для скачивания
            </Typography>
          </Box>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <ButtonGroup
              fullWidth
              size={'large'}
              orientation='vertical'
              aria-label='vertical contained button group'
              variant='contained'
            >
              <Button
                sx={{ marginBottom: 1 }}
                onClick={() => anAcceptanceCertificate('contract')}
              >
                Сгенерировать договор{' '}
              </Button>

              <Button
                onClick={() => anAcceptanceCertificate('transferAcceptanceAct')}
              >
                Сгенерировать акт приема-передачи
              </Button>
              <Button
                sx={{ my: 1 }}
                onClick={() => anAcceptanceCertificate('additionalAgreements')}
              >
                Сгенерировать заявку на доп. соглашение
              </Button>
              <Button
                onClick={() =>
                  anAcceptanceCertificate('anAcceptanceCertificate')
                }
              >
                Сгенерировать акт приема-сдачи
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/*End ----------Выбрать документы для скачивания*/}
    </Card>
  );
};

export default ClientRentObjects;
