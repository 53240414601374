import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { CustomSelect } from '../../../../common/formControllers/CustomSelect';
import { CustomInput } from '../../../../common/formControllers/CustomInput';
import { useUsersListPage } from '../useUsersListPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { PermissionWrapper } from '../../../../common/permissionWrapper/PermissionWrapper';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../../../../common/dialogs/confirm';
import { CustomCell } from '../../../../common/tables/CustomCell';

export const StaffsTable = () => {
  const { methods, userList, goToStaff, lastPage, deleteStaff, showModal } =
    useUsersListPage();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const open = useSelector((state) => state.layout.open);
  const allRoles = useSelector((state) => state.user.allRoles).map((el) => ({
    value: el.name,
    key: el.role,
  }));
  return (
    <FormProvider {...methods}>
      <Paper
        className={!open ? 'Overflow-box-show-menu' : 'Overflow-box'}
        sx={{ overflow: 'auto' }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>
                <CustomInput
                  name='email'
                  label='Почта'
                />
              </StyledTableCell>

              <StyledTableCell align='center'>
                <CustomInput
                  name='name'
                  label='Фамилия Имя'
                />
              </StyledTableCell>

              <StyledTableCell align='center'>
                <CustomSelect
                  sx={{ minWidth: 250 }}
                  options={allRoles}
                  name='role'
                  label='Роль'
                />
              </StyledTableCell>

              <StyledTableCell align='center'>Действия</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user) => (
              <StyledTableRow key={user.id}>
                <CustomCell
                  cursor='pointer'
                  onClick={() => goToStaff(user.id)}
                >
                  {user.email}3
                </CustomCell>

                <CustomCell
                  cursor='pointer'
                  onClick={() => goToStaff(user.id)}
                >
                  {user.profile?.surname} {user.profile?.name}
                </CustomCell>

                <CustomCell
                  cursor='pointer'
                  onClick={() => goToStaff(user.id)}
                >
                  {user.role[0].roleTranslate}
                </CustomCell>

                <PermissionWrapper permits={['user', 'delete']}>
                  <CustomCell align='center'>
                    <IconButton
                      sx={{
                        backgroundColor: '#fff',
                        mr: 1,
                        '&:hover': { backgroundColor: '#c2c2c2' },
                      }}
                      color='abortButton'
                      component='span'
                      onClick={() => showModal(user.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CustomCell>
                </PermissionWrapper>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {!!userList.length ? (
          <Pagination
            page={methods.watch('page')}
            siblingCount={!matches ? 0 : 1}
            onChange={(_, page) => methods.setValue('page', page)}
            count={lastPage}
            variant='outlined'
            shape='rounded'
          />
        ) : (
          <span>Ничего не найдено</span>
        )}
      </Box>

      <ConfirmDialog
        title='Удалить пользователя?'
        name='user-delete'
        buttonTitle='Удалить'
        event={deleteStaff}
      />
    </FormProvider>
  );
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#d0d0d0',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
