import React, { useEffect } from 'react';
import '../../../../globalStyle/Table.scss';
import ClientTable from '../../../common/tables/clients';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';
import { PermissionWrapper } from '../../../common/permissionWrapper/PermissionWrapper';
import { useRedirect } from '../../../../hooks/useRedirect';

const Clients = () => {
  useRedirect();

  return (
    <div>
      <HeaderPage title='Клиенты'>
        <PermissionWrapper permits={['client', 'create']}>
          <Link to='/client/new'>
            <Button
              variant={'contained'}
              color={'secondary'}
            >
              Создать клиента
            </Button>
          </Link>
        </PermissionWrapper>
      </HeaderPage>

      <ClientTable />
    </div>
  );
};

export default Clients;
