import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const CalendarDayHeader = ({ arg, clientCallCounter, taskCounter }) => {
  if (!taskCounter && !clientCallCounter) return null;

  return (
    <div>
      {arg?.text}

      {!!taskCounter?.length &&
        taskCounter?.map((el) => {
          if (el.date === arg.date.toLocaleDateString()) {
            return (
              <Box
                key={el}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontSize: 11,
                    backgroundColor: '#acf3af',
                    px: 1,
                    borderRadius: 2,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Выполненных задач : {el.countTask}
                </Typography>
              </Box>
            );
          }
        })}

      {!!clientCallCounter?.length &&
        clientCallCounter?.map((el) => {
          if (el.date === arg.date.toLocaleDateString()) {
            return (
              <Box
                key={el}
                sx={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  Звонков : {el.countCall}
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  Клиентов : {el.countClient}
                </Typography>
              </Box>
            );
          }
        })}
    </div>
  );
};
