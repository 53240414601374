import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { store } from './store';

import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />

      <CssBaseline />
      <Toaster position='top-right' />
    </BrowserRouter>
  </Provider>,
  document.querySelector('#root'),
);
