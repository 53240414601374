import React from 'react';
import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export const TextFieldController = ({
  value,
  name,
  control,
  rule = false,
  defaultValue,
  label,
  fullWidth = true,
  error,
  multiline = false,
  maxRows = 1,
  commentRequest = false,
}) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        rules={{ required: rule }}
        render={({ field: { value, onChange, onBlur } }) => (
          <TextField
            disabled={commentRequest}
            multiline={multiline}
            error={error}
            defaultValue={defaultValue || ''}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            required={rule}
          />
        )}
      />
    </FormControl>
  );
};
