import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import Button from '@mui/material/Button';
import { useMapEvents } from 'react-leaflet/hooks';
import { theme } from '../../../common/componentColors';
import { Box } from '@mui/material';

export function Map({ position, setPosition, setModalMap, setCoordinates }) {
  function LocationMarker() {
    const map = useMapEvents({
      click() {
        map.locate();
      },
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return position === null ? null : (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      ></Marker>
    );
  }
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [],
  );

  useEffect(() => {
    setCoordinates(`${position.lat.toFixed(6)},${position.lng.toFixed(6)}`);
  }, [position]);

  return (
    <MapContainer
      style={{ height: 535 }}
      center={position}
      zoom={13}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <LocationMarker />
    </MapContainer>
  );
}
