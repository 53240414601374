import React from 'react';
import './pageEntries.scss';
import { useFormContext } from 'react-hook-form';

export const PageEntries = ({
  data,
  lastPage,
  currentPage,
  countRecord,
  cn,
}) => {
  const { watch } = useFormContext();
  const recordShow = watch('select') || 5;

  if (!data?.length)
    return (
      <div className={`${cn} show-record-not-data`}>Ничего не найдено</div>
    );

  if (lastPage === 1) {
    return (
      <div className={cn}>
        Показаны записи <strong>{data?.length}</strong> из{' '}
        <strong>{data?.length}</strong>
      </div>
    );
  }

  return (
    <div className={`${cn} show-record-wrap`}>
      <div className='show-record-text'>Показаны записи</div>
      <strong>
        {currentPage * recordShow - (recordShow - 1)} -{' '}
        {lastPage === currentPage ? countRecord : currentPage * recordShow}
      </strong>
      <span className=''>из</span>
      <strong>{countRecord}</strong>
    </div>
  );
};
