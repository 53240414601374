import CalendarBlankOutline from 'mdi-material-ui/CalendarBlankOutline';
import AccountCashOutline from 'mdi-material-ui/AccountCashOutline';
import PeopleIcon from '@mui/icons-material/People';
import AddCardIcon from '@mui/icons-material/AddCard';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import CabinIcon from '@mui/icons-material/Cabin';
import HistoryIcon from '@mui/icons-material/History';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MoneyKeeper from '../components/pages/main/accountant';
import ClientPage from '../components/pages/main/clients';
import EachClient from '../components/pages/main/eachClient';
import Employees from '../components/pages/main/users';
import LoginPage from '../components/pages/auth/login';
import EachUser from '../components/pages/main/eachUser';
import Profile from '../components/pages/main/profile';
import Page404 from '../components/pages/main/error/404/Error';
import Contracts from '../components/pages/main/contracts';
import HistoryLog from '../components/pages/main/history';
import EachContract from '../components/pages/main/eachContract';
import Calendar from '../components/pages/main/calendar/index';
import EachProduct from '../components/pages/main/eachProduct';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import TaskPage from '../components/pages/main/driverPage';
import Payments from '../components/pages/main/payments';
import Statistics from '../components/pages/main/statistics';
import AddtionalAgreement from '../components/pages/main/eachAdditionalAgreement';
import Products from '../components/pages/main/products';
import Error from '../components/pages/main/error/404/Error';
import ArchiveNotifications from '../components/pages/main/archiveNotifications';
import { UsersListPage } from '../components/pages/main/users/usersListPage';

export const mainRoutes = [
  {
    path: '/',
    text: 'Календарь',
    icon: CalendarBlankOutline,
    roleAccess: ['task-executor', 'index'],
    role: ['admin', 'accountant', 'warehouse_worker', 'manager'],
    component: <Calendar />,
  },
  {
    path: '/users',
    text: 'Сотрудники',
    role: ['admin'],
    roleAccess: ['user', 'create'],
    icon: PeopleIcon,
    component: <UsersListPage />,
  },
  {
    path: '/accountant',
    roleAccess: ['account-transaction', 'index'],
    text: 'Бухгалтерия',
    role: ['admin', 'accountant', 'warehouse_worker', 'manager'],
    icon: AccountCashOutline,
    component: <MoneyKeeper />,
  },
  {
    path: '/clients',
    roleAccess: ['client', 'index'],
    text: 'Клиенты',
    icon: GroupAddIcon,
    role: ['admin', 'accountant', 'warehouse_worker', 'manager'],
    component: <ClientPage />,
  },
  {
    path: '/contracts',
    roleAccess: ['client-contract', 'index'],
    text: 'Договоры',
    icon: AutoStoriesIcon,
    role: ['admin', 'accountant', 'warehouse_worker', 'manager'],
    component: <Contracts />,
  },
  {
    path: '/payments',
    text: 'Оплаты',
    roleAccess: ['payment-transaction', 'index'],
    icon: AddCardIcon,
    role: ['admin', 'accountant'],
    component: <Payments />,
  },
  {
    path: '/statistics',
    text: 'Статистика',
    roleAccess: ['statistics', 'late-payments'],
    icon: SsidChartIcon,
    role: ['admin', 'accountant', 'manager'],
    component: <Statistics />,
  },
  {
    path: '/products',
    text: 'Объекты аренды',
    roleAccess: ['product', 'group-list'],
    icon: CabinIcon,
    role: ['admin', 'accountant', 'manager'],
    component: <Products />,
  },
  {
    path: '/history',
    roleAccess: ['product-log', 'index'],
    text: 'История',
    role: ['admin'],
    icon: HistoryIcon,
    component: <HistoryLog />,
  },
  {
    path: '/taskPage',
    text: 'Настройки',
    roleAccess: ['task-executor', 'driver'],
    role: ['driver'],
    icon: MiscellaneousServicesIcon,
    component: <TaskPage />,
  },
];

export const hiddenRoutes = [
  {
    path: '/profile',
    text: 'Профиль',
    component: <Profile />,
  },
  {
    path: 'client/:itemId',
    component: <EachClient />,
  },
  {
    path: 'user/:employeerId',
    component: <EachUser />,
  },
  {
    path: 'contract/:contractId',
    component: <EachContract />,
  },
  {
    path: 'product/:productId',
    component: <EachProduct />,
  },
  {
    path: '/404',
    component: <Error />,
  },
  {
    path: 'additional-agreement/:additionalId',
    component: <AddtionalAgreement />,
  },
  {
    path: 'archive-notifications',
    component: <ArchiveNotifications />,
  },
];

export const authRoutes = [
  {
    path: '/login',
    component: <LoginPage />,
  },
];
