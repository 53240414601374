import { useState, forwardRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { apiGet, apiPost } from '../../../../api';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import errorHandler from '../../../layouts/errorHandler';
import MenuItem from '@mui/material/MenuItem';
import InputMask from 'react-input-mask';
import { styled, useTheme } from '@mui/material/styles';
import { setReloadEvents } from '../../../../store/app';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});
const StyledButtonWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 510)]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  [theme.breakpoints.between(0, 510)]: {
    flexDirection: 'column',
  },
}));
const ButtonClose = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between(0, 510)]: {
    marginTop: 14,
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between(400, 510)]: {
    display: 'flex',
    m: 2,
    justifyContent: 'center',
  },
  [theme.breakpoints.between(0, 400)]: {
    display: 'flex',
    justifyContent: 'center',
    m: 2,
  },
}));

const DialogAddNewClient = ({ title, userRole, isCall = false }) => {
  const dispatch = useDispatch();
  const errorsList = useSelector((state) => state.errors.errorsList);
  const [show, setShow] = useState(false);
  const [userAccess, setUserAccess] = useState(false);
  const [roleOfUser, setRoleOfUser] = useState('');
  const [errors, setErrors] = useState([]);
  const [phoneOnChange, setPhoneOnChange] = useState('');
  const [phoneList, setPhoneList] = useState([]);
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  // checkBox
  const [checkBoxHandler, setCheckBoxHandler] = useState(false);

  const handleChangeCheckBox = (event) => {
    setCheckBoxHandler(event.target.checked);
  };

  // Managers
  const [allManagers, setAllManagers] = useState([]);

  const [disableButton, setDisableButton] = useState(false);
  const theme = useTheme();

  const closeDialog = () => {
    dispatch(setReloadEvents(false));
    setPhoneList([]);
    setPhoneOnChange('');
    setShow(false);
    setCheckBoxHandler(false);
  };

  const submit = (formData) => {
    // setDisableButton(true)
    let url = '';
    let text = '';

    for (let key in formData) {
      if (formData[key] === undefined) {
        delete formData[key];
      }
    }

    if (phoneOnChange.length < 11) {
      toast.error('Необходимо заполнить номер');
      return;
    }
    formData.phone = phoneOnChange;
    if (checkBoxHandler) formData.status = 2;

    url = '/client/create-call';

    if (phoneOnChange === phoneList[0]?.phone)
      return navigate('/client/' + phoneList[0].client_id);

    apiPost({
      url: url,
      postData: formData,
    })
      .then((res) => {
        if (res.error) {
          setDisableButton(false);
          return errorHandler(res.data, dispatch);
        }
        const idUser = res.data.id;
        // Обновляем список клиентов
        // apiGet({
        //     url: `/client/index?page=1&limit=5`
        // }).then(res => {
        //     if (res.error)
        //         return toast.error('ошибка обновления списка клиентов')
        //     dispatch(setClientList(res.data.models))
        // }).catch(e => {
        //     console.log(e)
        // })
        if (checkBoxHandler)
          setTimeout(() => navigate(`/client/${idUser}`), 500);
        dispatch(setReloadEvents(true));
        toast.success('Создан');
        closeDialog();
        reset();
        setDisableButton(false);
      })
      .catch((e) => console.log(e));
  };

  const getPhoneList = (e) => {
    // Вычлиняем только цифры, чтобы передать в Post запрос
    setPhoneOnChange(e.target.value.replace(/[^0-9]/g, ''));

    // Поиск телефона
    apiGet({
      url: `/client-phone/index?phone=${e.target.value.replace(
        /[^0-9]/g,
        '',
      )}&limit=5`,
    }).then((res) => {
      if (res.error) return toast.error('Ошибка получения телефона');
      setPhoneList(res.data.models);
    });
  };

  const getManagerList = () => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);
    if (userAccessLocal?.user?.index) {
      apiGet({
        url: '/user/index?role=manager',
      }).then((res) => {
        setAllManagers(res.data.models);
      });
    }
  };

  // useEffect(() => {
  // }, [])

  const openDialog = () => {
    setShow(true);
    getManagerList();
  };

  useEffect(() => {
    const userRoleLocal = window.localStorage.getItem('userAccess');
    setRoleOfUser(userRoleLocal);
  }, []);

  return (
    <div>
      <StyledButtonWrap
        sx={{
          textAlign: 'right',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledButton
          sx={{ mr: 2, width: 122, p: 1, fontSize: 11 }}
          variant='contained'
          color={'secondary'}
          onClick={openDialog}
        >
          {title}
        </StyledButton>
      </StyledButtonWrap>
      <Dialog
        fullWidth
        open={show}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit(submit)}>
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography variant='h5'>
                {checkBoxHandler ? 'Создать клиента' : title}
              </Typography>
            </Box>

            <InputMask
              mask='+7 (999) 999 99 99'
              value={phoneOnChange}
              disabled={false}
              maskChar=' '
              onChange={getPhoneList}
            >
              {() => (
                <TextField
                  required={true}
                  label={'Телефон'}
                  fullWidth
                  name='phone'
                />
              )}
            </InputMask>

            {/*<TextField*/}
            {/*    name="phone"*/}
            {/*    defaultValue={''}*/}
            {/*    label='Номер телефона'*/}
            {/*    onChange={getPhoneList}*/}
            {/*    fullWidth*/}
            {/*/>*/}

            {phoneOnChange.length < 4 ? (
              <Typography
                variant={'body2'}
                color={'#c0c0c0'}
                sx={{ ml: 1 }}
              >
                *Введите больше 4-х знаков для поиска
              </Typography>
            ) : (
              phoneList.map((item) => (
                <Box
                  key={item.id}
                  sx={{ mt: 2, display: 'inline-flex', flexWrap: 'wrap' }}
                >
                  <Button
                    type='button'
                    variant={'text'}
                    onClick={closeDialog}
                    component={RouterLink}
                    to={'/client/' + item.client_id}
                  >
                    {item.phone}
                  </Button>

                  {/*{item.phone.length === 0 ? 'Нет номера' : item.phone.map(phoneItem =>)}*/}
                </Box>
              ))
            )}

            <Controller
              name='name'
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  sx={{ my: 2 }}
                  value={value || ''}
                  required={true}
                  label='Имя'
                  onBlur={onBlur}
                  onChange={onChange}
                  name='name'
                  fullWidth
                  placeholder='Имя'
                />
              )}
            />

            {/*{checkBoxHandler &&*/}
            {/*<Controller*/}
            {/*    name='email'*/}
            {/*    control={control}*/}
            {/*    render={({field: {value, onChange, onBlur}}) => (*/}
            {/*        <TextField*/}
            {/*            value={value || ''}*/}
            {/*            required={true}*/}
            {/*            label='Почта'*/}
            {/*            onBlur={onBlur}*/}
            {/*            onChange={onChange}*/}
            {/*            name="email"*/}
            {/*            fullWidth/>*/}
            {/*    )}*/}
            {/*/>}*/}

            <Controller
              name='comment'
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  sx={{ mb: 2 }}
                  multiline
                  value={value || ''}
                  label='Комментарий'
                  onBlur={onBlur}
                  onChange={onChange}
                  name='comment'
                  fullWidth
                />
              )}
            />

            {userRole !== 'admin' ? (
              <></>
            ) : (
              <Controller
                name='manager_id'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <>
                    {allManagers.length === 0 ? (
                      <TextField
                        select
                        required={true}
                        value={value || ''}
                        label='Выбрать менеджера'
                        fullWidth
                      >
                        <MenuItem disabled>Пусто</MenuItem>
                      </TextField>
                    ) : (
                      <TextField
                        select
                        required={true}
                        value={value || ''}
                        label='Выбрать менеджера'
                        onBlur={onBlur}
                        onChange={onChange}
                        name='manager_id'
                        fullWidth
                      >
                        {allManagers.map((item) =>
                          item.profile.name === null &&
                          item.profile.surname === null ? (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                            >
                              {item.username}
                            </MenuItem>
                          ) : (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                            >
                              {item.profile.name} {item.profile.surname}
                            </MenuItem>
                          ),
                        )}
                      </TextField>
                    )}
                  </>
                )}
              />
            )}
            <div>
              {errorsList.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>

            <FormControlLabel
              sx={{ ml: 1 }}
              name='checkbox'
              label='Клиент'
              control={<Checkbox checked={checkBoxHandler} />}
              onChange={handleChangeCheckBox}
            />
          </DialogContent>
          <StyledDialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            {/*<Button variant='contained' onClick={createClient}>*/}
            {/*    Создать клиента*/}
            {/*</Button>*/}

            <Button
              disabled={disableButton}
              variant='contained'
              sx={{ mx: 2 }}
              type='submit'
            >
              {!checkBoxHandler ? 'Создать звонок' : 'Создать клиента'}
            </Button>

            <ButtonClose
              variant='contained'
              color='secondary'
              onClick={closeDialog}
            >
              Отменить
            </ButtonClose>
          </StyledDialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogAddNewClient;
