// FullCalendar
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import ruLocale from '@fullcalendar/core/locales/ru';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import './index.scss';

// React and Redux
import DialogAddNewEvent from '../../../common/dialogs/newEvent';
import { useDispatch, useSelector } from 'react-redux';
import { setAllRoles, setStaff } from '../../../../store/user';
import React, { useCallback, useEffect, useState } from 'react';
import { apiGet, apiPost } from '../../../../api';
import toast from 'react-hot-toast';
import {
  getEventData,
  setDialogEventDescription,
  setEventData,
  setEventNameList,
  setEventsList,
} from '../../../../store/events';
import DialogEventDescription from '../../../common/dialogs/eventDescription';
import errorHandler from '../../../layouts/errorHandler';

// MUI
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { theme } from '../../../common/componentColors';
import { useRedirect } from '../../../../hooks/useRedirect';
import { Spinner } from '../../../common/spinner/Spinner';
import { CalendarDayHeader } from './elems/CalendarDayHeader';
import { CustomSelect } from '../../../common/formControllers/CustomSelect';
import {
  selectRolesParse,
  selectUsersParse,
} from '../../../../helpers/SelectParse';
import { FormProvider, useForm } from 'react-hook-form';
import { sxStyles } from '../../../../globalStyle/sxStyles';
import { CalendarEvent } from './elems/CalendarEvent';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 510)]: {
    flexDirection: 'column-reverse',
  },
}));

const StyledBoxNewEvent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 750)]: {
    justifyContent: 'center',
    margin: '5px',
  },
}));

const DEFAULT_VALUE = {
  role: '',
  user: '',
};

const Calendar = () => {
  const methods = useForm({ defaultValues: DEFAULT_VALUE });
  const { watch, setValue } = methods;
  const dispatch = useDispatch();
  useRedirect();
  const eventsList = useSelector((state) => state.events.eventsList).map(
    (el) => ({ ...el, start: `${el.date}` }),
  );
  const allRoles = useSelector((state) => state.user.allRoles);
  const staff = useSelector((state) => state.user.staff);
  const eventData = useSelector((state) => state.events.eventData);

  const open = useSelector((state) => state.layout.open);
  const [isLoading, setIsLoading] = useState(false);
  const [userAccessData, setUserAccessData] = useState({});
  const [taskCounter, setTaskCounter] = useState([]);
  const [clientCallCounter, setClientCallCounter] = useState([]);
  const reloadEvents = useSelector((state) => state.app.reloadEvents);

  const handleEventClick = (data) => {
    const popover = window.document.querySelector('.fc-popover');
    if (popover) {
      popover.classList.add('invisible_popover');
    }
    dispatch(setEventData(data?.event));
    dispatch(setDialogEventDescription(true));
    getAllTasks();
  };

  const getAllTasks = () => {
    let url = '';
    let localRole = window.localStorage.getItem('userRole');
    if (localRole === 'admin' || localRole === 'manager')
      url = `/task/index?limit=1000&sort=createdAt&role=${watch(
        'role',
      )}&user_id=${watch('user')}`;
    else url = '/task-executor/index';

    apiGet({
      url: `${url}`,
    })
      .then((res) => {
        if (res.error) {
          return errorHandler(res.data, dispatch);
        }
        let arr = [];
        res.data.models.map((item) => {
          let data = {};
          // data.title = '';
          data.title = item.nameText;
          if (item.taskProduct.length === 0) {
            data.product_id = null;
            data.productName = null;
          } else {
            data.taskProduct = item?.taskProduct;
          }
          if (item.taskExecutor.length === 0) {
            data.taskComment = null;
          } else data.taskComment = item?.taskExecutor?.comment;
          data.description = item?.comment;
          data.status = item?.status;
          data.statusText = item?.statusText;
          data.id = item.id;
          data.color = item?.colorStatus;
          data.role = item?.user?.role[0]?.role;

          if (item.user !== null) {
            data.user = {};
            data.user.id = item?.user?.id;
            data.user.username = item?.user?.username;
            data.name = item?.user?.profile?.name;
            data.surname = item?.user?.profile?.surname;
          }
          data.date = item.start;
          data.files = item.files;
          arr.push(data);
        });

        dispatch(setEventsList(arr));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const userRole = window.localStorage.getItem('userRole');
    if (userRole !== 'admin' && userRole !== 'manager')
      return dispatch(getEventData());

    const userAccess = JSON.parse(localStorage.getItem('userAccess'));
    setUserAccessData(userAccess);

    if (userAccess.task.index) {
      getAllTasks();
    }
  }, [watch('user'), watch('role')]);

  useEffect(() => {
    const userAccess = JSON.parse(localStorage.getItem('userAccess'));
    setUserAccessData(userAccess);

    // Получение всех ролей системы
    if (userAccess['user-role'].index) {
      apiGet({
        url: '/user-role/index',
      }).then((res) => {
        if (res.error) return toast.error('Ошибка получение ролей');
        dispatch(setAllRoles(res.data));
      });
    }

    // Список имен для задач
    if (userAccess.task['name-list']) {
      apiGet({
        url: '/task/name-list',
      }).then((res) => {
        dispatch(setEventNameList(res.data));
      });
    }
  }, [eventData]);

  // Получение сотрудников относительно выбранной роли
  const selectUserOnChange = async (n, v) => {
    const res = await apiGet({
      url: `/user/index?role=${v}`,
    });
    if (res.error) return toast.error('Ошибка получения сотрудников');
    dispatch(setStaff(res.data.models));
  };

  const eventDropHandler = useCallback((event) => {
    const ruLocateDate = event.event._instance.range.start.toLocaleString(
      'ru-Ru',
      { timeZone: 'Atlantic/Reykjavik' },
    );
    apiPost({
      url: `/task/update?id=${event.event._def.publicId}`,
      postData: { start: ruLocateDate },
    }).then((res) => {
      if (res.error) {
        if (res.data !== '') {
          event.revert();
        }
        if (
          event.event.extendedProps.status === 3 ||
          event.event.extendedProps.status === 4
        ) {
          event.revert();
        }
        return errorHandler(res.data);
      }

      /**
       getAllTasks() делал дубликаты эвентов :)
       **/
    });
  }, []);

  const getCountCall = () => {
    apiGet({
      url: '/task/count-call',
    }).then((res) => {
      if (res.error) return;
      setClientCallCounter(res.data);
    });
  };

  const getCountTask = () => {
    apiGet({
      url: '/task/count-task',
    }).then((res) => {
      setTaskCounter(res.data);
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    if (userAccessLocal?.task['count-task']) getCountTask();
    if (userAccessLocal?.task['count-call']) getCountCall();

    return () => {
      abortController.abort();
    };
  }, [reloadEvents]);

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <StyledBox
        sx={{
          ...sxStyles.flexBetween,
          [theme.breakpoints.between(0, 1220)]: {
            ...sxStyles.flexStart,
            flexWrap: 'wrap-reverse',
          },
        }}
      >
        {userAccessData?.task?.create && (
          <StyledBoxNewEvent sx={{ display: 'flex' }}>
            <DialogAddNewEvent getAllTasks={getAllTasks} />
          </StyledBoxNewEvent>
        )}

        {userAccessData?.task?.index && (
          <FormProvider {...methods}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                [theme.breakpoints.between(0, 660)]: {
                  flexWrap: 'wrap',
                  gap: 2,
                },
                [theme.breakpoints.between(0, 818)]: { my: 1 },
              }}
            >
              {!!allRoles.length && (
                <CustomSelect
                  InputProps={{ sx: { ...sxStyles.mW240 } }}
                  handleChange={selectUserOnChange}
                  name='role'
                  onClear={() => setValue('user', '')}
                  label='Задачи для роли'
                  options={selectRolesParse(allRoles)}
                />
              )}
              {watch('role') && (
                <CustomSelect
                  InputProps={{ sx: { ...sxStyles.mW240 } }}
                  name='user'
                  label='Задачи для сотрудника'
                  options={selectUsersParse(staff)}
                />
              )}
            </Box>
          </FormProvider>
        )}

        <Typography
          variant='h4'
          align='center'
        >
          Календарь
        </Typography>
      </StyledBox>
      <Divider />
      <br />
      <Box
        className={
          !open ? 'Overflow-box-calendar-show-menu' : 'Overflow-box-calendar'
        }
        sx={{ overflow: 'auto' }}
      >
        <Box sx={{ minWidth: 1170 }}>
          <FullCalendar
            editable
            selectable
            allDaySlot={false}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek',
            }}
            eventBackgroundColor='grey'
            initialView='timeGridWeek'
            weekends
            eventMaxStack={3}
            eventClick={handleEventClick}
            eventOrder='start'
            eventDrop={eventDropHandler}
            events={eventsList}
            locale={ruLocale}
            eventDisplay='block'
            eventMinHeight={90}
            eventMaxHeight={90}
            dayCellContent={(arg) => {
              return (
                <div>
                  <span>{arg.dayNumberText}</span>

                  {arg.view.type === 'dayGridMonth' && (
                    <CalendarDayHeader
                      taskCounter={taskCounter}
                      clientCallCounter={clientCallCounter}
                      arg={arg}
                    />
                  )}
                </div>
              );
            }}
            dayHeaderContent={(arg) => (
              <CalendarDayHeader
                taskCounter={taskCounter}
                clientCallCounter={clientCallCounter}
                arg={arg}
              />
            )}
            eventContent={(info) => <CalendarEvent info={info.event} />}
          />
        </Box>
      </Box>

      <DialogEventDescription getAllTasks={getAllTasks} />
    </Box>
  );
};

export default Calendar;
