import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGet } from '../../api';

const initialState = {
  errorsList: [],
};

const errorsInfoSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setErrorHandler: (state, action) => {
      state.errorsList = action.payload;
    },
  },
});

export const { setErrorHandler } = errorsInfoSlice.actions;

export default errorsInfoSlice.reducer;
