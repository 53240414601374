import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import clients from './clients';
import contracts from './contracts';
import rentObjects from './rentObjects';
import products from './products';
import events from './events';
import errors from './errors';
import app from './app';
import notifications from './notifications';
import layout from './layout';
import managers from './allManager';
import refetch from './refetch/refetch';

export const store = configureStore({
  reducer: {
    user,
    errors,
    clients,
    contracts,
    rentObjects,
    products,
    events,
    app,
    notifications,
    layout,
    managers,
    refetch,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
