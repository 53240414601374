import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export const TableSelectForPages = () => {
  const { control } = useFormContext();

  return (
    <Box sx={{ maxWidth: 120 }}>
      <div>
        <Controller
          control={control}
          name='select'
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                // variant={"standard"}
                value={value || ''}
                select
                sx={{ width: 110 }}
                label='Кол-во'
                onChange={onChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </TextField>
            );
          }}
        />
      </div>
    </Box>
  );
};
