export const styles = {
  formClearIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 25,
    top: 11,
    '&hover': {
      opacity: 0.7,
    },
  },
  formInputClearIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 12,
    top: 11,
    '&hover': {
      opacity: 0.7,
    },
  },
};
