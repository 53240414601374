import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  managers: [],
};

const allManagersSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    setManagers: (state, action) => {
      state.managers = action.payload;
    },
  },
});

export const { setManagers } = allManagersSlice.actions;
export default allManagersSlice.reducer;
