import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refetch: false,
};

const refetchSlice = createSlice({
  name: 'refetch',
  initialState,
  reducers: {
    setRefetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});
export const { setRefetch } = refetchSlice.actions;
export default refetchSlice.reducer;
