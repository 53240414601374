import { ReactNode } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { theme } from './components/common/componentColors';
import { MainLayout } from './components/layouts/main/vertical';
import { authRoutes, hiddenRoutes, mainRoutes } from './routes';

export interface RoutesProps {
  component: ReactNode;
  path: string;
}

export const App = () => {
  const authToken = window.localStorage.getItem('authToken');
  const location = useLocation();

  const authRender = ({ path, component }: RoutesProps) => (
    <Route
      key={path}
      element={component}
      path={path}
    />
  );

  const renderRoute = ({ path, component }: RoutesProps) => (
    <Route
      key={path}
      element={component}
      path={path}
    />
  );

  if (!authToken) {
    return (
      <Routes>
        {authRoutes.map(authRender)}
        <Route
          path='*'
          element={
            <Navigate
              replace
              state={{ from: location }}
              to='/login'
            />
          }
        />
      </Routes>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <MainLayout>
        <Routes>
          {mainRoutes.map(renderRoute)}
          {hiddenRoutes.map(renderRoute)}
          <Route
            path='*'
            element={
              <Navigate
                replace
                to='/404'
              />
            }
          />
        </Routes>
      </MainLayout>
    </ThemeProvider>
  );
};
