import React from 'react';
import toast from 'react-hot-toast';

const errorHandler = (data) => {
  if (typeof data === 'string') return toast.error(data);

  for (let key in data) {
    data[key].map((item) => {
      toast.error(item);
    });
  }
};

export default errorHandler;
