import React, { useEffect, useState } from 'react';
import TablePayments from '../../../common/tables/payments';
import { useNavigate } from 'react-router-dom';

const Payments = () => {
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const userRoleLocal = localStorage.getItem('userRole');
    setUserRole(userRoleLocal);
  }, []);

  if (userRole === 'driver') return navigate('/taskPage');

  return (
    <div>
      <TablePayments />
    </div>
  );
};

export default Payments;
