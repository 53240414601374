import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { theme } from '../componentColors';

export const HeaderPage = ({ title, children, cn }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: children ? 'space-between' : 'end',
          [theme.breakpoints.between(0, 473)]: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        {children}
        <Typography
          sx={{
            [theme.breakpoints.between(0, 473)]: {
              marginBottom: 2,
            },
            ...cn,
          }}
          variant={'h4'}
          align={'right'}
        >
          {title}
        </Typography>
      </Box>

      <Divider sx={{ my: 3 }} />
    </Box>
  );
};
