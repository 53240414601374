import { useEffect, useState } from 'react';

export const useAccessRole = () => {
  const [role, setRole] = useState('');

  useEffect(() => {
    const userRole = window.localStorage.getItem('userRole');
    setRole(userRole);
  }, []);

  return role;
};
