import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Box, CardHeader, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { apiGet, apiPost } from '../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { setAllRoles, setEachUserData } from '../../../../store/user';
import { Controller, useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import errorHandler from '../../../layouts/errorHandler';
import { Spinner } from '../../../common/spinner/Spinner';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';
import { TextFieldController } from '../../../common/formControllers';
import { theme } from '../../../common/componentColors';

const EachUser = () => {
  const { control, handleSubmit } = useForm({ mode: 'onBlur' });
  const [isFetch, setIsFetch] = useState(false);
  const { employeerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allRoles = useSelector((state) => state.user.allRoles);
  const eachUserData = useSelector((state) => state.user.eachUserData);

  useEffect(() => {
    // Получить все роли системы
    apiGet({
      url: '/user-role/index',
    }).then((res) => {
      if (res.error) return toast.error('Роли не получены');
      dispatch(setAllRoles(res.data));
    });

    // Получить данные пользователя
    apiGet({
      url: `/user/view?id=${employeerId}`,
    }).then((res) => {
      if (res.error) {
        if (res.status === 404) {
          navigate('/404');
        }
        return errorHandler(res.data);
      }
      dispatch(setEachUserData(res.data));
      setIsFetch(true);
    });
  }, []);

  const saveUserData = (data) => {
    let passwordData = {};
    passwordData.password = data?.password;

    for (let key in data) {
      if (data[key] === undefined) delete data[key];
    }

    if (passwordData.password !== undefined) {
      apiPost({
        url: `/user/password-update?id=${employeerId}`,
        postData: passwordData,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        toast.success('Пароль изменен');
      });
    }
    apiPost({
      url: `/user-profile/update?id=${employeerId}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Обновлено');
      navigate('/users');
    });
  };

  if (!isFetch) {
    return <Spinner />;
  }
  return (
    <div>
      <HeaderPage
        title={`${eachUserData.profile.surname} ${eachUserData.profile.name}`}
      />

      <form
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(saveUserData)}
      >
        <Card raised>
          <CardHeader title='Информация о сотруднике' />
          <div className='grid grid-cols-1 gap-4 p-4 mb-3 xl1205:grid-cols-3'>
            <TextFieldController
              name={'email'}
              control={control}
              label='Почта'
              defaultValue={eachUserData.email}
            />

            <TextFieldController
              name={'password'}
              control={control}
              label='Пароль'
            />

            <Controller
              select
              name='role'
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  select
                  sx={{ minWidth: 290 }}
                  defaultValue={eachUserData.role[0].role || ''}
                  label={'Роль пользователя'}
                  onBlur={onBlur}
                  onChange={onChange}
                >
                  {allRoles.map((role) => (
                    <MenuItem
                      key={role.role}
                      value={role.role}
                    >
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <TextFieldController
              name={'surname'}
              control={control}
              label='Фамилия'
              defaultValue={eachUserData.profile.surname}
            />

            <TextFieldController
              name={'name'}
              control={control}
              label='Имя'
              defaultValue={eachUserData.profile.name}
            />

            <TextFieldController
              name={'patronymic'}
              control={control}
              label='Отчество'
              defaultValue={eachUserData.profile.patronymic}
            />
          </div>

          <div className='px-4 pb-4'>
            <Button
              sx={{ maxWidth: 230 }}
              type='submit'
              variant='contained'
            >
              Сохранить
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
};

export default EachUser;
