import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiGet } from '../../../../api';
import toast from 'react-hot-toast';
import { setUsersList } from '../../../../store/user';
import { setShowingDialog } from '../../../../store/app';
import { API_LIMIT } from '../../../../constants/apiLimit';

const DEFAULT_VALUE = {
  page: 1,
  email: '',
  role: '',
  name: '',
};

export const useUsersListPage = () => {
  const methods = useForm({ defaultValues: DEFAULT_VALUE });
  const { watch } = methods;
  const userList = useSelector((state) => state.user.userList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lastPage, setLastPage] = useState(1);
  const [employId, setEmployId] = useState('');

  const goToStaff = (id) => {
    navigate(`/user/${id}`);
  };

  const getStaffList = async () => {
    const { data, error } = await apiGet({
      url: `/user/index?page=${watch(
        'page',
      )}&limit=${API_LIMIT}&fullName=${watch('name')}&email=${watch(
        'email',
      )}&role=${watch('role')}`,
    });

    if (error) return toast.error('Ошибка получения пользователей');
    dispatch(setUsersList(data.models));
    setLastPage(data.lastPage);
  };

  const deleteStaff = async () => {
    const { error } = await apiGet({
      url: `/user/delete?id=${employId}`,
    });

    if (error) return toast.error('Ошибка удаления');

    toast.success('Успешно удалено');
    await getStaffList();
  };

  const showModal = async (employerId) => {
    setEmployId(employerId);
    dispatch(setShowingDialog('user-delete'));
  };

  useEffect(() => {
    getStaffList();
  }, [watch('page'), watch('name'), watch('email'), watch('role')]);

  useEffect(() => {
    methods.setValue('page', 1);
  }, [watch('name'), watch('email'), watch('role')]);

  return { methods, userList, goToStaff, lastPage, deleteStaff, showModal };
};
