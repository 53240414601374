//Заполенность кол-во
export const dataFillMount = {
  freeLab: 'Свободно',
  busyLab: 'Занято',
  unAvailLab: 'Недоступно',
};

// Просроченные платежи
export const dataOverdue = [
  {
    sum: 85990,
    days: '1-4 дней',
    count: 3,
    bgColor: 'rgba(26,250,1,1)',
  },
  {
    sum: 15990,
    days: '5-10 дней',
    count: 4,
    bgColor: 'rgba(255,232,0,1)',
  },
  {
    sum: 25990,
    days: '11-40 дней',
    count: 5,
    bgColor: 'rgba(250,193,4,1)',
  },
  {
    sum: 18990,
    days: '41-84 дней',
    count: 2,
    bgColor: 'rgba(255,169,0,1)',
  },
  {
    sum: 12990,
    days: '84-100 дней',
    count: 4,
    bgColor: 'rgb(227,66,7)',
  },
  {
    sum: 64990,
    days: 'Более 100',
    count: 2,
    bgColor: 'rgba(255,0,0,1)',
  },
];

export const dataOverdueColor = {
  bgColor1: 'rgba(43,162,147,0.95)',
  bgColor2: '#b92727',
  bgColor3: '#d7d460',
  bgColor4: '#5cb256',
  bgColor5: '#475b91',
};

//оплата по месяцам
export const dataPayment = [
  {
    totalSum: '400000',
    month: 'Февраль',
    label: '',
  },
  {
    totalSum: '600000',
    month: 'Март',
    label: '',
  },
  {
    totalSum: '865000',
    month: 'Апрель',
    label: '',
  },
  {
    totalSum: '107000',
    month: 'Май',
    label: '',
  },
  {
    totalSum: '82000',
    month: 'Июнь',
    label: '',
  },
  {
    totalSum: '100000',
    month: 'Июль',
    label: '',
  },
  {
    totalSum: '107000',
    month: 'Август',
    label: '',
  },
  {
    totalSum: '180000',
    month: 'Сентябрь',
  },
  {
    totalSum: '800000',
    month: 'Октябрь',
  },
  {
    totalSum: '107000',
    month: 'Декабрь',
  },
  {
    totalSum: '80000',
    month: 'Ноябрь',
  },
];

//Выручка
export const dataRevenue = [
  {
    totalSum: '200000',
    month: 'Январь',
  },
  {
    totalSum: '400000',
    month: 'Февраль',
  },
  {
    totalSum: '600000',
    month: 'Март',
  },
  {
    totalSum: '865000',
    month: 'Апрель',
  },
  {
    totalSum: '107000',
    month: 'Май',
  },
  {
    totalSum: '82000',
    month: 'Июнь',
  },
  {
    totalSum: '100000',
    month: 'Июль',
  },
  {
    totalSum: '107000',
    month: 'Август',
  },
  {
    totalSum: '180000',
    month: 'Сентябрь',
  },
  {
    totalSum: '800000',
    month: 'Октябрь',
  },
  {
    totalSum: '107000',
    month: 'Декабрь',
  },
  {
    totalSum: '80000',
    month: 'Ноябрь',
  },
];

//Заполенность месяца
export const dataFillMonth = [
  {
    mount: '0',
    month: 'Январь',
  },
  {
    mount: '20',
    month: 'Февраль',
  },
  {
    mount: '30',
    month: 'Март',
  },
  {
    mount: '45',
    month: 'Апрель',
  },
  {
    mount: '60',
    month: 'Май',
  },
  {
    mount: '70',
    month: 'Июнь',
  },
  {
    mount: '90',
    month: 'Июль',
  },
  {
    mount: '111',
    month: 'Август',
  },
  {
    mount: '130',
    month: 'Сентябрь',
  },
  {
    mount: '140',
    month: 'Октябрь',
  },
  {
    mount: '160',
    month: 'Декабрь',
  },
  {
    mount: '190',
    month: 'Ноябрь',
  },
];

export const dataDoughnut = {
  labels: dataOverdue.map((d) => d.days),
  responsive: true,
  datasets: [
    {
      data: dataOverdue.map((i) => i.sum),
      backgroundColor: dataOverdue.map((bgc) => bgc.bgColor),
      borderColor: ['rgb(255,255,255)'],
      borderWidth: 2,
    },
  ],
};

export const dataBarwww = {
  labels: dataPayment.map((m) => m.month),
  responsive: true,
  datasets: [
    {
      label: 'Безнал',

      //Сумма
      data: dataPayment.map((sum) => sum.totalSum),
      backgroundColor: 'rgba(255, 179, 0,0.8)',
    },
    // {
    //   label: "Сбербанк",
    //   data: dataPayment.map((sum) => sum.totalSum * 0.53),
    //   backgroundColor: "rgba(2,136,208, 0.8)"
    // },
    // {
    //   label: "Наличные",
    //   data: dataPayment.map((sum) => sum.totalSum * 0.83),
    //   backgroundColor: "rgba(213,78,73,0.8)"
    // },
    // {
    //   label: "Безнал с НДС",
    //   data: dataPayment.map((sum) => sum.totalSum * 1.13),
    //   backgroundColor: "rgba(88,166,57,0.8)"
    // }
  ],
};

export const optionsLine = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'index',
      intersect: false,
    },
  },
};
