import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeContractsList: [],
};

const contractSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setActiveContractsList: (state, action) => {
      state.activeContractsList = action.payload;
    },
  },
});

export const { setActiveContractsList } = contractSlice.actions;

export default contractSlice.reducer;
