import * as React from 'react';
import './index.scss';
import { styled, useTheme } from '@mui/material/styles';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import UserDropdown from '../../userDropDown/UserDropdown';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import { useEffect, useState } from 'react';
import { mainRoutes } from '../../../../routes';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import logo from '../../../../assets/logo/img.png';
import { useMediaQuery } from '@mui/material';
import Menu from '@mui/material/Menu';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { ContactlessOutlined, Style } from '@mui/icons-material';
import Notifications from '../../notifications';
import { theme } from '../../../common/componentColors';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from '../../../../store/layout';
import DialogAddNewClient from '../../../common/dialogs/addNewClient';
import { apiGet } from '../../../../api';
import { setUserProfile } from '../../../../store/user';
import { setManagers } from '../../../../store/allManager';
import toast from 'react-hot-toast';
import { usePermission } from '../../../../hooks/usePermission';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StyleBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 1460)]: {
    padding: 14,
  },
}));
const StyleTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between(0, 700)]: {
    fontSize: 17,
  },
}));

export const MainLayout = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.layout.open);
  const location = useLocation();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const userRole = window.localStorage.getItem('userRole');
  const [userAccess, setUserAccess] = useState({});
  const [isFetch, setIsFetch] = useState(false);
  const [userRoleRus, setUserRoleRus] = useState(null);
  const navigate = useNavigate();

  const permissionUserList = usePermission(['user', 'index']);

  const handleDrawerOpen = () => {
    dispatch(setOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setOpen(false));
  };

  useEffect(() => {
    if (permissionUserList) {
      apiGet({
        url: `/user/index?role=manager&limit=1000`,
      }).then((res) => {
        if (res.error) {
          return toast.error('Ошибка получения мэнеджеров');
        }
        dispatch(setManagers(res.data.models));
      });
    }
  }, [dispatch, permissionUserList]);

  useEffect(() => {
    if (matches) dispatch(setOpen(true));
    if (!matches) dispatch(setOpen(false));
    if (userRole === 'driver') return dispatch(setOpen(false));
  }, [matches]);

  useEffect(() => {
    apiGet({ url: '/user/auth' })
      .then((res) => {
        if (res.error) {
          window.localStorage.removeItem('authToken');
          return navigate('/login');
        }
        window.localStorage.setItem('userRole', res.data.role[0].role);
        window.localStorage.setItem(
          'userRoleRu',
          res?.data?.role[0].roleTranslate,
        );
        setUserRoleRus(res?.data?.role[0].roleTranslate);
        dispatch(setUserProfile(res.data));
      })
      .catch((e) => {
        console.log(e);
        window.localStorage.removeItem('authToken');
        navigate('/login');
      });
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);
    setTimeout(() => setIsFetch(true), 500);
  }, []);

  if (!isFetch) {
    return <></>;
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          [theme.breakpoints.between(0, 992)]: { overflowX: open && 'hidden' },
        }}
      >
        <AppBar
          position='fixed'
          open={open}
        >
          <Toolbar>
            {userRole !== 'driver' && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box>
              {userAccess?.client['create-call'] && (
                <DialogAddNewClient
                  title={'Создать звонок'}
                  userRole={userRole}
                  isCall={true}
                />
              )}
            </Box>

            <StyleTypography
              variant='h6'
              noWrap
              component='div'
            >
              С заботой о комфортном строительстве
            </StyleTypography>

            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
              <Notifications />
              <UserDropdown />
            </Box>
          </Toolbar>
        </AppBar>

        {userRole !== 'driver' ? (
          <Drawer
            variant='permanent'
            open={open}
          >
            <DrawerHeader
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                background: '#b2dfdb',
              }}
            >
              <img
                className='img-logo'
                src={logo}
                alt='logo BestCabins'
              />

              <Typography
                sx={{ textTransform: 'capitalize', fontSize: 17 }}
                variant={'h6'}
              >
                {userRoleRus}
              </Typography>

              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>

            <List>
              {mainRoutes?.map((item, index) =>
                userAccess[item?.roleAccess[0]][item?.roleAccess[1]] ? (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: 'block', px: 0.7 }}
                  >
                    <ListItemButton
                      key={item.path}
                      to={item.path}
                      component={RouterLink}
                      selected={location.pathname === item.path}
                      sx={{
                        minHeight: 48,
                        px: 2.5,
                        borderRadius: '9px',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <div key={index}></div>
                ),
              )}
            </List>
          </Drawer>
        ) : (
          <></>
        )}
        <StyleBox
          component='main'
          sx={{
            flexGrow: 1,
            p: 3,
            [theme.breakpoints.between(0, 992)]: {
              pointerEvents: open && 'none',
              height: open && '100vh',
              overflowY: open && 'hidden',
            },
          }}
        >
          <DrawerHeader />
          {children}
        </StyleBox>
      </Box>
    );
  }
};
