import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TableSelectForPages } from '../selectForPages';
import { theme } from '../componentColors';
import { Box, TextField, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';

const StyledBoxWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 494)]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

export const TableSort = () => {
  const { control } = useFormContext();

  return (
    <StyledBoxWrap sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          mr: 2,
          [theme.breakpoints.between(0, 500)]: { paddingBottom: 1 },
        }}
      >
        <TableSelectForPages />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LocalizationProvider
          localeText={{
            okButtonLabel: 'Ок',
            cancelButtonLabel: 'Отменить',
          }}
          dateAdapter={AdapterDateFns}
          adapterLocale={ru}
        >
          <Box sx={{ mr: 2 }}>
            <Controller
              control={control}
              name='date_from'
              render={({ field: { onChange, value } }) => {
                const handleChangeDate = (e) => {
                  onChange(e);
                };

                return (
                  <DatePicker
                    inputProps={{
                      placeholder: 'дд.мм.гггг',
                    }}
                    inputFormat='dd.MM.yyyy'
                    label='С какого'
                    value={value || null}
                    onChange={(value) => handleChangeDate(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                );
              }}
            />
          </Box>
        </LocalizationProvider>
        <LocalizationProvider
          localeText={{
            okButtonLabel: 'Ок',
            cancelButtonLabel: 'Отменить',
          }}
          dateAdapter={AdapterDateFns}
          adapterLocale={ru}
        >
          <Box>
            <Controller
              control={control}
              name='date_to'
              render={({ field: { onChange, value } }) => {
                const handleChangeDate = (e) => {
                  onChange(e);
                };

                return (
                  <DatePicker
                    inputProps={{
                      placeholder: 'дд.мм.гггг',
                    }}
                    label='По какое'
                    inputFormat='dd.MM.yyyy'
                    value={value || null}
                    onChange={(value) => handleChangeDate(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                );
              }}
            />
          </Box>
        </LocalizationProvider>
      </Box>
    </StyledBoxWrap>
  );
};
