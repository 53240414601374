import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import React, { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { apiGet, apiPost } from '../../../../api';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';
import Fade from '@mui/material/Fade';
import { useParams } from 'react-router-dom';
import { Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import errorHandler from '../../../layouts/errorHandler';
import { DeleteForever } from 'mdi-material-ui';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Tooltip from '@mui/material/Tooltip';
import './index.scss';
import { theme } from '../../componentColors';
import { editBalance } from '../../../../helpers/BalanceHelper';
import { setRefetch } from '../../../../store/refetch/refetch';
import ClearIcon from '@mui/icons-material/Clear';

const TitleContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 767)]: {
    marginBottom: 0,
  },
}));
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 767)]: {
    flexDirection: 'column',
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.between(0, 767)]: {
    margin: 0,
    marginTop: 12,
    width: '100%',
  },
  [theme.breakpoints.between(767, 1033)]: {},
}));

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const AttachNewProductToContract = ({
  setRefresh,
  product_id,
  setCheckChangeRentContract = null,
  contract_Id,
  buttonType = false,
  getActualBalanceOfClient,
}) => {
  const { handleSubmit, control, setValue } = useForm();
  const [show, setShow] = useState(false);
  const [productList, setProductList] = useState('');
  const [idContract, setIdContract] = useState('');
  const { refetch } = useSelector((state) => state.refetch);
  const dispatch = useDispatch();

  // Доп свойства и услуги
  const [typeCategoryList, setTypeCategoryList] = useState([]);

  // CheckBoxes
  const [deliveryCheckBoxHandler, setDeliveryCheckBoxHandler] = useState(true);
  const [returnCheckBoxHandler, setReturnCheckBoxHandler] = useState(true);

  const productStatusList = useSelector(
    (state) => state.products.productStatusList,
  );

  // Категории товаров
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState('');
  const [typeCategoryId, setTypeCategoryId] = useState('');
  const [chooseProductList, setChooseProductList] = useState([]);

  // Место доставки
  const [typeList, setTypeList] = useState([]);
  const [placeDelivery, setPlaceDelivery] = useState(null);

  // Счета компании
  const [invoiceList, setInvoiceList] = useState([]);
  const [companyAccountId, setCompanyAccountId] = useState('');

  // type of payment
  const [typeOfPayment, setTypeOfPayment] = useState([
    { id: 1, value: 'По умолчанию' },
    {
      id: 2,
      value: 'До конца месяца',
    },
    { id: 3, value: 'Фактическая' },
  ]);
  const [typeOfPaymentId, setTypeOfPaymentId] = useState('');

  const [productSendList, setProductSendList] = useState([]);
  const [productTypeFetch, setProductTypeFetch] = useState(null);

  const [disableButton, setDisableButton] = useState(false);
  const [resultSumOfDelivery, setResultSumOfDelivery] = useState('');
  const [resultSumOfReturn, setResultSumOfReturn] = useState('');
  const [resultSumOfTotal, setResultSumOfTotal] = useState('');

  const [resultSumOfProducts, setResultSumOfProducts] = useState('');

  const sumOfProducts = resultSumOfProducts.toString().replace(/\D/g, '');
  const sumOfDelivery = editBalance(resultSumOfDelivery);
  const sumOfReturn = editBalance(resultSumOfReturn);
  const sumOfTotal = editBalance(resultSumOfTotal);

  const { contractId } = useParams();

  const [todayDate, setTodayDate] = useState('');

  const isCategoryChange = useSelector(
    (state) => state.app.isCategoryPropChange,
  );

  useEffect(() => {
    let arr = [];
    const newDate = new Date().toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setTodayDate(arr.join('-'));
  }, []);

  const attachStoreItemToContract = (formData) => {
    // 1 - Отправка
    // 2 - Возват

    for (let key in formData) {
      if (formData[key] === undefined) delete formData[key];
    }

    // formData.start = Date.parse(formData.start) / 1000
    // formData.start = formData.start

    // if(formData.duration !== undefined)
    //     formData.duration = Date.parse(formData.duration) / 1000
    // formData.duration = formData.duration

    if (formData.start === undefined) formData.start = todayDate;

    // formData.status = productList
    formData.payment_type = typeOfPaymentId;
    formData.additional_services = {};
    formData.products = {};
    productSendList.map((item, i) => {
      formData.products[i] = item.id;
    });

    // formData.product_id = typeCategoryId

    if (deliveryCheckBoxHandler) {
      formData.additional_services['0'] = {};
      formData.additional_services['0'].id = 1;
      formData.additional_services['0'].type = 1;
      formData.additional_services['0'].properties = placeDelivery;
    }

    if (returnCheckBoxHandler) {
      formData.additional_services['1'] = {};
      formData.additional_services['1'].id = 1;
      formData.additional_services['1'].type = 2;
      formData.additional_services['1'].properties = placeDelivery;
    }

    if (formData.payment_type === '') formData.payment_type = 2;
    setDisableButton(true);

    let newContractId;
    if (buttonType) {
      newContractId = contract_Id;
    } else {
      newContractId = contractId;
    }

    formData.sum = resultSumOfProducts;

    // setDisableButton(false)

    apiPost({
      url: `/rental-product/create?contract_id=${newContractId}&product_id=${product_id}&company_accounts_id=${companyAccountId}`,
      postData: formData,
    })
      .then((res) => {
        if (res.error) {
          setDisableButton(false);
          return errorHandler(res.data);
        }
        toast.success('Доп соглашение создано');
        closeDialog();
        getActualBalanceOfClient();
        dispatch(setRefetch(!refetch));
        if (buttonType) return setRefresh(true);
        if (setCheckChangeRentContract) setCheckChangeRentContract('ok');
      })
      .catch((e) => console.log(e));
  };

  // Получение категорий продукта
  const getAllCategory = () => {
    apiGet({
      url: '/category-type/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setProductCategoryList(res.data.models);
    });
  };

  const getTypeList = () => {
    apiGet({
      url: `/additional-properties/index`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeList(res.data.models);
    });
  };

  useEffect(() => {
    getTypeList();
  }, [isCategoryChange]);

  useEffect(() => {
    // Получение списка типов оплат
    apiGet({
      url: `/rental-product/type-list`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeOfPayment(res.data);
    });

    getAllCategory();

    getTypeList();

    apiGet({
      url: `/additional-properties/index`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeList(res.data.models);
    });

    // Получить все счета компании
    apiGet({
      url: '/company-accounts/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
    });
  }, []);

  const getTypeCategory = () => {
    apiGet({
      url: `/product-type/index?category_type_id=${productCategoryId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeCategoryList(res.data.models);

      if (res.data.models.length === 0) {
        apiGet({
          url: `/product/index?limit=500&category_id=${productCategoryId}`,
        }).then((res) => {
          if (res.error) return errorHandler(res.data);
          setChooseProductList(res.data.models);
        });
      }
    });
  };

  useEffect(() => {
    if (productCategoryId === '') return console.log('');

    // Получение типов категорий продукта
    getTypeCategory();
  }, [productCategoryId]);

  useEffect(() => {
    if (productCategoryId === '') return;

    apiGet({
      url: `/product/index?limit=1500&category_id=${productCategoryId}&product_type_id=${typeCategoryId}&status=1`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setChooseProductList(res.data.models);
    });
  }, [typeCategoryId, productCategoryId]);

  const handleChangeCheckBox = (event) => {
    setDeliveryCheckBoxHandler(event.target.checked);
    if (productSendList.length > 0) getPrices(event.target.checked, 'delivery');
  };

  const returnHandleChangeCheckBox = (event) => {
    setReturnCheckBoxHandler(event.target.checked);
    if (productSendList.length > 0) getPrices(event.target.checked, 'return');
  };

  const getTypesOfCategoryOnChange = (categoryId) => {
    setTypeCategoryId('');
    setProductTypeFetch(null);

    // setTypeCategoryId('')
    // setTypeCategoryList([])
    setProductCategoryId(categoryId);
    productCategoryList.map((item) => {
      if (categoryId === item.id) return setTypeCategoryList(item.productType);
    });
    // setTypeCategoryList(newTypeList)
    setProductTypeFetch(true);
    // setProductTypeFetch(true)
  };

  const getOriginalTypesOfCategoryOnChange = (typeId) => {
    setTypeCategoryId(typeId);
    // setProductCategoryId(typeId)
  };

  const getProductsIdCategoryOnChange = (value) => {
    let newProductList = [];
    let dataDelivery = {};
    let dataProducts = {};
    let data = {};
    const result = chooseProductList.filter(({ id }) => id !== value.id);
    setChooseProductList(result);

    setProductSendList((prevState) => [
      ...prevState,
      { id: value.id, name: value.name },
    ]);

    if (productSendList.length !== 0) {
      productSendList.map((item) => {
        newProductList.push(item.id);
      });
    }

    newProductList.push(value.id);
    dataProducts.products = newProductList;

    if (placeDelivery !== null) {
      dataDelivery.id = placeDelivery;
      dataDelivery.delivery = deliveryCheckBoxHandler;
      dataDelivery.return = returnCheckBoxHandler;
    }

    data.products = newProductList;
    data.delivery = dataDelivery;

    apiPost({
      url: `/rental-product/calculate`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setResultSumOfProducts(res?.data?.product_sum);
      setResultSumOfDelivery(res?.data?.delivery);
      setResultSumOfReturn(res?.data?.return);
      setResultSumOfTotal(res?.data?.total);
    });

    // setProductSendList(value)
  };

  const getPrices = (checked, whCheck, id) => {
    let data = {};
    let dataDelivery = {};
    let arr = [];

    productSendList.map((item) => {
      arr.push(item.id);
    });
    data.products = arr;
    dataDelivery.id = id ? id : placeDelivery;

    if (whCheck === 'delivery') dataDelivery.delivery = checked;
    else dataDelivery.delivery = deliveryCheckBoxHandler;
    if (whCheck === 'return') dataDelivery.return = checked;
    else dataDelivery.return = returnCheckBoxHandler;

    data.delivery = dataDelivery;

    if (data.products.length) {
      apiPost({
        url: `/rental-product/calculate`,
        postData: data,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        setResultSumOfProducts(res?.data?.product_sum);
        setResultSumOfDelivery(res?.data?.delivery);
        setResultSumOfReturn(res?.data?.return);
        setResultSumOfTotal(res?.data?.total);
      });
    }
  };

  const deleteProductSendList = ({ id, name }) => {
    let data = {};
    let dataProducts = {};
    let dataDelivery = {};
    setChooseProductList((prevState) => [{ id, name }, ...prevState]);
    const result = productSendList.filter((item) => item.id !== id);
    setProductSendList(result);
    let arr = [];
    result.map((item) => {
      arr.push(item.id);
    });
    dataProducts.products = arr;

    if (placeDelivery !== null) {
      dataDelivery.id = placeDelivery;
      dataDelivery.delivery = deliveryCheckBoxHandler;
      dataDelivery.return = returnCheckBoxHandler;
    }

    if (arr.length === 0) {
      dataDelivery.id = '';
      dataDelivery.delivery = false;
      dataDelivery.return = false;
    }

    data.products = arr;
    data.delivery = dataDelivery;

    apiPost({
      url: `/rental-product/calculate`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setResultSumOfProducts(res.data.product_sum);
      setResultSumOfDelivery(res?.data?.delivery);
      setResultSumOfReturn(res?.data?.return);
      setResultSumOfTotal(res?.data?.total);
    });
  };

  const deliveryPlaceClear = () => {
    setPlaceDelivery(null);
    let data = {};
    let dataDelivery = {};
    let arr = [];

    productSendList.map((item) => {
      arr.push(item.id);
    });

    if (placeDelivery === null) {
      dataDelivery.id = placeDelivery;
      dataDelivery.delivery = false;
      dataDelivery.return = false;
    }

    data.products = arr;
    data.delivery = dataDelivery;
    apiPost({
      url: `/rental-product/calculate`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setResultSumOfProducts(res.data.product_sum);
      setResultSumOfDelivery(res?.data?.delivery);
      setResultSumOfReturn(res?.data?.return);
      setResultSumOfTotal(res?.data?.total);
    });
  };

  const typeProductOnChange = (id) => {
    setPlaceDelivery(id);
    getPrices(false, '', id);
  };

  const companyAccountsIdOnChange = (id) => {
    setCompanyAccountId(id);
  };

  const paymentTypeOnChange = (e) => {
    setTypeOfPaymentId(e.target.value);
  };

  const closeDialog = () => {
    if (setCheckChangeRentContract) setCheckChangeRentContract('change');
    setValue('description', '');
    setDeliveryCheckBoxHandler(true);
    setReturnCheckBoxHandler(true);
    setProductSendList([]);
    setShow(false);
    setDisableButton(false);
    // setTypeCategoryList([])
    setTypeCategoryId('');
    setProductSendList([]);
    setProductTypeFetch(null);
    setDisableButton(false);
    setResultSumOfProducts('');
    setTypeCategoryList([]);
    setChooseProductList([]);
    setResultSumOfProducts('');
    setResultSumOfDelivery('');
    setResultSumOfReturn('');
    setResultSumOfTotal('');
  };
  const openDialog = () => {
    setShow(true);
  };

  const changeSumOfProductsOnChange = (e) => {
    setResultSumOfProducts(e.target.value);
    setResultSumOfTotal(
      parseInt(e.target.value) +
        parseInt(resultSumOfDelivery) +
        parseInt(resultSumOfReturn),
    );
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        {buttonType ? (
          <Tooltip title='Создать доп. соглашение'>
            <IconButton
              sx={{
                backgroundColor: '#fff',
                mr: 1,
                '&:hover': { backgroundColor: '#c2c2c2' },
              }}
              onClick={openDialog}
            >
              <NoteAddIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            color={'secondary'}
            variant='contained'
            onClick={openDialog}
          >
            Создать доп соглашение
          </Button>
        )}
      </Box>
      <Dialog
        disableRestoreFocus={true}
        fullWidth
        open={show}
        maxWidth='md'
        scroll='body'
        TransitionComponent={Transition}
      >
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit(attachStoreItemToContract)}
        >
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <TitleContainer sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Создать доп соглашение
              </Typography>
            </TitleContainer>
            <StyledBox sx={{ display: 'flex', mb: 2 }}>
              <FormControl fullWidth>
                <Controller
                  name='description'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <StyledTextField
                      value={value || ''}
                      label='Адрес установки'
                      onBlur={onBlur}
                      onChange={onChange}
                      name='description'
                      placeholder='Адрес установки'
                    />
                  )}
                />
              </FormControl>

              <Controller
                name='start'
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <StyledTextField
                    onBlur={onBlur}
                    onChange={onChange}
                    name='start'
                    label='С какого числа'
                    type='date'
                    defaultValue={todayDate}
                    sx={{ width: 250, mx: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <Controller
                name='duration'
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <StyledTextField
                    onBlur={onBlur}
                    onChange={onChange}
                    name='duration'
                    label='До какого числа'
                    type='date'
                    sx={{ width: 250 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </StyledBox>

            {/*<FormControl fullWidth>*/}
            {/*    <Controller*/}
            {/*        name='status'*/}
            {/*        control={control}*/}
            {/*        render={({field: {onBlur}}) => (*/}
            {/*            <Autocomplete*/}
            {/*                fullWidth*/}
            {/*                onBlur={onBlur}*/}
            {/*                name='status'*/}
            {/*                defaultValue={productStatusList.value}*/}
            {/*                getOptionLabel={(option) => option.value || ""}*/}
            {/*                onChange={(event, value) =>{*/}
            {/*                    if (value !== null) setProductList(value.key)*/}
            {/*                    else {*/}
            {/*                        return*/}
            {/*                    }*/}
            {/*                }}*/}
            {/*                disablePortal*/}
            {/*                options={productStatusList}*/}
            {/*                renderInput={(params) =>*/}
            {/*                    <TextField*/}
            {/*                        required={true}*/}
            {/*                        {...params}*/}
            {/*                        label="Статус объекта"/>}/>*/}
            {/*        )}/>*/}
            {/*</FormControl>*/}

            {/*<FormControl fullWidth>*/}
            {/*    <Controller*/}
            {/*        name='company_accounts_id'*/}
            {/*        control={control}*/}
            {/*        render={({field: {onBlur}}) => (*/}
            {/*            <Autocomplete*/}
            {/*                onBlur={onBlur}*/}
            {/*                name='company_accounts_id'*/}
            {/*                defaultValue={invoiceList?.name}*/}
            {/*                getOptionLabel={(option) => option?.name || ""}*/}
            {/*                onChange={(event, value) =>{*/}
            {/*                    if (value !== null) companyAccountsIdOnChange(value.id)*/}
            {/*                    else {*/}
            {/*                        return*/}
            {/*                    }*/}
            {/*                }}*/}
            {/*                disablePortal*/}
            {/*                options={invoiceList}*/}
            {/*                isOptionEqualToValue={(option, value) => option.id === value.id}*/}
            {/*                renderInput={(params) =>*/}
            {/*                    <TextField*/}
            {/*                        required={true}*/}
            {/*                        {...params}*/}
            {/*                        label="Счет зачисления"/>}/>)}/>*/}
            {/*</FormControl>*/}

            {/*<button onClick={() => console.log(typeOfPayment)}>show</button>*/}
            <TextField
              fullWidth
              select
              label={'Период отсчета оплаты'}
              name='payment_type'
              defaultValue={2}
              onChange={paymentTypeOnChange}
            >
              {typeOfPayment.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.key}
                >
                  {item.value}
                </MenuItem>
              ))}
            </TextField>

            {/*<FormControl fullWidth>*/}
            {/*    <Controller*/}
            {/*        name='payment_type'*/}
            {/*        control={control}*/}
            {/*        render={({field: {onBlur}}) => (*/}
            {/*            <Autocomplete*/}
            {/*                onBlur={onBlur}*/}
            {/*                name='payment_type'*/}
            {/*                defaultValue={1}*/}
            {/*                getOptionLabel={(option) => option?.value || ""}*/}
            {/*                onChange={(event, value) =>{*/}
            {/*                        paymentTypeOnChange(value.key)*/}
            {/*                }}*/}
            {/*                disablePortal*/}
            {/*                options={typeOfPayment}*/}
            {/*                isOptionEqualToValue={(option, value) => option.id === value.id}*/}
            {/*                renderInput={(params) =>*/}
            {/*                    <TextField*/}
            {/*                        {...params}*/}
            {/*                        label="Период отсчета оплаты"/>}/>)}/>*/}
            {/*</FormControl>*/}
            {/*<FormHelperText>Если не выбрано - будет расчитываться с 1-го числа*</FormHelperText>*/}

            {/* START Доп услуги доставка - Возврат ---------------------------------------*/}
            <Divider
              sx={{ mt: 3, mb: 2 }}
              textAlign='center'
            >
              Выбрать доп услуги
            </Divider>

            <FormControl fullWidth>
              <Controller
                name='typeProduct'
                control={control}
                render={({ field: { value, onBlur, onChange } }) => (
                  <Autocomplete
                    clearIcon={
                      <ClearIcon
                        fontSize='small'
                        onClick={() => {
                          deliveryPlaceClear();
                        }}
                      />
                    }
                    onBlur={onBlur}
                    name='typeProduct'
                    defaultValue={typeList?.name}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, value) => {
                      if (value !== null) typeProductOnChange(value.id);
                      else return;
                    }}
                    disablePortal
                    options={typeList}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Куда доставить'
                      />
                    )}
                  />
                )}
              />
            </FormControl>

            <FormControlLabel
              name='delivery'
              sx={{ ml: 1 }}
              label='Доставка'
              control={<Checkbox checked={deliveryCheckBoxHandler} />}
              onChange={handleChangeCheckBox}
            />

            <FormControlLabel
              name='returnDelivery'
              sx={{ ml: 1 }}
              label='Возврат'
              control={<Checkbox checked={returnCheckBoxHandler} />}
              onChange={returnHandleChangeCheckBox}
            />
            {/* END Доп услуги доставка - Возврат ---------------------------------------*/}

            {/* Start Товары - Категории товаров - Типы товаров---------------------------------------*/}
            <Divider
              sx={{ mt: 3, mb: 2 }}
              textAlign='center'
            >
              Прикрепить товары
            </Divider>

            <FormControl fullWidth>
              <Controller
                name='productCategory'
                control={control}
                render={({ field: { value, onBlur } }) => (
                  <TextField
                    select
                    label={'Категории товаров'}
                    defaultValue={value || ''}
                    onBlur={onBlur}
                    onChange={(e) => getTypesOfCategoryOnChange(e.target.value)}
                    name='productCategory'
                  >
                    {productCategoryList.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item?.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </FormControl>

            {typeCategoryList.length === 0 ? (
              <></>
            ) : (
              <FormControl fullWidth>
                <Controller
                  name='productType'
                  control={control}
                  render={({ field: { value, onBlur } }) => (
                    <TextField
                      sx={{ my: 2 }}
                      select
                      label={'Типы категорий товаров'}
                      // value={productTypeFetch !== null ? value : ''}
                      // onBlur={onBlur}
                      value={typeCategoryId || ''}
                      onChange={(e) =>
                        getOriginalTypesOfCategoryOnChange(e.target.value)
                      }
                      name='productType'
                    >
                      <MenuItem value={''}>Не выбрано</MenuItem>
                      {typeCategoryList.map((item, id) => (
                        <MenuItem
                          key={id.toString()}
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            )}

            <FormControl fullWidth>
              <Controller
                name='productList'
                control={control}
                render={({ field: { value, onBlur } }) => (
                  <Autocomplete
                    sx={typeCategoryList.length === 0 ? { mt: 2 } : { mt: 0 }}
                    onBlur={onBlur}
                    name='productList'
                    noOptionsText={'Нужно выбрать тип и категорию'}
                    defaultValue={chooseProductList?.name}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, value) => {
                      if (value !== null) getProductsIdCategoryOnChange(value);
                      else return;
                    }}
                    disablePortal
                    options={chooseProductList}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(props, chooseProductList) => (
                      <Box
                        component='li'
                        {...props}
                        key={chooseProductList.id}
                      >
                        {chooseProductList.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // required={true}
                        label='Список товаров'
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            {/*</>}*/}

            {/* END Товары - Категории товаров - Типы товаров---------------------------------------*/}

            {/*Start -----Сумма всех выбранных товаров*/}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                my: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
            >
              <TextField
                sx={{
                  maxWidth: '24%',
                  [theme.breakpoints.between(562, 910)]: {
                    minWidth: '48%',
                    marginBottom: 2,
                  },
                  [theme.breakpoints.between(0, 562)]: {
                    minWidth: '100%',
                    marginTop: 0,
                    marginBottom: 2,
                  },
                }}
                InputLabelProps={{ className: 'total-group-input-label' }}
                onChange={changeSumOfProductsOnChange}
                label={'Сумма выбранных товаров'}
                disabled={resultSumOfProducts === ''}
                value={sumOfProducts || ''}
              />
              <TextField
                sx={{
                  maxWidth: '24%',
                  [theme.breakpoints.between(562, 910)]: {
                    minWidth: '48%',
                    marginBottom: 2,
                  },
                  [theme.breakpoints.between(0, 562)]: {
                    minWidth: '100%',
                    marginTop: 0,
                    marginBottom: 2,
                  },
                }}
                InputLabelProps={{ className: 'total-group-input-label' }}
                disabled={true}
                label={'Сумма доставки'}
                value={sumOfDelivery}
              />
              <TextField
                sx={{
                  maxWidth: '24%',
                  [theme.breakpoints.between(562, 910)]: {
                    minWidth: '48%',
                    marginBottom: 2,
                  },
                  [theme.breakpoints.between(0, 562)]: {
                    minWidth: '100%',
                    marginTop: 0,
                    marginBottom: 2,
                  },
                }}
                InputLabelProps={{ className: 'total-group-input-label' }}
                disabled={true}
                label={'Сумма возврата'}
                value={sumOfReturn}
              />
              <TextField
                sx={{
                  maxWidth: '24%',
                  [theme.breakpoints.between(562, 910)]: {
                    minWidth: '48%',
                    marginBottom: 2,
                  },
                  [theme.breakpoints.between(0, 562)]: {
                    minWidth: '100%',
                    marginTop: 0,
                    marginBottom: 2,
                  },
                }}
                InputLabelProps={{ className: 'total-group-input-label' }}
                disabled={true}
                label={'Итого'}
                value={sumOfTotal}
              />
            </Box>

            {/*End -----Сумма всех выбранных товаров*/}

            <Box sx={{ mt: 2 }}>
              {productSendList.length !== 0 ? (
                <Typography
                  sx={{ ml: 1 }}
                  variant={'subtitle2'}
                >
                  Товары для доп соглашения:
                </Typography>
              ) : (
                <></>
              )}
              <Box sx={{ display: 'flex' }}>
                {productSendList?.map((item) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: 1,
                      borderRadius: 1,
                      m: 1,
                      pl: 2,
                      background: '#f5f5f5',
                    }}
                    key={item.id}
                  >
                    {item.name}
                    <IconButton onClick={() => deleteProductSendList(item)}>
                      <DeleteForever />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              disabled={disableButton}
              variant='contained'
              sx={{ marginRight: 1 }}
              type={'submit'}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={closeDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AttachNewProductToContract;
