import { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';
import TableCell, {
  type TableCellProps,
  tableCellClasses,
} from '@mui/material/TableCell';

interface Props extends TableCellProps {
  cursor?: 'pointer' | 'default';
}

export const CustomCell = ({
  children,
  cursor = 'default',
  ...props
}: PropsWithChildren<Props>) => (
  <StyledTableCell
    sx={{ cursor, py: 0.5, ...props.sx }}
    {...props}
  >
    {children}
  </StyledTableCell>
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#d0d0d0',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
