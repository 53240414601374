import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import CardContent from '@mui/material/CardContent';
import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { setAllRoles, setUsersList } from '../../../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { apiGet, apiPost } from '../../../../api';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';
import Fade from '@mui/material/Fade';
import { setActiveContractsList } from '../../../../store/contracts';
import { setCategoryList } from '../../../../store/rentObjects';
import errorHandler from '../../../layouts/errorHandler';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { DeleteForever } from '@mui/icons-material';
import axios from 'axios';
import { theme } from '../../componentColors';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { FormHelperText } from '@mui/material';
import { sxStyles } from '../../../../globalStyle/sxStyles';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 600)]: {
    flexDirection: 'column',
  },
}));

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  minWidth: 220,
  [theme.breakpoints.between(0, 600)]: {
    marginTop: 16,
  },
}));

const Input = styled('input')({
  display: 'none',
});

const DialogAddNewRentItem = ({
  setRentObjects,
  getAllProducts,
  getSortProducts,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const uploadRef = useRef(null);

  // Окно диалога
  const [show, setShow] = useState(false);

  // Типы категорий
  const [typeCategory, setTypeCategory] = useState([]);

  // Id Для категорий и типов
  const [typeId, setTypeId] = useState('');
  const [categoryId, setCategoryId] = useState('');

  // Статусы товаров
  const [productStatusList, setProductStatusList] = useState([]);

  const [generateName, setGenerateName] = useState('');

  const [selectedFile, setSelectedFile] = useState([]);
  const [urlSelectedFile, setUrlSelectedFile] = useState(null);

  // Все товары из redux
  const categoryList = useSelector((state) => state.rentObjects.categoryList);

  // Отслеживание изменение категорий в настройках
  const isCategoryChange = useSelector(
    (state) => state.app.isCategoryPropChange,
  );

  const createNewRentItem = (formData) => {
    console.log(formData, 8998);
    let filesArray = [];
    // formData.status = parseInt(formData.status)
    formData.price = formData.price;
    formData.category_id = categoryId;
    formData.product_type_id = typeId;

    formData.name = generateName;

    if (urlSelectedFile !== null) {
      urlSelectedFile.map((item) => {
        let object = {};
        object.extension = item
          .split('base64')[0]
          .split('/')[1]
          .slice(0, item.split('base64')[0].split('/')[1].length - 1);
        object.content = item.split('base64')[1].split('//Z')[0].substring(1);
        filesArray.push(object);
      });
      formData.files = JSON.stringify(filesArray);
    }

    // Создание товара
    apiPost({
      url: `/product/create`,
      postData: formData,
    })
      .then((res) => {
        if (res.error) return errorHandler(res.data);
        closeDialogAddNewProduct();
        toast.success('Товар создан');
        getAllProducts();
        getSortProducts();
      })
      .catch((e) => console.log(e));
  };

  const getCategoryList = () => {
    // Получение инфо о категории
    apiGet({
      url: '/category-type/index',
    }).then((res) => {
      if (res.error) return toast.error('Получение инфо о категории');
      dispatch(setCategoryList(res.data.models));
    });
  };

  useEffect(() => {
    getCategoryList();
  }, [isCategoryChange]);

  useEffect(() => {
    // Получить все статусы товаров
    apiGet({
      url: `/product/status-list`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setProductStatusList(res.data);
    });

    getCategoryList();
  }, []);

  const getTypeListOfCategory = (e) => {
    setCategoryId(e.target.value);

    apiGet({
      url: `/product-type/index?category_type_id=${e.target.value}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeCategory(res.data.models);
    });
  };

  const getTypeIdOnChange = (e) => {
    setTypeId(e.target.value);
  };

  const closeDialogAddNewProduct = () => {
    setShow(false);
    setValue('name', '');
    setValue('price', '');
    setValue('purchase_price', '');
    setValue('description', '');
    setTypeId('');
    setTypeCategory([]);
    setCategoryId('');
    clearErrors();
    reset();
    setGenerateName('');
    setUrlSelectedFile(null);
    setSelectedFile([]);
  };

  const openDialog = () => {
    apiGet({
      url: '/product/generate-name',
    }).then((res) => {
      setGenerateName(res.data);
      setShow(true);
    });
  };

  const nameOnChange = (e) => {
    setGenerateName(e.target.value);
  };

  const loadFile = (e) => {
    setSelectedFile((prev) => [...prev, ...e.target.files]);

    // /* Get files in array form */
    // const files = Array.from(e.target.files);

    /* Map each file to a promise that resolves to an array of image URI */
    Promise.all(
      [...selectedFile, ...e.target.files].map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        });
      }),
    ).then(
      (images) => {
        /* Как только все промисы будут разрешены, обновите состояние с помощью массива url изображения. */
        setUrlSelectedFile(images);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const deleteFile = (i) => {
    uploadRef.current.value = '';
    const filterArray = selectedFile.filter((_, index) => index !== i);
    const filterUrlArray = urlSelectedFile.filter((_, index) => index !== i);
    setSelectedFile(filterArray);
    setUrlSelectedFile(filterUrlArray);
  };

  return (
    <div>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={openDialog}
      >
        Добавить новый товар
      </Button>
      <Dialog
        fullWidth
        open={show}
        maxWidth='md'
        scroll='body'
        onClose={closeDialogAddNewProduct}
        TransitionComponent={Transition}
      >
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit(createNewRentItem)}
        >
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 3, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Добавить новый товар{' '}
              </Typography>
            </Box>

            <StyledBox sx={{ display: 'flex', gap: 2 }}>
              <TextField
                // sx={{mr: 2}}
                value={generateName || ''}
                label='Имя'
                error={Boolean(errors.name)}
                onChange={nameOnChange}
                name='name'
                fullWidth
              />

              <Controller
                name='price'
                control={control}
                rules={{ required: 'Необходимо заполнить "Цена аренды"' }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <div style={{ ...sxStyles.flexCol }}>
                    <StyledTextFieldPrice
                      value={value || ''}
                      error={Boolean(errors.price)}
                      label='Цена аренды'
                      type={'number'}
                      onBlur={onBlur}
                      onChange={onChange}
                      name='price'
                      fullWidth
                    />
                    <FormHelperText
                      sx={{ whiteSpace: 'nowrap', color: '#d32f2f' }}
                    >
                      {errors?.price?.message}
                    </FormHelperText>
                  </div>
                )}
              />

              <Controller
                name='purchase_price'
                control={control}
                rules={{ required: 'Необходимо заполнить "Цена покупки"' }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <div style={{ ...sxStyles.flexCol }}>
                    <StyledTextFieldPrice
                      sx={{ [theme.breakpoints.between(0, 600)]: { ml: 0 } }}
                      value={value || ''}
                      error={Boolean(errors.price)}
                      label='Цена покупки'
                      type={'number'}
                      onBlur={onBlur}
                      onChange={onChange}
                      name='purchase_price'
                      fullWidth
                    />
                    <FormHelperText
                      sx={{ whiteSpace: 'nowrap', color: '#d32f2f' }}
                    >
                      {errors?.['purchase_price']?.message}
                    </FormHelperText>
                  </div>
                )}
              />
            </StyledBox>

            <Controller
              name='category_id'
              control={control}
              rules={{ required: 'Необходимо заполнить "Категория"' }}
              render={({ field: { value, onChange, onBlur } }) => {
                const changeSelect = (e) => {
                  const { value } = e.target;
                  onChange(value);
                  getTypeListOfCategory(e);
                };
                return (
                  <Box sx={{ ...sxStyles.flexCol, my: 2 }}>
                    <TextField
                      select
                      value={value || ''}
                      defaultValue={''}
                      label='Категория'
                      error={Boolean(errors['category_id'])}
                      onChange={changeSelect}
                      name='category_id'
                      fullWidth
                    >
                      {categoryList.map((item) => (
                        <MenuItem
                          value={item.id}
                          key={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormHelperText
                      sx={{ whiteSpace: 'nowrap', color: '#d32f2f' }}
                    >
                      {errors?.['category_id']?.message}
                    </FormHelperText>
                  </Box>
                );
              }}
            />
            {!!typeCategory.length && (
              <TextField
                select
                sx={{ mb: 2 }}
                defaultValue={''}
                label='Тип товара'
                onChange={getTypeIdOnChange}
                name='product_type_id'
                fullWidth
              >
                {typeCategory.map((item) => (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <FormControl fullWidth>
              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    multiline
                    maxRows={4}
                    value={value || ''}
                    label='Описание'
                    onBlur={onBlur}
                    onChange={onChange}
                    name='description'
                    placeholder='Описание'
                  />
                )}
              />
            </FormControl>

            <Box
              type={'span'}
              sx={{ my: 1.5 }}
            >
              {urlSelectedFile === null ? (
                <></>
              ) : (
                urlSelectedFile.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      border: '1px solid #d7d7d7',
                      mr: 1,
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <img
                      style={{ height: '50px' }}
                      src={item}
                      alt='Photo uploaded'
                    />
                    <IconButton
                      sx={{ borderRadius: 0 }}
                      onClick={() => deleteFile(i)}
                    >
                      <DeleteForever
                        color={'primary'}
                        sx={{ width: 35 }}
                      />
                    </IconButton>
                  </Box>
                ))
              )}
            </Box>
            <label htmlFor='icon-button-file'>
              <Input
                defaultValue={''}
                accept='*'
                id='icon-button-file'
                type='file'
                ref={uploadRef}
                multiple
                onChange={loadFile}
              />
              <Button
                startIcon={<FileUploadOutlinedIcon />}
                component={'span'}
                size={'small'}
                variant={'contained'}
                color={'primary'}
              >
                Добавить фото
              </Button>
            </label>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              variant='contained'
              sx={{ mx: 1 }}
              type={'submit'}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={closeDialogAddNewProduct}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogAddNewRentItem;
