import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiGet } from '../../api';
import { dateReverseFormat } from '../../helpers/DateHelper';

const initialState = {
  eventData: null,
  eventsNameList: [],
  isEventDescription: false,
  eventsList: [],
};

export const getEventData = createAsyncThunk(
  '/task/index?sort=createdAt',
  async (_, { rejectedWithValue, dispatch }) => {
    let url = '';
    let role = window.localStorage.getItem('userRole');
    if (role === 'admin' || role === 'manager')
      url = '/task/index?limit=1000&sort=createdAt&role=';
    else url = '/task-executor/index';
    apiGet({
      url: url,
    }).then((res) => {
      if (res.error)
        return console.log(
          'нужно обработать ошибку из events redux async thunk',
        );
      let arr = [];
      res.data.models.map((item) => {
        let data = {};
        data.title = item?.nameText;
        data.id = item?.id;
        if (item.taskProduct.length === 0) {
          data.product_id = null;
          data.productName = null;
        } else {
          data.taskProduct = item?.taskProduct;
        }
        if (item.taskExecutor.length === 0) {
          data.taskComment = null;
        } else data.taskComment = item?.taskExecutor?.comment;
        data.description = item?.comment;
        data.status = item?.status;
        data.statusText = item?.statusText;
        data.color = item?.colorStatus;
        data.role = item?.user?.role[0]?.role;

        if (item.user !== null) {
          data.user = {};
          data.user.id = item?.user?.id;
          data.user.username = item?.user?.username;
          data.name = item?.user?.profile?.name;
          data.surname = item?.user?.profile?.surname;
        }

        data.date = dateReverseFormat(item.start);
        data.coordinates = item.coordinates;
        data.files = item.files;
        arr.push(data);
      });
      dispatch(setEventsList(arr));
    });
  },
);

const eventInfoSlice = createSlice({
  name: 'eventInfo',
  initialState,
  reducers: {
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },

    setEventNameList: (state, action) => {
      state.eventsNameList = action.payload;
    },

    setDialogEventDescription: (state, action) => {
      state.isEventDescription = action.payload;
    },

    setEventsList: (state, action) => {
      state.eventsList = action.payload;
    },
  },
  extraReducers: {
    [getEventData.pending]: () => console.log('pending'),
    [getEventData.fulfilled]: () => console.log('fulfilled'),
    [getEventData.rejected]: () => console.log('rejected'),
  },
});

export const {
  setEventData,
  setEventNameList,
  setDialogEventDescription,
  setEventsList,
} = eventInfoSlice.actions;

export default eventInfoSlice.reducer;
