import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = layoutSlice.actions;

export default layoutSlice.reducer;
