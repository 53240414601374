export const sxStyles = {
  mW270: {
    minWidth: 270,
  },
  mW240: {
    minWidth: 240,
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
};
