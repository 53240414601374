import React from 'react';
import { usePermission } from '../../../hooks/usePermission';

export const PermissionWrapper = ({ children, permits }) => {
  const permission = usePermission(permits);

  if (!permission) {
    return null;
  }

  return <>{children}</>;
};
