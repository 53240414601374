import { apiGet, apiPost } from '../../../../api';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import toast from 'react-hot-toast';
import Button from '@mui/material/Button';
import { ButtonGroup, Pagination, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import EachProductWrapper from '../../eachProductWrapper';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import EachContractWrapper from '../../eachContractWrapper';
import errorHandler from '../../../layouts/errorHandler';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { TableSelectForPages } from '../../selectForPages';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { theme } from '../../componentColors';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MenuItem from '@mui/material/MenuItem';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ruLocale from 'date-fns/locale/ru';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ru } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import browser from 'leaflet/src/core/Browser';
import { editBalance } from '../../../../helpers/BalanceHelper';
import { PermissionWrapper } from '../../permissionWrapper/PermissionWrapper';
import { FormProvider, useForm } from 'react-hook-form';
import { TableSort } from '../../taleSort/TableSort';
import ConfirmDialog from '../../dialogs/confirm';
import { setShowingDialog } from '../../../../store/app';
import { HeaderPage } from '../../headerPage/HeaderPage';
import { STATUS_TRANSACTION_COLORS_TABLE } from '../../../../constants/statusTransactionColor';
import { CustomCell } from '../CustomCell';
import { API_LIMIT } from '../../../../constants/apiLimit';
import { format } from 'date-fns';
import { dateFormat } from '../../../../helpers/DateHelper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#dedede',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [theme.breakpoints.between(0, 400)]: {
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledBoxDatePicker = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 460)]: {
    flexDirection: 'column',
  },
}));

const StyledDatePickerUpdate = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.between(0, 460)]: {
    marginLeft: 0,
    marginTop: 16,
  },
}));

const StyledTableTopRow = styled(TableCell)(({ theme }) => ({
  display: 'table-cell',
  textAlign: 'center',
  [theme.breakpoints.between(0, 592)]: {
    width: '100%',
  },
}));

const TablePayments = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const open = useSelector((state) => state.layout.open);
  const managers = useSelector((state) => state.managers.managers);
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const [showDialog, setShowDialog] = useState(false);

  const [extraContractList, setExtraContractList] = useState([]);
  const [totalSumPayment, setTotalSumPayment] = useState({});
  const [dateSave, setDateSave] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [userRole, setUserRole] = useState('');

  // Поиск по таблице
  const [managerSearch, setManagerSearch] = useState('');
  const [contractNumberSearch, setContractNumberSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [deletePaymentId, setDeletePaymentId] = useState('');
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [sortTableCompanyAccount, setSortTableCopanyAccount] = useState('');
  const [sortTableSum, setSortTableSum] = useState('');
  const [acceptSortSum, setAcceptSortSum] = useState('');
  const [acceptSortList, setAcceptSortList] = useState([]);
  const [companyAccountSortList, setCompanyAccountSortList] = useState([]);
  const [openConfirmUpdateDialog, setOpenConfirmUpdateDialog] = useState(false);
  const [openDialogLoad, setOpenDialogLoad] = useState(false);
  const [idInvoiceLoad, setIdInvoiceLoad] = useState('');

  // Модалка изменение отдельной оплаты
  const [updateStartDate, setUpdateStartDate] = useState(null);
  const [updateStopDate, setUpdateStopDate] = useState(null);
  const [updateSumPayment, setUpdateSumPayment] = useState('');
  const [invoiceList, setInvoiceList] = useState([]);
  const [idInvoiceTo, setIdInvoiceTo] = useState('');

  const [paymentBy, setPaymentBy] = useState('');
  const [paymentFrom, setPaymentFrom] = useState('');
  const [chevronSort, setChevronSort] = useState(false);
  const [userAccess, setUserAccess] = useState({});
  const [sumOfClient, setSumOfClient] = useState('');
  const [totalBalance, setTotalBalance] = useState('');
  const [abortedId, setAbortedId] = useState(null);

  // Открыть модалку закрытия оплаты
  const [openClosePaymentDialog, setOpenClosePaymentDialog] = useState(false);
  const [idForPaymentClose, setIdForPaymentClose] = useState('');

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const getInvoiceList = () => {
    apiGet({
      url: '/company-accounts/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
    });
  };

  const closeDialog = () => {
    setOpenClosePaymentDialog(false);
    setDateSave('');
    setPaymentId('');
    setShowDialog(false);
    setOpenConfirmDeleteDialog(false);
    setOpenConfirmUpdateDialog(false);
  };

  const clientOnChangeSearch = (e) => {
    setClientSearch(e.target.value);
    setPage(1);
  };

  const managerOnChangeSearch = (e) => {
    if (e.target.value === 99) return setManagerSearch('');
    setManagerSearch(e.target.value);
    setPage(1);
  };

  const contractNumberOnChangeSearch = (e) => {
    setContractNumberSearch(e.target.value);
    setPage(1);
  };

  // Список оплат клиентов
  const getPaymentTransaction = () => {
    // company_accounts_id
    apiGet({
      url: `/payment-transaction/index?page=${page}&status=${acceptSortSum}&company_accounts_id=${sortTableCompanyAccount}&fromDateActualPayment=${
        methods.watch('date_from')?.toLocaleDateString('ru-RU') || ''
      }&toDateActualPayment=${
        methods.watch('date_to')?.toLocaleDateString('ru-RU') || ''
      }&managerId=${managerSearch}&clientName=${clientSearch}&contractNumber=${contractNumberSearch}&limit=${
        methods.watch('select') || API_LIMIT
      }&sort=${sortTableSum}`,
    }).then((res) => {
      if (res.error) return toast.error(res.data);
      setTotalSumPayment(res.data.sum);
      setLastPage(res.data.lastPage);
      setExtraContractList(res.data.models);
    });
  };

  // Поиск по таблице
  useEffect(() => {
    const userRoleLocal = window.localStorage.getItem('userRole');
    setUserRole(userRoleLocal);

    // Список арендованных товаров клиентов
    getPaymentTransaction();
  }, [
    page,
    clientSearch,
    managerSearch,
    contractNumberSearch,
    sortTableSum,
    acceptSortSum,
    sortTableCompanyAccount,
    methods.watch('select'),
    methods.watch('date_from'),
    methods.watch('date_to'),
  ]);

  const getStatusList = () => {
    apiGet({
      url: `/payment-transaction/filter-list`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setAcceptSortList(res.data);
    });
  };
  const getCompanyList = () => {
    apiGet({
      url: `/company-accounts/index`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setCompanyAccountSortList(res.data.models);
    });
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);
    getInvoiceList();
    getStatusList();
    getCompanyList();
  }, []);

  // Акцептовка платежа
  const acceptPayment = (id, clientIdFunc, sum) => {
    getActualBalanceOfClient(clientIdFunc);
    setPaymentId(id);
    setShowDialog(true);
    setSumOfClient(sum);
  };

  // Отмена акцептовки
  const getAbortedPaymentId = (id) => {
    setAbortedId(id);
    dispatch(setShowingDialog('abortPayment'));
  };

  const abortPayment = async () => {
    const res = await apiGet({
      url: `/payment-transaction/cancel-acceptance?id=${abortedId}`,
    });
    if (res.error) return errorHandler(res.data);
    toast.success(res.data);
    getPaymentTransaction();
  };

  const confirmAcceptPayment = () => {
    let data = {};
    data.actual_payment_date = dateSave;
    data.sum = Number(sumOfClient);

    apiPost({
      url: `/payment-transaction/accepted?id=${paymentId}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      // Список арендованных товаров клиентов
      getPaymentTransaction();
      toast.success('Платеж акцептован');
      setShowDialog(false);
    });
  };

  const confirmUpdatePayment = () => {
    const data = {};
    data.sum = updateSumPayment;
    data.payment_from = updateStartDate;
    data.payment_by = updateStopDate;

    apiPost({
      url: `/payment-transaction/update?id=${paymentId}&company_accounts_id=${idInvoiceTo}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Обновлено');
      getPaymentTransaction();
      setOpenConfirmUpdateDialog(false);
    });
  };

  const confirmDeletePaymentItem = () => {
    apiGet({
      url: `/payment-transaction/delete?id=${deletePaymentId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      // Список арендованных товаров клиентов
      getPaymentTransaction();
      toast.success('Платеж удален');
    });
    setOpenConfirmDeleteDialog(false);
  };

  const deletePaymentItem = (id) => {
    setDeletePaymentId(id);
    setOpenConfirmDeleteDialog(true);
  };

  const acceptPaymentOnChange = (e) => {
    setDateSave(e.target.value);
  };

  const openDialogEditPayment = (id, sum, payment_by, payment_from) => {
    setPaymentBy(payment_by);
    setPaymentFrom(payment_from);
    setUpdateSumPayment(sum);
    setOpenConfirmUpdateDialog(true);
    setPaymentId(id);
  };

  const updatePaymentStopDate = (e) => {
    setUpdateStopDate(e.target.value);
  };

  const updatePaymentSumHandle = (e) => {
    setUpdateSumPayment(e.target.value);
  };

  const updatePaymentStartDate = (e) => {
    setUpdateStartDate(e.target.value);
  };

  const chooseInvoiceToItem = (e) => {
    setIdInvoiceTo(e.target.value);
  };

  const chooseCompanyAccount = (e) => {
    if (e.target.value === 99) return setSortTableCopanyAccount('');
    setSortTableCopanyAccount(e.target.value);
  };

  const sortSum = (sort = ``, chevron = false) => {
    setSortTableSum(sort);
    setChevronSort(chevron);
    setPage(1);
  };

  const sortAccept = (e) => {
    setAcceptSortSum(e.target.value);
    setPage(1);
  };

  const downloadInvoice = (type) => {
    apiGet({
      url: `/legal-document/check?id=${idInvoiceLoad}&type=${type}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      window.open(`${res.data.link}`);
      toast.success('Скачивается...');
      setOpenDialogLoad(false);
    });
  };

  const getActualBalanceOfClient = (itemId) => {
    apiGet({
      url: `/client-balance/view-balance?id=${itemId}`,
    }).then((res) => {
      if (res.error) return;
      setTotalBalance(res.data);
    });
  };

  const closePayment = (id) => {
    setIdForPaymentClose(id);
    setOpenClosePaymentDialog(true);
  };

  const openHandlerDialogLoad = (id) => {
    setIdInvoiceLoad(id);
    setOpenDialogLoad(true);
  };

  const confirmClosePaymentItem = () => {
    apiGet({
      url: `/payment-transaction/rejected?id=${idForPaymentClose}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Платеж отклонен');
      closeDialog();
      getPaymentTransaction();
    });
  };

  return (
    <Box>
      <HeaderPage
        cn={{ marginLeft: 2 }}
        title='Оплаты'
      >
        <FormProvider {...methods}>
          <TableSort />
        </FormProvider>
      </HeaderPage>

      <Box
        className={!open ? 'Overflow-box-show-menu' : 'Overflow-box'}
        sx={{ overflow: 'auto' }}
        component={Paper}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  [theme.breakpoints.between(0, 1400)]: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  },
                }}
              >
                <StyledTableTopRow
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    width: '100%',
                    p: 1,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    [theme.breakpoints.between(0, 470)]: {
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    },
                  }}
                  align='center'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      [theme.breakpoints.between(0, 470)]: {
                        width: '95%',
                        borderBottom: '1px solid #4273a1',
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: 15 }}>Общая сумма</Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {editBalance(totalSumPayment?.total, true)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      [theme.breakpoints.between(0, 470)]: {
                        width: '95%',
                        borderBottom: '1px solid #4273a1',
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: 15 }}>
                      Доставка/Возврат
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {editBalance(totalSumPayment?.deliveryReturn, true)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      [theme.breakpoints.between(0, 470)]: {
                        width: '95%',
                        borderBottom: '1px solid #4273a1',
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: 15 }}>Аренда</Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {editBalance(totalSumPayment?.rentalSum, true)}
                    </Typography>
                  </Box>
                </StyledTableTopRow>

                <StyledTableTopRow
                  sx={{
                    backgroundColor: theme.palette.acceptButton.main,
                    width: '100%',
                    p: 1,
                  }}
                  align='center'
                >
                  <Typography sx={{ fontSize: 15 }}>
                    {' '}
                    Акцептованные платежи{' '}
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {editBalance(totalSumPayment?.accepted, true)}
                  </Typography>
                </StyledTableTopRow>

                <StyledTableTopRow
                  sx={{
                    backgroundColor: theme.palette.abortButton.main,
                    width: '100%',
                    p: 1,
                  }}
                  align='center'
                >
                  <Typography sx={{ fontSize: 15 }}>
                    Не акцептованные платежи
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {editBalance(totalSumPayment?.not_accepted, true)}
                  </Typography>
                </StyledTableTopRow>
              </Box>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table
            sx={{ minWidth: 1310 }}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align='center'>Объекты аренды</StyledTableCell>
                <StyledTableCell align='center'>
                  <TextField
                    sx={{ width: 90 }}
                    fullWidth
                    defaultValue={''}
                    onChange={contractNumberOnChangeSearch}
                    label={'Договор'}
                  />
                </StyledTableCell>

                <StyledTableCell align='center'>
                  <TextField
                    sx={{ width: 80 }}
                    fullWidth
                    defaultValue={''}
                    onChange={clientOnChangeSearch}
                    label={'Клиент'}
                  />
                </StyledTableCell>

                <StyledTableCell align='center'>
                  Фактическая дата оплаты
                </StyledTableCell>

                <StyledTableCell align='center'>Дата создания</StyledTableCell>

                <StyledTableCell align='center'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      onClick={() => sortSum()}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 1,
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      {' '}
                      Сумма
                    </Box>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    >
                      {chevronSort ? (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => sortSum('sum', false)}
                        >
                          <ExpandMoreRoundedIcon
                            sx={{ width: 18, height: 18 }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => sortSum('-sum', true)}
                        >
                          <ExpandLessRoundedIcon
                            sx={{ width: 18, height: 18 }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </StyledTableCell>
                {userRole !== 'manager' && (
                  <StyledTableCell>
                    <TextField
                      sx={{ minWidth: 130 }}
                      fullWidth
                      label={'Менеджер'}
                      onChange={managerOnChangeSearch}
                      defaultValue=''
                      select
                    >
                      <MenuItem value={99}>Все</MenuItem>
                      {managers.map((user) => (
                        <MenuItem
                          key={user.id}
                          value={`${user?.id}`}
                        >
                          {`${user?.profile?.surname} ${user?.profile?.name}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </StyledTableCell>
                )}

                <StyledTableCell align='center'>
                  <TextField
                    sx={{ width: 155 }}
                    label={'Способ оплаты '}
                    defaultValue={''}
                    onChange={chooseCompanyAccount}
                    select
                  >
                    <MenuItem value={99}>Все</MenuItem>
                    {companyAccountSortList.map((i) => (
                      <MenuItem
                        key={i.id}
                        value={i.id}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </StyledTableCell>

                <StyledTableCell align='center'>Период</StyledTableCell>

                <StyledTableCell align='center'>
                  <TextField
                    sx={{
                      width: 100,
                      [theme.breakpoints.between(0, 660)]: {
                        marginTop: 2,
                        mx: 0,
                      },
                    }}
                    label={'Статус '}
                    defaultValue={''}
                    onChange={sortAccept}
                    select
                  >
                    <MenuItem value={''}>Все</MenuItem>
                    {acceptSortList.map((i) => (
                      <MenuItem
                        key={i.key}
                        value={i.key}
                      >
                        {i.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </StyledTableCell>

                <StyledTableCell align='center'>Действия</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {extraContractList.map((contract) => (
                <StyledTableRow key={contract.id}>
                  <CustomCell align='center'>
                    <Box sx={{ display: 'flex' }}>
                      {contract?.productRental?.products?.map((item) => (
                        <div key={item?.product?.id}>
                          <EachProductWrapper
                            status={item?.product?.status}
                            product_id={item?.product?.id}
                          >
                            {item?.product.name}
                          </EachProductWrapper>
                        </div>
                      ))}
                    </Box>
                  </CustomCell>

                  <CustomCell align='center'>
                    <EachContractWrapper
                      contract_id={contract?.productRental?.contract?.id}
                    >
                      {contract?.productRental?.contract?.number}
                    </EachContractWrapper>
                  </CustomCell>

                  <CustomCell align={'center'}>
                    <Button
                      component={RouterLink}
                      to={'/client/' + contract.productRental?.client?.id}
                    >
                      {contract?.clientName}
                    </Button>
                  </CustomCell>

                  <CustomCell align={'center'}>
                    {dateFormat(contract?.actual_payment_date) || '-'}
                  </CustomCell>

                  <CustomCell align={'center'}>
                    {contract?.createdAt}
                  </CustomCell>

                  <CustomCell align='center'>
                    {editBalance(contract?.sum)}
                  </CustomCell>

                  {userRole !== 'manager' && (
                    <CustomCell align='center'>
                      <Button
                        component={RouterLink}
                        to={'/user/' + contract?.manager?.id}
                      >
                        {contract?.manager?.fullName ||
                          contract?.manager?.email}
                      </Button>
                    </CustomCell>
                  )}

                  <CustomCell align={'center'}>
                    {contract?.companyAccounts?.name}
                  </CustomCell>

                  <CustomCell
                    sx={{ whiteSpace: 'nowrap' }}
                    align={'center'}
                  >
                    {contract.period}
                  </CustomCell>

                  <CustomCell align={'center'}>
                    <Box
                      sx={{
                        width: 100,
                        m: 'auto',
                        p: 1,
                        background:
                          STATUS_TRANSACTION_COLORS_TABLE[contract.status],
                        borderRadius: 1,
                      }}
                    >
                      {contract.statusText}
                    </Box>
                  </CustomCell>

                  <CustomCell
                    align='right'
                    component='th'
                    scope='row'
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {contract.status === 1 && (
                        <>
                          {userAccess['payment-transaction'].accepted && (
                            <Tooltip title='Выбрать дату для акцептования'>
                              <IconButton
                                sx={{
                                  backgroundColor: '#fff',
                                  mr: 1,
                                  '&:hover': { backgroundColor: '#c2c2c2' },
                                }}
                                onClick={() =>
                                  acceptPayment(
                                    contract.id,
                                    contract?.productRental.client?.id,
                                    contract?.sum,
                                  )
                                }
                                color='primary'
                              >
                                <CreditScoreIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}

                      {contract.status === 3 && (
                        <>
                          {userAccess['payment-transaction'][
                            'cancel-acceptance'
                          ] && (
                            <Tooltip title='Отменить акцептование'>
                              <IconButton
                                sx={{
                                  backgroundColor: '#fff',
                                  mr: 1,
                                  '&:hover': { backgroundColor: '#c2c2c2' },
                                }}
                                onClick={() => getAbortedPaymentId(contract.id)}
                                color='primary'
                              >
                                <CreditCardOffIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                      {userAccess['payment-transaction'].update ? (
                        <Tooltip title='Обновить данные оплаты'>
                          <IconButton
                            sx={{
                              backgroundColor: '#fff',
                              mr: 1,
                              '&:hover': { backgroundColor: '#c2c2c2' },
                            }}
                            onClick={() =>
                              openDialogEditPayment(
                                contract.id,
                                contract.sum,
                                contract.payment_by,
                                contract.payment_from,
                              )
                            }
                            color='primary'
                          >
                            <ModeEditIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <></>
                      )}

                      {userAccess['legal-document']['check'] && (
                        <Tooltip title='Выбрать файлы для скачивания'>
                          <IconButton
                            sx={{
                              backgroundColor: '#fff',
                              mr: 1,
                              '&:hover': { backgroundColor: '#c2c2c2' },
                            }}
                            color={'primary'}
                            onClick={() => openHandlerDialogLoad(contract.id)}
                          >
                            <SaveAltIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                      {contract.status === 1 && (
                        <>
                          {userAccess['payment-transaction'].rejected && (
                            <Tooltip title='Отклонить оплату'>
                              <IconButton
                                sx={{
                                  backgroundColor: '#fff',
                                  mr: 1,
                                  '&:hover': { backgroundColor: '#c2c2c2' },
                                }}
                                color={'abortButton'}
                                onClick={() => closePayment(contract.id)}
                              >
                                <DoDisturbIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}

                      {userAccess['payment-transaction'].delete ? (
                        <Tooltip title='Удалить оплату'>
                          <IconButton
                            sx={{
                              backgroundColor: '#fff',
                              mr: 1,
                              '&:hover': { backgroundColor: '#c2c2c2' },
                            }}
                            color={'abortButton'}
                            onClick={() => deletePaymentItem(contract.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </CustomCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          siblingCount={!matches ? 0 : 1}
          size={!matches ? 'small' : 'medium'}
          page={page}
          onChange={(event, value) => setPage(value)}
          count={lastPage}
          variant='outlined'
          shape='rounded'
        />
      </Box>

      {/*Start --------------Акцептовать платеж*/}
      <Dialog
        fullWidth
        open={showDialog}
        maxWidth='sm'
        // scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Акцептовать
            </Typography>
          </Box>
          <TextField
            sx={{ width: '50%', display: 'flex', m: 'auto' }}
            onChange={acceptPaymentOnChange}
            name='date'
            label='Выберите дату'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Divider sx={{ my: 2 }}>Данные клиента</Divider>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '60%',
              m: 'auto',
              [theme.breakpoints.between(0, 435)]: {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold' }}
              variant={'subtitle1'}
            >
              Баланс клиента
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold' }}
              variant={'subtitle1'}
            >
              {editBalance(totalBalance)}
            </Typography>
          </Box>

          <TextField
            sx={{ my: 2, mx: 'auto', display: 'flex', width: '60%' }}
            type={'number'}
            value={sumOfClient || ''}
            onChange={(e) => setSumOfClient(e.target.value)}
            label={'Сумма для списания'}
          />
        </DialogContent>

        <DialogActions
          sx={{
            pb: { xs: 8, sm: 12.5 },
            justifyContent: 'center',
            [theme.breakpoints.between(0, 460)]: { flexDirection: 'column' },
          }}
        >
          <Button
            variant='contained'
            sx={{
              marginRight: 1,
              [theme.breakpoints.between(0, 460)]: { marginRight: 0 },
            }}
            onClick={confirmAcceptPayment}
          >
            {dateSave === '' ? 'Акцептовать сегодняшней датой' : 'Акцептовать'}
          </Button>

          <Button
            sx={{ [theme.breakpoints.between(0, 460)]: { mt: 1 } }}
            variant='contained'
            color='secondary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/*End --------------Акцептовать платеж*/}

      {/* //Отмена акцептованного платежа */}
      <ConfirmDialog
        title='Подтвержаете отмену акцептования?'
        buttonTitle='Подтвердить'
        event={abortPayment}
        name='abortPayment'
      />

      {/*Start --------------Удаление платежа*/}
      <Dialog
        sx={{ zIndex: 99999 }}
        fullWidth
        open={openConfirmDeleteDialog}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Удалить платеж?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='abortButton'
            sx={{ marginRight: 1 }}
            onClick={confirmDeletePaymentItem}
          >
            Удалить
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/*End --------------Удаление платежа*/}

      {/*Start --------------Закрыть платеж*/}
      <Dialog
        sx={{ zIndex: 99999 }}
        fullWidth
        open={openClosePaymentDialog}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Вы уверены что хотите закрыть платеж?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='abortButton'
            sx={{ marginRight: 1 }}
            onClick={confirmClosePaymentItem}
          >
            Закрыть
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/*End --------------Закрыть платеж*/}

      {/*ВЫбрать файлы для скачивания*/}
      <Dialog
        sx={{ zIndex: 99999 }}
        fullWidth
        open={openDialogLoad}
        maxWidth='md'
        scroll='body'
        onClose={() => setOpenDialogLoad(false)}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Выберите файлы для скачивания
            </Typography>
          </Box>
          <Box>
            <ButtonGroup
              fullWidth
              size={'large'}
              orientation='vertical'
              aria-label='vertical contained button group'
              variant='contained'
            >
              <Button
                sx={{ marginBottom: 1 }}
                onClick={() => downloadInvoice(1)}
              >
                Скачать счет в Word
              </Button>
              <Button onClick={() => downloadInvoice(2)}>
                Скачать счет в PDF
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setOpenDialogLoad(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Start ---------Обновление данных по оплате*/}
      <Dialog
        fullWidth
        open={openConfirmUpdateDialog}
        maxWidth='sm'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Обновить данные по оплате
            </Typography>
          </Box>

          <StyledBoxDatePicker sx={{ display: 'flex' }}>
            <StyledDatePickerUpdate
              sx={{ mr: 1 }}
              fullWidth
              onChange={updatePaymentStartDate}
              name='payment_from'
              type='date'
              defaultValue={paymentFrom}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <StyledDatePickerUpdate
              sx={{ ml: 1 }}
              fullWidth
              onChange={updatePaymentStopDate}
              name='payment_by'
              type='date'
              defaultValue={paymentBy}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </StyledBoxDatePicker>

          <TextField
            sx={{ my: 2 }}
            fullWidth
            label={'Сумма платежа'}
            name={'sum'}
            value={updateSumPayment || ''}
            onChange={updatePaymentSumHandle}
          />

          <TextField
            onChange={chooseInvoiceToItem}
            required={true}
            defaultValue={''}
            select
            fullWidth
            label={'Куда направить средства'}
          >
            {invoiceList.map((invoiceItem) => (
              <MenuItem
                key={invoiceItem.id}
                onChange={chooseInvoiceToItem}
                value={invoiceItem.id}
              >
                {invoiceItem.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            sx={{ marginRight: 1 }}
            onClick={confirmUpdatePayment}
          >
            Обновить
          </Button>

          <Button
            variant='contained'
            color='secondary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/* End ---------Обновление данных по оплате*/}
    </Box>
  );
};

export default TablePayments;
