import React, { memo } from 'react';
import { ROLES_ICON } from '../../../../../constants/rolesIcon';
import '../index.scss';
import { stringSlice } from '../../../../../helpers/StringSlice';

// Вывод дополнительной информации о задаче в календаре
export const CalendarEvent = memo(({ info }) => {
  const eventInfo = info?.extendedProps;

  const renderProducts = (el) => {
    return (
      <div
        className='events-products-item'
        key={el?.product?.id}
      >
        {el?.product?.name}
      </div>
    );
  };

  return (
    <div className='calendar-event'>
      {eventInfo.role && (
        <div className='calendar-event-top'>
          {ROLES_ICON[eventInfo?.role]?.icon}

          <span>
            {eventInfo?.surname} {eventInfo?.name}
          </span>
        </div>
      )}

      <div className='calendar-event-title'>{info?.title}</div>

      <div className='events-products-wrap'>
        {eventInfo.taskProduct?.length > 2
          ? eventInfo.taskProduct?.slice(0, 2)?.map(renderProducts)
          : eventInfo.taskProduct?.map(renderProducts)}
        {eventInfo.taskProduct?.length > 2 &&
          `+ ${eventInfo.taskProduct?.length - 2}`}
      </div>

      <span className='calendar-event-description'>
        {stringSlice(eventInfo?.description)}
      </span>
    </div>
  );
});

CalendarEvent.displayName = 'CalendarEvent';
