import React, { useEffect } from 'react';
import InvoiceKeeper from '../../../layouts/invoiceKeeper';
import { useNavigate } from 'react-router-dom';
import { useRedirect } from '../../../../hooks/useRedirect';

const MoneyKeeper = () => {
  useRedirect();

  return (
    <div>
      <InvoiceKeeper />
    </div>
  );
};

export default MoneyKeeper;
