import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DialogAddNewRentItem from '../../../common/dialogs/newRentItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { apiGet } from '../../../../api';
import errorHandler from '../../../layouts/errorHandler';
import EachProductWrapper from '../../../common/eachProductWrapper';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { setProductInfo } from '../../../../store/products';
import { useRedirect } from '../../../../hooks/useRedirect';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';
import { PermissionWrapper } from '../../../common/permissionWrapper/PermissionWrapper';
import { Spinner } from '../../../common/spinner/Spinner';

const InputsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 1100)]: {
    flexDirection: 'column',
  },
}));

const ProductInputs = styled(TextField)(({ theme }) => ({
  '.css-6hp17o-MuiList-root-MuiMenu-list': {
    overflow: 'auto',
  },

  [theme.breakpoints.between(0, 446)]: {
    m: 1,
  },
  [theme.breakpoints.between(0, 1100)]: {
    margin: 0,
    marginTop: 12,
    width: '70vw',
  },
}));

const Products = () => {
  const [productList, setProductList] = useState([]);
  const [productCategory, setProductCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [productStatus, setProductStatus] = useState('');
  const [rentStatus, setRentStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isCategoryChange = useSelector(
    (state) => state.app.isCategoryPropChange,
  );
  const { control } = useForm({ mode: 'onBlur' });
  useRedirect();

  const getAllProducts = () => {
    setIsLoading(true);
    // Получение списка всех товаров
    apiGet({
      url: `/product/group-list?count=${100}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setProductList(res.data);
      setIsLoading(false);
    });
  };

  const getCategoryList = () => {
    // Получение всех категорий
    apiGet({
      url: '/category-type/index',
    })
      .then((res) => {
        if (res.error) return errorHandler(res.data);
        setCategoryList(res.data.models);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getCategoryList();
  }, [isCategoryChange]);

  useEffect(() => {
    // Получение списка всех товаров
    getAllProducts();

    getCategoryList();

    // Получение всех категорий
    apiGet({
      url: '/category-type/index',
    })
      .then((res) => {
        if (res.error) return errorHandler(res.data);
        setCategoryList(res.data.models);
      })
      .catch((e) => {
        console.log(e);
      });

    // Записать все доступные статусы для товаров
    apiGet({
      url: '/product/status-list',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setRentStatus(res.data);
    });
  }, []);

  const getSortProducts = () => {
    apiGet({
      url: `/product/group-list?count=${100}&status=${productStatus}&category=${productCategory}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setProductList(res.data);
    });
  };

  useEffect(() => {
    if (productStatus === '' && productCategory === '') {
      // Получение списка всех товаров
      getAllProducts();
    }
    getSortProducts();
  }, [productStatus, productCategory]);

  const productCategoryOnChange = (e) => {
    setProductCategory(e.target.value);
  };

  const productStatusOnChange = (e) => {
    setProductStatus(e.target.value);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <HeaderPage title='Объекты аренды'>
        <PermissionWrapper permits={['product', 'create']}>
          <DialogAddNewRentItem
            getAllProducts={getAllProducts}
            getSortProducts={getSortProducts}
          />
        </PermissionWrapper>
      </HeaderPage>
      <InputsBox sx={{ display: 'flex', mb: 2 }}>
        <FormControl fullWidth>
          <Controller
            name='category_id'
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <ProductInputs
                sx={{ mr: 2 }}
                select
                label={'Категории товаров'}
                name='category_id'
                defaultValue={''}
                onBlur={onBlur}
                onChange={productCategoryOnChange}
              >
                <MenuItem value={''}>Все</MenuItem>
                {categoryList.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </ProductInputs>
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name='category_id'
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <ProductInputs
                select
                label={'Статус'}
                name='category_id'
                defaultValue={''}
                onBlur={onBlur}
                onChange={productStatusOnChange}
              >
                <MenuItem value={''}>Все</MenuItem>
                {rentStatus.map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.key}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </ProductInputs>
            )}
          />
        </FormControl>
      </InputsBox>
      {productList?.map((category) => (
        <div key={category?.id}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                sx={{ mr: 2 }}
                variant={'h5'}
              >
                {category?.name}
              </Typography>
            </div>
            <div>
              <Typography variant={'subtitle2'}>
                Всего - <strong>{category?.countByStatus?.total}</strong>, На
                ремонте - <strong>{category?.countByStatus?.onRepair}</strong>,
                Ожидает съезда -{' '}
                <strong>
                  {category?.countByStatus?.waitingForTheConvention}
                </strong>
                , Свободно - <strong>{category?.countByStatus?.free}</strong>
              </Typography>
            </div>
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />

          {category?.items?.map((item, i) => (
            <Box
              key={i}
              sx={{ mb: 4 }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {item?.items?.map((product) => (
                  <div key={product?.id}>
                    {/*<button onClick={() => console.log(product)}>show</button>*/}
                    <EachProductWrapper
                      price={product.price}
                      isEnabled={true}
                      statusText={product.statusText}
                      rentalContract={product?.productRental}
                      status={product?.status}
                      type={product?.productType}
                      product_id={product?.id}
                    >
                      {product?.name}
                    </EachProductWrapper>
                  </div>
                ))}
              </Box>
            </Box>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Products;
