import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import { apiGet, apiPost } from '../../../api';
import { setProductInfo } from '../../../store/products';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { DialogContentText, DialogTitle } from '@mui/material';
import errorHandler from '../errorHandler';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { DeleteForever } from '@mui/icons-material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { editBalance } from '../../../helpers/BalanceHelper';

const EachProductInfo = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const uploadRef = useRef(null);

  const [isFetch, setIsFetch] = useState(false);
  const [categoryList, setCategoryList] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [urlSelectedFile, setUrlSelectedFile] = useState(null);
  // Статус для товара
  const [rentStatus, setRentStatus] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [typeCategoryList, setTypeCategoryList] = useState([]);
  const [openSubmitDeleteProduct, setOpenSubmitDeleteProduct] = useState(false);
  const [showModalDeletePhoto, setShowModalDeletePhoto] = useState(false);
  const [photoId, setPhotoId] = useState('');
  const [userAccess, setUserAccess] = useState({});

  const dispatch = useDispatch();
  const productInfo = useSelector((state) => state.products.productInfo);
  const { control, handleSubmit, setValue, watch } = useForm({
    mode: 'onBlur',
  });

  const userRole = window.localStorage.getItem('userRole');
  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    // Записать все доступные статусы для товаров
    apiGet({
      url: '/product/status-list',
    }).then((res) => {
      setRentStatus(res.data);
    });

    getProductInfo();

    apiGet({
      url: '/category-type/index',
    }).then((res) => {
      setCategoryList(res.data.models);
      setIsFetch(true);
    });
  }, []);

  const getProductInfo = () => {
    apiGet({
      url: `/product/view?id=${productId}`,
    }).then((res) => {
      if (res.error) return;
      dispatch(setProductInfo(res.data));
    });
  };

  const submit = (data) => {
    if (data.name === undefined) data.name = productInfo.name;

    for (let key in data) {
      if (data[key] === undefined) delete data[key];
    }

    data.category_id = categoryId;

    if (selectedFile.length > 0) {
      // const files = Array.from(selectedFile)
      let formData = new FormData();

      selectedFile.forEach((file) => {
        formData.append('files[]', file);
      });

      // setProgressBar(true)
      // Сохранить фото
      apiPost({
        url: `/product/upload-document?id=${productId}`,
        postData: formData,
      }).then((res) => {
        if (res.error) {
          return errorHandler(res.data);
        }
        toast.success('Фото загружены');
        // setProgressBar(false)
        // dispatch(setDialogEventDescription(false))
        setUrlSelectedFile(null);
      });
    }

    apiPost({
      url: `/product/update?id=${productId}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Успешно');
      navigate('/products');
    });
  };

  const loadFile = (e) => {
    setSelectedFile((prev) => [...prev, ...e.target.files]);

    /* Map each file to a promise that resolves to an array of image URI */
    Promise.all(
      [...selectedFile, ...e.target.files].map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        });
      }),
    ).then(
      (images) => {
        setUrlSelectedFile(images);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const deleteFile = (i) => {
    uploadRef.current.value = '';
    const filterArray = selectedFile.filter((_, index) => index !== i);
    const filterUrlArray = urlSelectedFile.filter((_, index) => index !== i);
    setSelectedFile(filterArray);
    setUrlSelectedFile(filterUrlArray);
  };

  const openFile = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleClose = () => {
    setOpenSubmitDeleteProduct(false);
  };

  const handleCloseModalDeletePhoto = () => {
    setShowModalDeletePhoto(false);
  };

  const handleShowModalDeletePhoto = (id) => {
    setPhotoId(id);
    setShowModalDeletePhoto(true);
  };

  const submitDeletePhoto = () => {
    apiGet({
      url: `/product/delete-document?id=${photoId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Фото Успешно удалено');
      handleCloseModalDeletePhoto();
      getProductInfo();
    });
  };

  const submitDeleteProduct = () => {
    apiGet({
      url: `/product/delete?id=${productId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      navigate('/products');
      toast.success('Успешно');
    });
  };

  const deleteProduct = () => {
    setOpenSubmitDeleteProduct(true);
  };

  const categoryOnChangeHandler = (e) => {
    setCategoryId(e.target.value);
  };

  useEffect(() => {
    if (categoryId === '') {
      setCategoryId(productInfo.category.id);
      return;
    }
    apiGet({
      url: `/product-type/index?category_type_id=${categoryId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeCategoryList(res.data.models);
    });
  }, [categoryId]);

  if (!isFetch) {
    return <></>;
  } else {
    return (
      <Box sx={{ '& .MuiTextField-root': { m: 2 } }}>
        <form onSubmit={handleSubmit(submit)}>
          <Card
            raised
            sx={{ my: 2 }}
          >
            <FormControl fullWidth>
              <Controller
                name='name'
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    name='name'
                    disabled={!userAccess?.product?.update}
                    label='Имя'
                    defaultValue={
                      productInfo?.name === null ? '' : productInfo?.name
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name='description'
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    name='description'
                    label='Описание'
                    disabled={!userAccess?.product?.update}
                    defaultValue={
                      productInfo?.description === null
                        ? ''
                        : productInfo?.description
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name='price'
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    name='price'
                    label='Цена аренды'
                    disabled={!userAccess?.product?.update}
                    defaultValue={
                      productInfo?.price === null ? '' : productInfo?.price
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>

            {userRole === 'admin' && (
              <FormControl fullWidth>
                <Controller
                  name='purchase_price'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      label='Цена покупки'
                      disabled={!userAccess?.product?.update}
                      // value={value | 0}
                      defaultValue={
                        productInfo?.purchase_price === null
                          ? ''
                          : productInfo?.purchase_price
                      }
                    />
                  )}
                />
              </FormControl>
            )}

            <FormControl fullWidth>
              <Controller
                name='status'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    name='status'
                    onBlur={onBlur}
                    select
                    onChange={onChange}
                    disabled={!userAccess?.product?.update}
                    label={productInfo?.statusText}
                    value={value || ''}
                  >
                    {rentStatus.map((item) => (
                      <MenuItem
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                name='createdAt'
                disabled
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: userAccess.product.update && 'black',
                  },
                }}
                label={'Дата создания'}
                defaultValue={productInfo?.createdAt}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                select
                disabled={!userAccess?.product?.update}
                name='category_id'
                onChange={categoryOnChangeHandler}
                label={'Категория'}
                value={categoryId || ''}
              >
                {categoryList.map((item) => (
                  <MenuItem
                    key={item?.id}
                    value={item.id}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name='product_type_id'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    select
                    disabled={!userAccess?.product?.update}
                    name='product_type_id'
                    onBlur={onBlur}
                    onChange={onChange}
                    label={'Тип товара'}
                    defaultValue={productInfo?.productType?.id}
                  >
                    {typeCategoryList.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </FormControl>

            {userAccess.product['upload-document'] && (
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <label
                  style={{ marginRight: 16 }}
                  htmlFor='icon-button-file'
                >
                  <input
                    accept='*'
                    ref={uploadRef}
                    id='icon-button-file'
                    type='file'
                    hidden
                    multiple
                    onChange={loadFile}
                  />
                  <Tooltip title='Добавить фото товара'>
                    <IconButton
                      color='primary'
                      component='span'
                    >
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  </Tooltip>
                </label>
              </Box>
            )}

            <Box
              type={'span'}
              sx={{ my: 1, mx: 2, display: 'flex', flexWrap: 'wrap' }}
            >
              {urlSelectedFile === null ? (
                <></>
              ) : (
                urlSelectedFile.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      border: '1px solid #d7d7d7',
                      mr: 1,
                      marginTop: 1,
                      display: 'flex',
                    }}
                  >
                    <img
                      style={{ height: 50, width: 80, objectFit: 'cover' }}
                      src={item}
                      alt='Photo uploaded'
                    />
                    <IconButton
                      sx={{ borderRadius: 0 }}
                      onClick={() => deleteFile(i)}
                    >
                      <DeleteForever
                        color={'primary'}
                        sx={{ width: 35 }}
                      />
                    </IconButton>
                  </Box>
                ))
              )}
            </Box>

            <Divider sx={{ my: 1 }}>
              {productInfo?.files?.length !== 0
                ? 'Фото товара'
                : 'Фотографий нет'}
            </Divider>

            <Box
              sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            >
              {productInfo?.length !== 0 &&
                productInfo?.files?.map((i) => (
                  <a
                    key={i?.id}
                    style={{ marginLeft: 16, position: 'relative' }}
                  >
                    {userAccess.product['delete-document'] && (
                      <IconButton
                        onClick={() => handleShowModalDeletePhoto(i?.id)}
                        sx={{
                          position: 'absolute',
                          zIndex: 21,
                          bottom: 110,
                          width: 30,
                          height: 30,
                          right: 12,
                          backgroundColor: 'rgba(255,255,255,0.6)',
                          '&:hover': {
                            backgroundColor: 'rgba(255,255,255,0.9)',
                          },
                        }}
                      >
                        <ClearRoundedIcon />
                      </IconButton>
                    )}
                    <img
                      onClick={() => openFile(i?.publicLink)}
                      style={{
                        borderRadius: 10,
                        width: 240,
                        height: 140,
                        objectFit: 'cover',
                        zIndex: 20,
                        marginTop: 6,
                        marginRight: 6,
                      }}
                      src={i?.publicLink}
                      alt='Фото товара'
                    />
                  </a>
                ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {userAccess.product.update && (
                <Button
                  sx={{ m: 2 }}
                  type={'submit'}
                  variant={'contained'}
                >
                  Сохранить
                </Button>
              )}
              {userAccess.product.delete && (
                <Button
                  sx={{ m: 2 }}
                  color={'abortButton'}
                  variant={'contained'}
                  onClick={deleteProduct}
                >
                  Удалить
                </Button>
              )}
            </Box>
          </Card>
        </form>

        <Dialog
          open={showModalDeletePhoto}
          onClose={handleCloseModalDeletePhoto}
        >
          <DialogTitle>Удалить Фото?</DialogTitle>
          <DialogContent>
            Удаленное фото невозоможно будет восстановить
          </DialogContent>
          <DialogActions>
            <Button
              color={'abortButton'}
              onClick={submitDeletePhoto}
            >
              Удалить
            </Button>
            <Button
              onClick={handleCloseModalDeletePhoto}
              autoFocus
            >
              Отменить
            </Button>
          </DialogActions>
        </Dialog>

        {/*Удалить товар целиком*/}

        <Dialog
          maxWidth={'sm'}
          open={openSubmitDeleteProduct}
          onClose={handleClose}
        >
          <DialogTitle>Удалить товар?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Товар будет удален только в том случае, если он непривязан к доп
              соглашению
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color={'abortButton'}
              onClick={submitDeleteProduct}
            >
              Удалить
            </Button>
            <Button
              onClick={handleClose}
              autoFocus
            >
              Отменить
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
};

export default EachProductInfo;
