import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationsCounter: '0',
  allNotificationsList: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsCounter: (state, action) => {
      state.notificationsCounter = action.payload;
    },
    setAllNotificationsList: (state, action) => {
      state.allNotificationsList = action.payload;
    },
  },
});

export const { setNotificationsCounter, setAllNotificationsList } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
