import IconButton from '@mui/material/IconButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { apiGet } from '../../../api';
import errorHandler from '../errorHandler';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import { DialogContentText, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllNotificationsList,
  setNotificationsCounter,
} from '../../../store/notifications';

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationsCounter = useSelector(
    (state) => state.notifications.notificationsCounter,
  );
  const allNotificationList = useSelector(
    (state) => state.notifications.allNotificationsList,
  );
  const [notificationList, setNotificationList] = useState([]);
  // const [notificationCounter, setNotificationCounter] = useState('0')
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubmitNotifications, setOpenSubmitNotifications] = useState(false);
  const openMenuNotification = Boolean(anchorEl);
  const navigate = useNavigate();

  // Получить все новые свои уведомления
  const getNotificationList = () => {
    apiGet({
      url: `/notifications/list`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      dispatch(setNotificationsCounter(res.data.count));
      setNotificationList(res.data.models);
    });
  };

  useEffect(() => {
    // Получить все новые свои уведомления
    getNotificationList();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubmitNotifications(false);
  };

  const closeNotification = (id) => {
    // Отметить уведомление как прочитанное
    apiGet({
      url: `/notifications/view?id=${id}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      // пометить задачи в архиве как просмотреннные
      const newList = allNotificationList.map((i) =>
        i.id === id ? { ...i, status: 2 } : i,
      );
      dispatch(setAllNotificationsList(newList));
      // Получить все новые свои уведомления
      getNotificationList();
    });
  };

  const clearAllNotifications = () => {
    setOpenSubmitNotifications(true);
  };

  const openNotificationsHandler = (event) => {
    getNotificationList();
    setAnchorEl(event.currentTarget);
  };

  const openArchiveNotifications = () => {
    navigate('/archive-notifications');
    handleClose();
  };

  const submitClearAllNotifications = () => {
    // Отметить все уведомления прочитанными
    apiGet({
      url: `/notifications/clear-all?id=${notificationList[0].id}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      // setNotificationList(newList);
      getNotificationList();
      // пометить задачи в архиве как просмотреннные
      const newList = allNotificationList.map((i) => ({ ...i, status: 2 }));
      dispatch(setAllNotificationsList(newList));
    });
    setOpenSubmitNotifications(false);
  };

  return (
    <div>
      <Tooltip title='Уведомления'>
        <IconButton
          onClick={openNotificationsHandler}
          id='basic-button'
          aria-haspopup='true'
          aria-expanded={openMenuNotification ? 'true' : undefined}
          aria-controls={openMenuNotification ? 'basic-menu' : undefined}
          sx={{ position: 'relative' }}
          component='span'
        >
          {!!notificationsCounter ? (
            <Badge
              badgeContent={notificationsCounter}
              color='secondary'
              max={99}
            >
              <NotificationsActiveIcon />
            </Badge>
          ) : (
            <NotificationsActiveIcon />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: 1.7 }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        id='basic-button'
        anchorEl={anchorEl}
        open={openMenuNotification}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            size='small'
            onClick={openArchiveNotifications}
          >
            Архив
          </Button>
          {notificationsCounter > 2 && (
            <Button
              variant='outlined'
              size='small'
              onClick={clearAllNotifications}
              color='secondary'
            >
              Очистить
            </Button>
          )}
        </Box>

        {!notificationsCounter && (
          <span className='text-black font-medium text-sm px-1'>
            Новых уведомлений нет
          </span>
        )}

        <Box
          sx={{
            '&::-webkit-scrollbar': {
              backgroundColor: '#ffffff',
              width: 6,
            },
            '&::-webkit-scrollbar-thumb': {
              width: 6,
              backgroundColor: '#b2dfdb',
              borderRadius: 3,
            },
            overflowY: 'auto',
            width: '100%',
            maxHeight: 250,
          }}
        >
          {notificationList.map((item) => (
            <Box
              key={item.id}
              sx={{ width: 250, p: '4px' }}
            >
              <Card
                sx={{
                  position: 'relative',
                  p: '2px 8px',
                  boxShadow: '0 0 3px',
                }}
              >
                <IconButton
                  onClick={() => closeNotification(item.id)}
                  sx={{
                    position: 'absolute',
                    right: -6,
                    top: -6,
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
                <div style={{ fontSize: 13, maxWidth: 180 }}>{item.text}</div>
              </Card>
            </Box>
          ))}
        </Box>
      </Menu>

      <Dialog
        maxWidth={'sm'}
        open={openSubmitNotifications}
        onClose={handleClose}
      >
        <DialogTitle>Очистить все уведомления?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Все уведомления будут так же доступны в архиве
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitClearAllNotifications}>Очистить</Button>
          <Button
            color={'error'}
            onClick={handleClose}
            autoFocus
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Notifications;
