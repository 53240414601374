import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { apiPost } from '../../../../api';
import errorHandler from '../../../layouts/errorHandler';
import toast from 'react-hot-toast';
import { Controller, useForm } from 'react-hook-form';

const AddNewInvoice = ({
  setIsDialogOpen,
  isDialogOpen,
  event,
  setIsButtonDisabled,
  isButtonDisabled = true,
}) => {
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const closeDialog = () => {
    setIsDialogOpen(false);
    reset();
    setIsButtonDisabled(false);
  };

  const submit = (formData) => {
    setIsButtonDisabled(true);
    apiPost({
      url: '/company-accounts/create',
      postData: formData,
    }).then((res) => {
      if (res.error) {
        setIsButtonDisabled(false);
        return errorHandler(res.data);
      }
      toast.success('Счет создан');
      event();
      setIsButtonDisabled(false);
      closeDialog();
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={closeDialog}
        maxWidth='md'
        scroll='body'
      >
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit(submit)}
        >
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 2, sm: 4 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Добавить новый счет
              </Typography>
            </Box>
            <div>
              <Controller
                name='name'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    error={Boolean(errors.name)}
                    value={value || ''}
                    label={'Название счета'}
                    onBlur={onBlur}
                    required={true}
                    onChange={onChange}
                    name='name'
                    fullWidth
                  />
                )}
              />

              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    sx={{ mt: 2 }}
                    value={value || ''}
                    label={'Описание'}
                    onBlur={onBlur}
                    required={true}
                    onChange={onChange}
                    name='description'
                    fullWidth
                  />
                )}
              />

              <Controller
                name='initial_balance'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    sx={{ mt: 2 }}
                    error={Boolean(errors.name)}
                    value={value || ''}
                    label={'Начальный остаток'}
                    type={'number'}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              disabled={isButtonDisabled}
              type={'submit'}
              variant='contained'
              sx={{ marginRight: 1 }}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={closeDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddNewInvoice;
