import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  ButtonGroup,
  Button,
  DialogActions,
} from '@mui/material';
import { apiGet } from '../../../../api';
import errorHandler from '../../../layouts/errorHandler';
import { toast } from 'react-hot-toast';

export const DownloadInvoiceModal = ({ children, transactionId }) => {
  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const handleDownloadPaymentInvoice = async (type) => {
    const { error, data } = await apiGet({
      url: `/legal-document/check?id=${transactionId}&type=${type}`,
    });
    if (error) {
      return errorHandler(data);
    } else {
      window.open(`${data.link}`);
      toast.success('Скачивается...');
      setOpenDialogDownload(false);
    }
  };

  return (
    <>
      <div onClick={() => setOpenDialogDownload(true)}>{children}</div>
      <Dialog
        sx={{ zIndex: 99999 }}
        fullWidth
        open={openDialogDownload}
        maxWidth='md'
        scroll='body'
        onClose={() => setOpenDialogDownload(false)}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Выберите файлы для скачивания
            </Typography>
          </Box>
          <Box>
            <ButtonGroup
              fullWidth
              size={'large'}
              orientation='vertical'
              aria-label='vertical contained button group'
              variant='contained'
            >
              <Button
                sx={{ marginBottom: 1 }}
                onClick={() => handleDownloadPaymentInvoice(1)}
              >
                Скачать счет в Word
              </Button>
              <Button onClick={() => handleDownloadPaymentInvoice(2)}>
                Скачать счет в PDF
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setOpenDialogDownload(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
