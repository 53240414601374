import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EachContractWrapper = ({ children, contract_id }) => {
  return (
    <Box>
      <Button
        component={RouterLink}
        to={'/contract/' + contract_id}
      >
        {children}
      </Button>
    </Box>
  );
};

export default EachContractWrapper;
