import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setClientList } from '../../../../store/clients';
import { setUsersList } from '../../../../store/user';
import { apiGet } from '../../../../api';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import { Input, Pagination, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import EachProductWrapper from '../../eachProductWrapper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import InputMask from 'react-input-mask';
import ConfirmDialog from '../../dialogs/confirm';
import { setShowingDialog } from '../../../../store/app';
import errorHandler from '../../../layouts/errorHandler';
import { TableSelectForPages } from '../../selectForPages';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import { theme } from '../../componentColors';
import { editBalance } from '../../../../helpers/BalanceHelper';
import { TableSort } from '../../taleSort/TableSort';
import { FormProvider, useForm } from 'react-hook-form';
import { PageEntries } from '../../pageEntries/pageEntries';
import { API_LIMIT } from '../../../../constants/apiLimit';
import { CustomCell } from '../CustomCell';
const pluralize = require('pluralize-ru');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#dedede',
    color: '#000000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const TableWrapBox = styled(Paper)(({ theme }) => ({}));

const StyledBoxWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 660)]: {
    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.between(0, 467)]: {
    alignItems: 'start',
  },
}));

const ClientTable = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const open = useSelector((state) => state.layout.open);
  const clientList = useSelector((state) => state.clients.clientList);
  const managers = useSelector((state) => state.managers.managers);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [userRole, setUserRole] = useState('');
  const [idClient, setIdClient] = useState('');
  const [count, setCount] = useState('');
  // Параметры поиска
  const [typeSearch, setTypeSearch] = useState('');
  const [managerSearch, setManagerSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [phoneSearch, setPhoneSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState(2);
  const [contractSearch, setContractSearch] = useState('');
  const [sortTableSum, setSortTableSum] = useState('');

  const [forUseEffect, setForUseEffect] = useState(false);
  const [chevronSort, setChevronSort] = useState(false);
  const [userAccess, setUserAccess] = useState({});

  const changePage = (event, value) => {
    setPage(value);
  };

  const searchPhone = (e) => {
    setPhoneSearch(e.target.value.replace(/[^0-9]/g, ''));
    setPage(1);
  };
  const searchContract = (e) => {
    setContractSearch(e.target.value);
    setPage(1);
  };
  const searchClient = React.useCallback(
    (e) => {
      setNameSearch(e.target.value);
      setPage(1);
    },
    [setNameSearch],
  );
  const searchManager = (e) => {
    if (e.target.value === 99) return setManagerSearch('');
    setManagerSearch(e.target.value);
    setPage(1);
  };

  const searchType = (e) => {
    setTypeSearch(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    const role = window.localStorage.getItem('userRole');
    setUserRole(role);

    apiGet({
      url: `/client/index?page=${page}&limit=${
        methods.watch('select') || API_LIMIT
      }&status=${statusSearch}&type=${typeSearch}&sort=${sortTableSum}&clientName=${nameSearch}&phone=${phoneSearch}&contractNumber=${contractSearch}&managerName=${managerSearch}&fromDateCreate=${
        methods.watch('date_from')?.toLocaleDateString('ru-RU') || ''
      }&toDateCreate=${
        methods.watch('date_to')?.toLocaleDateString('ru-RU') || ''
      }`,
    })
      .then((res) => {
        if (res.error) return errorHandler(res.data);
        dispatch(setClientList(res.data.models));
        setLastPage(res.data.lastPage);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    page,
    statusSearch,
    typeSearch,
    nameSearch,
    phoneSearch,
    forUseEffect,
    managerSearch,
    contractSearch,
    sortTableSum,
    methods.watch('select'),
    methods.watch('date_from'),
    methods.watch('date_to'),
  ]);

  const searchStatus = (e) => {
    setStatusSearch(e.target.value);
  };

  const deleteClient = (id) => {
    dispatch(setShowingDialog('delete-client'));
    setIdClient(id);
  };

  const confirmDeleteClient = () => {
    apiGet({
      url: `/client/delete?id=${idClient}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Клиент удален');
      setForUseEffect(!forUseEffect);
    });
  };

  const openClientPage = (id) => {
    navigate(`/client/${id}`);
  };

  const sortSum = (sort, chevron) => {
    setSortTableSum(sort);
    setChevronSort(chevron);
    setPage(1);
  };

  // Сброс всех фильтров
  const resetAllFilters = () => {
    setNameSearch('');
    setTypeSearch('');
    setManagerSearch('');
    setPhoneSearch('');
    setContractSearch('');
    setStatusSearch('');
    setSortTableSum(``);
    setChevronSort(false);
    methods.reset();
  };

  const inputs = document.querySelectorAll('.phone-mask');

  useEffect(() => {
    if (!!inputs.length) {
      inputs.forEach((el) => {
        if (el) {
          el?.childNodes?.forEach((i) => {
            const filedset = i?.childNodes[1];
            if (filedset && i) {
              i?.removeChild(filedset);
            }
          });
        }
      });
    }
  }, [inputs]);

  return (
    <StyledBoxWrap>
      <FormProvider {...methods}>
        <TableSort />
        <Box
          sx={{
            display: 'flex',
            mt: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.between(0, 470)]: {
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column-reverse',
              alignItems: 'start',
            },
          }}
        >
          <PageEntries
            data={clientList}
            lastPage={lastPage}
            currentPage={page}
            countRecord={count}
          />

          <Button
            sx={{
              maxHeight: '38px',
              [theme.breakpoints.between(0, 470)]: { my: 0.5 },
            }}
            size='small'
            variant='contained'
            color='primary'
            onClick={resetAllFilters}
          >
            Сбросить фильтры
          </Button>
        </Box>
      </FormProvider>
      <TableWrapBox
        className={!open ? 'Overflow-box-show-menu' : 'Overflow-box'}
        sx={{ overflow: 'auto', mt: 2 }}
      >
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell align='center'>
                  <TextField
                    sx={{ minWidth: 80 }}
                    label={'Клиент'}
                    value={nameSearch}
                    onChange={searchClient}
                  />
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <TextField
                    sx={{ minWidth: 75 }}
                    fullWidth
                    value={typeSearch}
                    label={'Тип'}
                    onChange={searchType}
                    select
                  >
                    <MenuItem value={1}>Физ</MenuItem>
                    <MenuItem value={2}>Юр</MenuItem>
                    <MenuItem value={''}>Все</MenuItem>
                  </TextField>
                </StyledTableCell>

                <StyledTableCell
                  sx={{ width: 150 }}
                  align='center'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      onClick={() => sortSum(``, false)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 1,
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      Баланс
                    </Box>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    >
                      {chevronSort ? (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => sortSum(`balance`, false)}
                        >
                          <ExpandMoreRoundedIcon
                            sx={{ width: 18, height: 18 }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => sortSum(`-balance`, true)}
                        >
                          <ExpandLessRoundedIcon
                            sx={{ width: 18, height: 18 }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </StyledTableCell>
                {userRole !== 'manager' && (
                  <StyledTableCell align='center'>
                    <TextField
                      sx={{ minWidth: 130 }}
                      fullWidth
                      label={'Менеджер'}
                      value={managerSearch}
                      onChange={searchManager}
                      select
                    >
                      <MenuItem value={99}>Все</MenuItem>
                      {managers.map((user) => (
                        <MenuItem
                          key={user.id}
                          value={`${user?.profile?.surname} ${user?.profile?.name}`}
                        >
                          {`${user?.profile?.surname} ${user?.profile?.name}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </StyledTableCell>
                )}

                <StyledTableCell sx={{ width: 200 }}>
                  <TextField
                    type={'number'}
                    label={'Телефон'}
                    value={phoneSearch}
                    sx={{ minWidth: 160 }}
                    onChange={searchPhone}
                  />
                </StyledTableCell>

                <StyledTableCell align='center'>
                  <TextField
                    sx={{ width: 100 }}
                    fullWidth
                    value={statusSearch}
                    label={'Клиент'}
                    onChange={searchStatus}
                    select
                  >
                    <MenuItem value={1}>Звонок</MenuItem>
                    <MenuItem value={2}>Клиенты</MenuItem>
                  </TextField>
                </StyledTableCell>

                <StyledTableCell align='center'>Объекты аренды</StyledTableCell>
                <StyledTableCell align='center'>Дата</StyledTableCell>

                <StyledTableCell
                  align='center'
                  sx={{ width: 130 }}
                >
                  <TextField
                    sx={{ minWidth: 110 }}
                    label={'Договор'}
                    value={contractSearch}
                    onChange={searchContract}
                  />
                </StyledTableCell>

                <StyledTableCell align='center'>Действия</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientList.map((client) => (
                <StyledTableRow key={client.id}>
                  <CustomCell
                    cursor='pointer'
                    onClick={() => openClientPage(client.id)}
                    align='center'
                  >
                    {client.fullName || '-'}
                  </CustomCell>

                  <CustomCell align='center'>
                    {client?.typeText || 'Физическое лицо'}
                  </CustomCell>

                  <CustomCell sx={{ padding: 0, textAlign: 'center' }}>
                    {editBalance(client?.balance)}
                  </CustomCell>

                  {userRole !== 'manager' && (
                    <CustomCell align='center'>
                      <Button
                        sx={{ fontSize: 13 }}
                        component={RouterLink}
                        to={'/user/' + client?.manager?.id}
                      >
                        {client?.manager?.fullName || client?.manager?.email}
                      </Button>
                    </CustomCell>
                  )}

                  <CustomCell
                    component='th'
                    scope='row'
                  >
                    <InputMask
                      mask='+7 (999) 999 99 99'
                      disabled={false}
                      value={
                        client?.phone?.length === 0
                          ? 'Не указан'
                          : client?.phone[0]?.phone
                      }
                    >
                      {() => (
                        <>
                          <TextField
                            className='phone-mask'
                            hiddenLabel
                            disabled
                            inputProps={{
                              style: {
                                fontSize: 13.5,
                                padding: 10,
                              },
                            }}
                            sx={{
                              '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'black',
                              },
                              my: 0,
                              '& .MuiTextField-root': { my: 1 },
                            }}
                          />
                        </>
                      )}
                    </InputMask>
                  </CustomCell>

                  <CustomCell align='center'>
                    {client?.status === 2 ? 'Клиент' : 'Звонок'}
                  </CustomCell>

                  <CustomCell align='center'>
                    {client.productsCount ? `${client.productsCount} шт.` : '-'}
                  </CustomCell>

                  <CustomCell align='center'>{client?.createdAt}</CustomCell>

                  <CustomCell align='center'>
                    {client?.contract.length === 0
                      ? '-'
                      : client?.contract.slice(0, 3).map((item) => (
                          <Button
                            key={item.id}
                            component={RouterLink}
                            to={'/contract/' + item?.id}
                          >
                            {item?.number}
                          </Button>
                        ))}
                  </CustomCell>

                  <CustomCell
                    align='center'
                    size={'small'}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <IconButton
                        sx={{
                          backgroundColor: '#fff',
                          mr: 1,
                          '&:hover': { backgroundColor: '#c2c2c2' },
                        }}
                        color='primary'
                        component={RouterLink}
                        to={'/client/' + client.id}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>

                      {userAccess?.client?.delete && (
                        <IconButton
                          sx={{
                            backgroundColor: '#fff',
                            mr: 1,
                            '&:hover': { backgroundColor: '#c2c2c2' },
                          }}
                          color={'abortButton'}
                          component='span'
                          onClick={() => deleteClient(client.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </CustomCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapBox>

      {!!clientList?.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination
            page={page}
            siblingCount={!matches ? 0 : 1}
            size={!matches ? 'small' : 'medium'}
            onChange={changePage}
            count={lastPage}
            variant='outlined'
            shape='rounded'
          />
        </Box>
      )}

      <ConfirmDialog
        name='delete-client'
        title={'Удалить клиента/звонок?'}
        buttonTitle={'Удалить'}
        event={confirmDeleteClient}
      />
    </StyledBoxWrap>
  );
};

export default ClientTable;
