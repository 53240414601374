import React, { useState, useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';

export const ClientPaymentTransactionsModal = ({
  buttonText = 'Сохранить',
  setTransactionId,
  title = 'Изменить платеж',
  children,
  name = 'comment',
  label = 'Комментарий',
  placeholder = 'Комментарий',
  onSubmit,
  dataInvoice,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const { handleSubmit, control, setValue } = useFormContext();

  const hideModal = () => {
    setIsVisible(false);
    setTransactionId(null);
  };

  const submit = (data) => {
    onSubmit(data);
    hideModal();
  };

  useEffect(() => {
    setValue(name, dataInvoice.comment);
    setValue('sum', dataInvoice.sum);
  }, [isVisible]);

  return (
    <>
      <div onClick={() => setIsVisible(true)}>{children}</div>
      <Dialog
        fullWidth
        open={isVisible}
        onClose={hideModal}
        maxWidth='sm'
        scroll='body'
      >
        <form onSubmit={handleSubmit(submit)}>
          <DialogContent
            sx={{
              pb: 2,
              px: { xs: 2, sm: 4 },
              pt: { xs: 2, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 5, textAlign: 'center' }}>
              <Typography variant='h5'>{title}</Typography>
            </Box>

            <FormControl fullWidth>
              <Controller
                name='sum'
                control={control}
                render={({ field }) => (
                  <TextField
                    label='Сумма'
                    placeholder='Введите сумму'
                    {...field}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ my: 2 }}
                    label={label}
                    placeholder={placeholder}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </DialogContent>

          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              type='submit'
              variant='contained'
              sx={{ marginRight: 1 }}
            >
              {buttonText}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={hideModal}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
