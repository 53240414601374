import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams } from 'react-router-dom';
import errorHandler from '../../../layouts/errorHandler';
import { apiGet, apiPost } from '../../../../api';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../common/componentColors';
import { useDispatch, useSelector } from 'react-redux';
import { editBalance } from '../../../../helpers/BalanceHelper';
import InvoiceForPaymentDialog from '../../../common/dialogs/CreateAnInvoiceForPayment';
import { setShowingDialog } from '../../../../store/app';
import ConfirmDialog from '../../../common/dialogs/confirm';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import { PermissionWrapper } from '../../../common/permissionWrapper/PermissionWrapper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#dedede',
    color: '#000000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const TableWrapBox = styled(Box)(({ theme }) => ({}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.between(0, 1140)]: {
    flexDirection: 'column',
  },
}));
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 427)]: {
    flexDirection: 'column',
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.between(0, 427)]: {
    marginLeft: 0,
  },
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.between(0, 727)]: {
    marginTop: 12,
  },
}));
const BoxButtonGroup = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 400)]: {
    flexDirection: 'column',
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between(0, 400)]: {
    margin: 0,
    marginTop: 12,
  },
}));

const AddtionalAgreement = () => {
  const addArgId = useParams();
  const dispatch = useDispatch();
  const [rentalProductList, setRentalProductList] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const navigate = useNavigate();
  const open = useSelector((state) => state.layout.open);
  // Закрытие доп соглашения
  const [isOpenCloseAdditionalDialog, setIsOpenCloseAdditionalDialog] =
    useState(false);

  // Создать новую оплату
  const [invoiceList, setInvoiceList] = useState([]);
  const [showDialogCreateNewPayment, setShowDialogCreateNewPayment] =
    useState(false);
  const [sumOfCreateNewPayment, setSumOfCreateNewPayment] = useState('');
  const [startDateOfCreateNewInvoice, setStartDateOfCreateNewInvoice] =
    useState(null);
  const [stopDateOfCreateNewInvoice, setStopDateOfCreateNewInvoice] =
    useState(null);
  const [idInvoiceTo, setIdInvoiceTo] = useState('');
  const [userAccess, setUserAccess] = useState({});

  const [disableButton, setDisableButton] = useState(false);

  const getInvoiceList = () => {
    apiGet({
      url: '/company-accounts/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
    });
  };

  const getRentalProductInfo = () => {
    apiGet({
      url: `/rental-product/index?id=${addArgId.additionalId}`,
    }).then((res) => {
      console.log(res.data.models[0]);
      if (res.error) {
        return errorHandler(res.data);
      }
      if (res.data.models.length === 0) {
        navigate('/404');
      }
      setRentalProductList(res.data.models[0]);
      setIsFetch(true);
    });
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    getInvoiceList();
    getRentalProductInfo();
  }, []);

  const closeAdditional = () => {
    setIsOpenCloseAdditionalDialog(true);
  };

  const closeDialog = () => {
    setShowDialogCreateNewPayment(false);
    setIsOpenCloseAdditionalDialog(false);
    setSumOfCreateNewPayment('');
    setStartDateOfCreateNewInvoice(null);
    setStopDateOfCreateNewInvoice(null);
    setDisableButton(false);
  };

  const closeRentalContract = () => {
    apiGet({
      url: `/rental-product/close?id=${addArgId.additionalId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Доп соглашение закрыто');
      getRentalProductInfo();
    });
    closeDialog();
  };

  const openDialogCreateNewPayment = () => {
    setShowDialogCreateNewPayment(true);
  };

  const onChangeStartDate = (e) => {
    let nowDate = e.target.value;
    setStartDateOfCreateNewInvoice(nowDate);
  };

  const onChangeStopDate = (e) => {
    let nowDate = e.target.value;
    setStopDateOfCreateNewInvoice(nowDate);
  };

  const createNewInvoice = () => {
    const data = {};
    data.sum = sumOfCreateNewPayment;
    data.payment_from = startDateOfCreateNewInvoice;
    data.payment_by = stopDateOfCreateNewInvoice;
    setDisableButton(true);
    apiPost({
      url: `/payment-transaction/create?id=${addArgId.additionalId}&company_accounts_id=${idInvoiceTo}`,
      postData: data,
    }).then((res) => {
      if (res.error) {
        setDisableButton(false);
        return errorHandler(res.data);
      }
      toast.success('Оплата успешно создана');
      closeDialog();
    });
  };

  if (!isFetch) {
    return <></>;
  } else {
    return (
      <div>
        <Typography
          sx={{ mb: 2 }}
          variant={'h5'}
          align={'right'}
        >
          Дополнительное соглашение №{addArgId.additionalId}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <StyledGrid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={5}
          >
            <Box
              sx={{
                mt: 5,
                '& .MuiTextField-root': { mt: 2 },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
              }}
            >
              <StyledDivider
                sx={{ mt: 4 }}
                textAlign={'center'}
              >
                Личная информация
              </StyledDivider>
              {/*<TextField*/}
              {/*    fullWidth*/}
              {/*    type="text"*/}
              {/*    label={'Имя клиента'}*/}
              {/*    defaultValue={rentalProductList?.contract?.client?.fullName || ''}*/}
              {/*    disabled={true}*/}
              {/*/>*/}
              <OutlinedInput
                fullWidth
                type='text'
                label={'Имя клиента'}
                defaultValue={
                  rentalProductList?.contract?.client?.fullName || ''
                }
                disabled={true}
                endAdornment={
                  <InputAdornment position='end'>
                    <PermissionWrapper permits={['client', 'view']}>
                      <Link
                        to={`/client/${rentalProductList?.contract?.client?.id}`}
                      >
                        <IconButton
                          edge='end'
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <EyeOutline />
                        </IconButton>
                      </Link>
                    </PermissionWrapper>
                  </InputAdornment>
                }
              />

              <TextField
                fullWidth
                type='text'
                label={'Баланс клиента'}
                defaultValue={
                  editBalance(rentalProductList?.contract?.client?.balance) ||
                  ''
                }
                disabled={true}
              />

              <StyledDivider
                sx={{ mt: 4 }}
                textAlign={'center'}
              >
                Общая информация
              </StyledDivider>

              {rentalProductList?.closed_at === null ? (
                <></>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    my: 1,
                    borderRadius: 1,
                    justifyContent: 'space-between',
                    p: 2,
                    color: '#eeeeee',
                    backgroundColor: '#ff7777',
                  }}
                >
                  <Typography variant={'subtitle2'}>
                    Доп соглашение закрыто:{' '}
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    {rentalProductList?.closed_at}
                  </Typography>
                </Box>
              )}

              <StyledBox sx={{ display: 'flex' }}>
                <TextField
                  sx={{ mr: 1 }}
                  fullWidth
                  type='text'
                  label={'Начинается'}
                  defaultValue={rentalProductList?.start || ''}
                  disabled={true}
                />

                <StyledTextField
                  sx={{ ml: 1 }}
                  fullWidth
                  type='text'
                  label={'Заканчивается'}
                  defaultValue={rentalProductList?.duration || ''}
                  disabled={true}
                />
              </StyledBox>

              <TextField
                fullWidth
                type='text'
                label={'Номер договора'}
                // defaultValue={rentalProductList?.contract?.client?.contract[0]?.number || ''}
                defaultValue={rentalProductList?.contract?.number || ''}
                disabled={true}
              />

              <TextField
                fullWidth
                type='text'
                label={'Статус договора'}
                defaultValue={rentalProductList?.contract?.statusText || ''}
                disabled={true}
              />

              <TextField
                fullWidth
                type='text'
                label={'Менеджер'}
                defaultValue={
                  rentalProductList?.contract?.client?.manager?.fullName || ''
                }
                disabled={true}
              />

              <TextField
                fullWidth
                type='text'
                label={'Адрес доставки'}
                defaultValue={
                  rentalProductList?.description === null
                    ? ''
                    : rentalProductList?.description
                }
                disabled={true}
              />

              {/*<button onClick={() => console.log(rentalProductList)}>show</button>*/}

              {/*<Divider sx={{mt: 4, my: 2}} textAlign={'center'}>Действия</Divider>*/}
              <BoxButtonGroup sx={{ mt: 4, display: 'flex' }}>
                {rentalProductList?.closed_at === null && (
                  <StyledButton
                    onClick={() =>
                      dispatch(setShowingDialog('closeAdditional'))
                    }
                    variant={'contained'}
                    sx={{ mr: 2 }}
                  >
                    Закрыть доп соглашение
                  </StyledButton>
                )}
                {userAccess['payment-transaction'].create && (
                  <InvoiceForPaymentDialog
                    invoiceList={invoiceList}
                    addArgId={addArgId.additionalId}
                  />
                )}
                {/* // <StyledButton
                                //     onClick={openDialogCreateNewPayment}
                                //     variant={'contained'}>Создать новую оплату</StyledButton>} */}
              </BoxButtonGroup>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={7}
          >
            <StyledDivider
              sx={{ mt: 5, mb: 2 }}
              textAlign={'center'}
            >
              Список товаров
            </StyledDivider>
            <TableWrapBox
              className={
                !open ? 'Overflow-box-each-show-menu' : 'Overflow-box-each'
              }
              sx={{ overflow: 'auto' }}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label='customized table'
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center'>Название</StyledTableCell>

                      <StyledTableCell align='center'>
                        Стоимость
                      </StyledTableCell>

                      <StyledTableCell align='center'>Статус</StyledTableCell>

                      <StyledTableCell align='center'>
                        Категория
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rentalProductList.products.map((item) => (
                      <StyledTableRow key={item?.product?.id}>
                        <StyledTableCell align='center'>
                          {item?.product?.name}
                        </StyledTableCell>

                        <StyledTableCell align='center'>
                          {editBalance(item?.product?.price)}
                        </StyledTableCell>

                        <StyledTableCell align='center'>
                          {item?.product?.statusText}
                        </StyledTableCell>

                        <StyledTableCell align='center'>
                          {item?.product?.category.name}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableWrapBox>
          </Grid>
        </StyledGrid>

        {/*Start ----------Закрытие доп соглашения*/}
        <ConfirmDialog
          title={'Закрыть доп соглашение?'}
          buttonTitle={'Закрыть доп соглашение'}
          event={closeRentalContract}
          name={'closeAdditional'}
        />
      </div>
    );
  }
};

export default AddtionalAgreement;
