import { useDispatch, useSelector } from 'react-redux';
import { apiGet } from '../../../../api';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { setActiveContractsList } from '../../../../store/contracts';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import { Pagination, Select, Stack, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EachProductWrapper from '../../eachProductWrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../dialogs/confirm';
import errorHandler from '../../../layouts/errorHandler';
import { setShowingDialog } from '../../../../store/app';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { TableSelectForPages } from '../../selectForPages';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ruLocale from 'date-fns/locale/ru';
import { theme } from '../../componentColors';
import { editBalance } from '../../../../helpers/BalanceHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { TableSort } from '../../taleSort/TableSort';
import { CustomCell } from '../CustomCell';
import { API_LIMIT } from '../../../../constants/apiLimit';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#dedede',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const TableWrapBox = styled(Paper)(({ theme }) => ({}));
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 660)]: {
    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.between(0, 467)]: {
    alignItems: 'start',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between(0, 467)]: {
    width: '100%',
    textAlign: 'center',
  },
}));
const StyledTypographyWrap = styled('div')(({ theme }) => ({
  [theme.breakpoints.between(0, 467)]: {
    width: '100%',
    textAlign: 'center',
  },
}));

const TableContracts = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const activeContractsList = useSelector(
    (state) => state.contracts.activeContractsList,
  );
  const open = useSelector((state) => state.layout.open);
  const managers = useSelector((state) => state.managers.managers);
  const [isFetch, setIsFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [balanceCount, setBalanceCount] = useState(null);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  // Поиск по таблице
  const [managerSearch, setManagerSearch] = useState('');
  const [typeSearch, setTypeSearch] = useState('');
  const [idContract, setIdContract] = useState('');
  const [contractNumber, setContractNumber] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [balanceSort, setBalanceSort] = useState('');

  const [forUseEffect, setForUseEffect] = useState(false);
  const [userAccess, setUserAccess] = useState({});
  const [userRole, setUserRole] = useState('');

  const changePage = (event, value) => {
    setPage(value);
  };

  const managerOnChangeSearch = (e) => {
    if (e.target.value === 99) return setManagerSearch('');
    setManagerSearch(e.target.value);
    setPage(1);
  };

  const typeOnChangeSearch = (e) => {
    setTypeSearch(e.target.value);
    setPage(1);
  };

  const ClientOnChangeSearch = (e) => {
    setClientSearch(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    apiGet({
      url: `/client-contract/index?page=${page}&limit=${
        methods.watch('select') || API_LIMIT
      }&sort=${balanceSort}&number=${contractNumber}&managerName=${managerSearch}&clientType=${typeSearch}&clientName=${clientSearch}&fromDateCreate=${
        methods.watch('date_from')?.toLocaleDateString('ru-RU') || ''
      }&toDateCreate=${
        methods.watch('date_to')?.toLocaleDateString('ru-RU') || ''
      }`,
    }).then((res) => {
      if (res.error) return toast.error(res.data);
      setBalanceCount(res.data.amount);
      dispatch(setActiveContractsList(res.data.models));
      setLastPage(res.data.lastPage);
      setIsFetch(true);
    });
  }, [
    page,
    forUseEffect,
    balanceSort,
    contractNumber,
    managerSearch,
    typeSearch,
    clientSearch,
    methods.watch('select'),
    methods.watch('date_from'),
    methods.watch('date_to'),
  ]);

  const confirmDeleteContract = () => {
    apiGet({
      url: `/client-contract/delete?id=${idContract}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Договор удален');
      setForUseEffect(!forUseEffect);
    });
  };

  const deleteContract = (id) => {
    dispatch(setShowingDialog(true));
    setIdContract(id);
    confirmDeleteContract(id);
  };

  const contractNumberSearch = (e) => {
    setContractNumber(e.target.value);
  };

  const openContractPage = (id) => {
    if (userAccess['client-contract']['contract-view'])
      navigate(`/contract/${id}`);
  };

  const updateBalanceSort = (value) => {
    setBalanceSort(value);
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    const userRoleLocal = window.localStorage.getItem('userRole');
    setUserRole(userRoleLocal);
  }, []);

  if (!isFetch) {
    return <></>;
  } else {
    return (
      <div>
        <StyledBox
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormProvider {...methods}>
            <TableSort />
          </FormProvider>
          <StyledTypographyWrap>
            <StyledTypography
              sx={{ mb: 2 }}
              align={'right'}
              variant={'h4'}
            >
              Договоры
            </StyledTypography>
          </StyledTypographyWrap>
        </StyledBox>
        <Divider sx={{ mb: 4, mt: 2 }} />
        <TableWrapBox
          className={!open ? 'Overflow-box-show-menu' : 'Overflow-box'}
          sx={{ overflow: 'auto' }}
        >
          <TableContainer component={Paper}>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TextField
                      sx={{ width: 90 }}
                      fullWidth
                      onChange={contractNumberSearch}
                      defaultValue={''}
                      label={'Договор'}
                    />
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: 250 }}>
                    Объекты аренды
                  </StyledTableCell>

                  <StyledTableCell>
                    <TextField
                      fullWidth
                      sx={{ width: 290 }}
                      onChange={ClientOnChangeSearch}
                      defaultValue={''}
                      label={'Клиент'}
                    />
                  </StyledTableCell>
                  {userRole !== 'manager' && (
                    <StyledTableCell>
                      <TextField
                        sx={{ minWidth: 130 }}
                        fullWidth
                        label={'Менеджер'}
                        onChange={managerOnChangeSearch}
                        defaultValue=''
                        select
                      >
                        <MenuItem value={99}>Все</MenuItem>
                        {managers.map((user) => (
                          <MenuItem
                            key={user.id}
                            value={`${user?.profile?.surname} ${user?.profile?.name}`}
                          >
                            {`${user?.profile?.surname} ${user?.profile?.name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StyledTableCell>
                  )}

                  <StyledTableCell align='center'>
                    <div className='flex items-center'>
                      <div
                        onClick={() => updateBalanceSort('')}
                        className='mr-1 cursor-pointer  hover:underline'
                      >
                        Баланс
                      </div>

                      <div
                        onClick={() =>
                          updateBalanceSort(
                            balanceSort === 'balance' ? '-balance' : 'balance',
                          )
                        }
                        className={`cursor-pointer w-[23px] h-[23px] flex justify-center items-center hover:bg-white/30 rounded-full transition-all 
                          ${
                            balanceSort === 'balance'
                              ? 'rotate-180'
                              : 'rotate-0'
                          }`}
                      >
                        <ExpandMoreRoundedIcon sx={{ width: 18, height: 18 }} />
                      </div>
                    </div>
                    <Box>{balanceCount || '0'} ₽</Box>
                  </StyledTableCell>

                  <StyledTableCell>
                    <TextField
                      select
                      fullWidth
                      sx={{ width: 140 }}
                      label={'Тип'}
                      defaultValue={''}
                      onChange={typeOnChangeSearch}
                    >
                      <MenuItem value={1}>Физ</MenuItem>
                      <MenuItem value={2}>Юр</MenuItem>
                      <MenuItem value={''}>Все</MenuItem>
                    </TextField>
                  </StyledTableCell>

                  <StyledTableCell>Дата заключения</StyledTableCell>

                  <StyledTableCell align='right'>Комментарий</StyledTableCell>
                  <StyledTableCell align='right'>Действия</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeContractsList.map((contract) => (
                  <StyledTableRow key={contract?.id}>
                    <CustomCell
                      cursor='pointer'
                      onClick={() => openContractPage(contract.id)}
                    >
                      <Typography variant={'subtitle2'}>
                        {contract?.number}
                      </Typography>
                    </CustomCell>

                    <CustomCell sx={{ width: 250 }}>
                      <Box sx={{ display: 'flex' }}>
                        {contract?.item?.length === 0
                          ? 'Нет товаров'
                          : contract?.item?.map((item) => (
                              <div key={item?.product?.id}>
                                <EachProductWrapper
                                  product_id={item?.product?.id}
                                  status={item?.product?.status}
                                >
                                  {item?.product?.name}
                                </EachProductWrapper>
                              </div>
                            ))}
                      </Box>
                    </CustomCell>

                    <CustomCell sx={{ width: 320, py: 0.5 }}>
                      <Button
                        component={RouterLink}
                        to={'/client/' + contract.client.id}
                      >
                        {contract?.client?.fullName}
                      </Button>
                    </CustomCell>

                    {userRole !== 'manager' && (
                      <CustomCell>
                        <Button
                          sx={{ fontSize: 13, whiteSpace: 'nowrap' }}
                          component={RouterLink}
                          to={'/user/' + contract?.client?.manager?.id}
                        >
                          {contract?.client?.manager?.fullName}
                        </Button>
                      </CustomCell>
                    )}

                    <CustomCell
                      align='center'
                      sx={{ width: 110, p: 0, whiteSpace: 'nowrap' }}
                    >
                      {editBalance(contract?.client?.balance)}
                    </CustomCell>

                    <CustomCell sx={{ width: 140 }}>
                      {contract?.client?.typeText || 'Физическое лицо'}
                    </CustomCell>

                    <CustomCell>{contract?.createdAt}</CustomCell>

                    <CustomCell
                      align='center'
                      sx={{ p: 0 }}
                    >
                      <span className='line-clamp-2 break-all'>
                        {contract?.description}
                      </span>
                    </CustomCell>

                    <CustomCell
                      align='right'
                      component='th'
                      scope='row'
                    >
                      <Box sx={{ display: 'flex' }}>
                        <IconButton
                          sx={{
                            backgroundColor: '#fff',
                            mr: 1,
                            '&:hover': { backgroundColor: '#c2c2c2' },
                          }}
                          color='primary'
                          component={RouterLink}
                          to={'/contract/' + contract.id}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>

                        {userAccess['client-contract'].delete && (
                          <IconButton
                            sx={{
                              backgroundColor: '#fff',
                              mr: 1,
                              '&:hover': { backgroundColor: '#c2c2c2' },
                            }}
                            color={'abortButton'}
                            component='span'
                            onClick={() => deleteContract(contract.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </CustomCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapBox>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination
            siblingCount={!matches ? 0 : 1}
            size={!matches ? 'small' : 'medium'}
            page={page}
            onChange={changePage}
            count={lastPage}
            variant='outlined'
            shape='rounded'
          />
        </Box>
        <ConfirmDialog
          buttonTitle={'Удалить'}
          title={'Удалить договор'}
          event={confirmDeleteContract}
        />
      </div>
    );
  }
};

export default TableContracts;
