import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../../../api';
import errorHandler from '../../../errorHandler';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from '@mui/material';
import { API_LIMIT } from '../../../../../constants/apiLimit';

export const useClientPaymentTransactionsTable = () => {
  const { itemId } = useParams();
  const location = useLocation();

  const { refetch } = useSelector((state) => state.refetch);
  const dispatch = useDispatch();

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const methods = useForm();

  const [transactionList, setTransactionList] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const fetchPaymentTransactionList = () => {
    apiGet({
      url: `/payment-list/index?id=${itemId}&limit=${API_LIMIT}&page=${page}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTransactionList(res.data.models);
      setLastPage(res.data.lastPage);
    });
  };

  const handleAcceptPaymentInvoice = () => {
    apiGet({
      url: `/payment-transaction/manager-confirmation?id=${transactionId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success(res.data);
      setTransactionId(null);
      fetchPaymentTransactionList();
    });
  };

  const handleEditCommentPaymentInvoice = (data) => {
    apiPost({
      url: `/payment-transaction/update-comment-manager?id=${transactionId}`,
      postData: data,
    }).then((res) => {
      if (res.error) {
        return errorHandler(res.data);
      }
      toast.success('Платеж успешно изменен');
      methods.reset();
      fetchPaymentTransactionList();
    });
  };

  const handleDeletePaymentInvoice = () => {
    apiGet({ url: `/payment-transaction/delete?id=${transactionId}` }).then(
      (res) => {
        if (res.error) return errorHandler(res.data);
        toast.success(res.data);
        setTransactionId(null);
        fetchPaymentTransactionList();
      },
    );
  };

  const handleSendMailPaymentInvoice = () => {
    apiGet({
      url: `/payment-transaction/send-payment-to-email?id=${transactionId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success(res.data);
    });
  };

  const changePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (location.pathname === `/client/${itemId}`) {
      fetchPaymentTransactionList();
    }
  }, [refetch, page]);
  return {
    transactionList,
    setTransactionId,
    dispatch,
    methods,
    lastPage,
    page,
    matches,
    changePage,
    handleEditCommentPaymentInvoice,
    handleSendMailPaymentInvoice,
    handleDeletePaymentInvoice,
    handleAcceptPaymentInvoice,
  };
};
