import {
  AdminPanelSettings,
  Computer,
  HandymanTwoTone,
  LocalShippingTwoTone,
  PointOfSale,
} from '@mui/icons-material';

export const ROLES_ICON = {
  driver: {
    icon: <LocalShippingTwoTone />,
  },
  admin: {
    icon: <AdminPanelSettings />,
  },
  warehouse_worker: {
    icon: <HandymanTwoTone />,
  },
  accountant: {
    icon: <PointOfSale />,
  },
  manager: {
    icon: <Computer />,
  },
};
