import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGet } from '../../api';

const initialState = {
  productInfo: [],
  productStatusList: [],
};

// export const getProductStatusList = createAsyncThunk(
//     '/product/status-list',
//     async (_, {rejectedWithValue, dispatch}) => {
//         apiGet({
//             url: '/product/status-list'
//         }).then(res => {
//             console.log('sdf')
//             if (res.error)
//                 return console.log('нужно обработать ошибку из products redux async thunk')
//             dispatch(setProductStatusList(res.data))
//         })
//     }
// )

const productsInfoSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductInfo: (state, action) => {
      state.productInfo = action.payload;
    },

    setProductStatusList: (state, action) => {
      state.productStatusList = action.payload;
    },
  },

  // extraReducers: {
  //     [getProductStatusList.pending]: () => console.log('pending Status list'),
  //     [getProductStatusList.fulfilled]: () => console.log('fulfilled pending Status list'),
  //     [getProductStatusList.rejected]: () => console.log('rejected pending Status list'),
  // }
});

export const { setProductInfo, setProductStatusList } =
  productsInfoSlice.actions;

export default productsInfoSlice.reducer;
