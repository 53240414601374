import React from 'react';
import EachClientInfo from '../../../layouts/eachClients';
import Box from '@mui/material/Box';

const EachClient = () => {
  return (
    <Box>
      <EachClientInfo />
    </Box>
  );
};

export default EachClient;
