import React, { useState, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DialogAppSetting from '../../common/dialogs/appSettings';
import { apiGet } from '../../../api';
import { setUserFullName, setUserProfile } from '../../../store/user';
import toast from 'react-hot-toast';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = () => {
  const { userProfile, userFullName } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [userAccess, setUserAccess] = useState('');
  const dispatch = useDispatch();

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleDropdownClose();
    window.localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    const userRoleLocal = window.localStorage.getItem('userRoleRu');
    setUserRole(userRoleLocal);

    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    apiGet({ url: '/user/auth' })
      .then((res) => {
        if (res.error) {
          return toast.error('Ошибка сервера');
        }
        dispatch(setUserProfile(res.data));
        dispatch(setUserFullName(res.data.profile));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar
          onClick={handleDropdownOpen}
          sx={{
            backgroundColor: '#b2dfdb',
            textTransform: 'uppercase ',
            width: 40,
            fontWeight: '600',
            height: 40,
            color: '#000',
          }}
        >
          {userFullName?.surname?.slice(0, 1)}
          {userFullName?.name?.slice(0, 1)}
        </Avatar>
      </Badge>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 1.6 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ p: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar
                alt='John Doe'
                src='/images/avatars/1.png'
                sx={{
                  width: '2.5rem',
                  height: '2.5rem',
                  textTransform: 'uppercase ',
                }}
              >
                {userProfile?.profile?.surname?.slice(0, 1)}
                {userProfile?.profile?.name?.slice(0, 1)}
              </Avatar>
            </Badge>

            <Box
              sx={{ display: 'flex', marginLeft: 1, flexDirection: 'column' }}
            >
              <div
                style={{
                  fontWeight: 600,
                  maxWidth: 140,
                  fontSize: '14px',
                  wordWrap: 'break-word',
                }}
              >
                {userProfile?.profile?.surname} {userProfile?.profile?.name}
              </div>

              <Typography
                variant='body2'
                sx={{ fontSize: '0.7rem', color: 'text.disabled' }}
              >
                {userRole}
              </Typography>
            </Box>
          </Box>
        </Box>

        {userRole !== 'driver' && (
          <MenuItem
            sx={{ borderRadius: '8px' }}
            to={'/profile'}
            onClick={() => handleDropdownClose()}
            component={RouterLink}
          >
            <AccountCircleIcon
              color='primary'
              sx={{ mr: 1 }}
            />
            Профиль
          </MenuItem>
        )}

        {userAccess['additional-properties']?.create && <DialogAppSetting />}

        <Divider sx={{ my: '4px' }} />

        <MenuItem
          sx={{ borderRadius: '8px' }}
          onClick={handleLogout}
        >
          <LogoutIcon
            color='primary'
            sx={{ mr: 1 }}
          />
          Выйти
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
