import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { styles } from './styles';
import { FormError } from './FormError';

// const CustomSelectComponent = styled(TextField)`
//   & label.Mui-focused {
//     color: #b2dfdb;  }
//   & .MuiOutlinedInput-root {
//
//     &.Mui-focused fieldset {
//       border-color: #b2dfdb;
//     }
//     &:hover fieldset {
//       border-color: #819ca9;
//     }
//   }
// `;

export const CustomInput = ({
  name,
  rules,
  options = [],
  label,
  icon,
  allowClear = true,
  handleChange,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isError = !!errors[name]?.message;
  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => {
          const isClearIconVisible = allowClear && !!value;
          const changeInput = (e) => {
            const { value } = e.target;
            handleChange?.(name, value);
            onChange(value);
          };

          const resetFields = () => {
            handleChange?.(name, '');
            onChange('');
          };

          return (
            <FormControl
              className='space-y-1 relative'
              fullWidth
            >
              <TextField
                className={`custom_input ${props?.className}`}
                label={label || ''}
                error={isError}
                onBlur={onBlur}
                onChange={changeInput}
                value={value || ''}
                {...props}
              />

              {isClearIconVisible && (
                <IconButton
                  size='small'
                  sx={{ ...styles.formInputClearIcon }}
                  onClick={resetFields}
                >
                  <CloseOutlined />
                </IconButton>
              )}

              <FormError name={name} />
            </FormControl>
          );
        }}
      />
    </div>
  );
};
