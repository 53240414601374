import EachProductWrapper from '../../../../../common/eachProductWrapper';
import React from 'react';
import { editBalance } from '../../../../../../helpers/BalanceHelper';

export const ClientPaymentTransactionsTableTop = ({ name, price, status }) => {
  return (
    <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
      <EachProductWrapper
        status={status[0]}
        link={false}
      >
        {name}
      </EachProductWrapper>
      <div>Цена: {editBalance(price)}</div>
    </div>
  );
};
