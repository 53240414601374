import React from 'react';
import { useFormContext } from 'react-hook-form';

export const FormError = ({ name }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name]?.message;
  if (!error) return null;

  return <div className='text-xs text-red'>{error}</div>;
};
