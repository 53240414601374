// React / Redux
import { getEventData } from '../../../../store/events';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import React, { forwardRef, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

// Components
import { TextFieldController } from '../../formControllers';
import { apiGet, apiPost } from '../../../../api';

// MUI
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import errorHandler from '../../../layouts/errorHandler';
import IconButton from '@mui/material/IconButton';
import { DeleteForever } from 'mdi-material-ui';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { theme } from '../../componentColors';
import { setNotificationsCounter } from '../../../../store/notifications';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between(370, 510)]: {
    width: 250,
    height: 40,
  },
  [theme.breakpoints.between(0, 370)]: {
    width: 190,
    height: 32,
  },
  [theme.breakpoints.between(0, 750)]: {
    margin: 0,
  },
}));

const DialogAddNewEvent = ({ product_id, getAllTasks }) => {
  const dispatch = useDispatch();
  const eventsNameList = useSelector((state) => state.events.eventsNameList);
  const notificationsCounter = useSelector(
    (state) => state.notifications.notificationsCounter,
  );
  const allRoles = useSelector((state) => state.user.allRoles);

  // Списки и данные для формы
  const [userOfRoles, setUserOfRoles] = useState([]);
  const [eventName, setEventName] = useState('');
  const [eventUser, setEventUser] = useState('');
  // Модальное окно
  const [show, setShow] = useState(false);

  // Категории товаров
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState('');

  // Типы товаров - подкатегории
  const [typeCategoryList, setTypeCategoryList] = useState([]);
  const [typeCategoryId, setTypeCategoryId] = useState('');

  // Товары определенной категории и типа
  const [chooseProductList, setChooseProductList] = useState([]);
  const [productSendList, setProductSendList] = useState([]);
  const { handleSubmit, control, setValue, watch } = useForm();
  const [todayDate, setTodayDate] = useState('');
  const [todayTime, setTodayTime] = useState('');
  const [roleInputValue, setRoleInputValue] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let arr = [];
    const newDate = new Date().toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setTodayDate(arr.join('-'));
  }, []);

  useEffect(() => {
    const newDate = new Date()
      .toLocaleTimeString('ru-RU')
      .split(':')
      .splice(0, 2)
      .join(':');
    setTodayTime(newDate);
  }, []);

  const getDate = () => {
    const newDate = new Date()
      .toLocaleDateString('ru-RU')
      .split('.')
      .reverse()
      .join('-');
    const newTime = new Date()
      .toLocaleTimeString('ru-RU')
      .split(':')
      .splice(0, 2)
      .join(':');
    setValue('date', todayDate);
    setValue('time', todayTime);
    setRoleInputValue([]);
    setShow(true);
  };

  const submit = (formData) => {
    let data = {};
    data.name = eventName;
    data.user_id = eventUser;
    data.product_id = typeCategoryId;
    if (formData.comment === undefined) data.comment = '';
    else data.comment = formData.comment;

    data.products = {};

    productSendList?.map((item) => {
      data.products[item.name] = item.id;
    });

    data.product_id = product_id;
    data.start = `${watch('date')} ${watch('time')}`;

    if (product_id === undefined) data.product_id = '';
    setDisableButton(true);
    apiPost({
      url: '/task/create',
      postData: data,
    })
      .then((res) => {
        if (res.error) {
          // setProductSendList([])
          setDisableButton(false);
          return errorHandler(res.data);
        } else {
          toast.success('Успешно');

          // Обновление всех задач
          getAllTasks();
          // dispatch(getEventData())
          // dispatch(setNotificationsCounter(notificationsCounter +1));
          handleCloseDialog();
          setDisableButton(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Получение всех пользователей при выборе роли
  const getUsers = (value) => {
    apiGet({
      url: `/user/index?role=${value.role}`,
    }).then((res) => {
      if (res.error) return toast.error('ошибка получения пользователей');
      setUserOfRoles(res.data.models);
    });
  };

  const eventNameOnChange = (value) => {
    setEventName(value.key);
  };

  const usersHandlerOnChange = (value) => {
    setEventUser(value.id);
  };

  const getTypesOfCategory = () => {
    if (productCategoryId === '') return;

    apiGet({
      url: `/product-type/index?category_type_id=${productCategoryId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTypeCategoryList(res.data.models);
    });
  };

  useEffect(() => {
    getTypesOfCategory();
  }, [productCategoryId]);

  useEffect(() => {
    if (typeCategoryId === '') return;
    else {
      apiGet({
        url: `/product/index?product_type_id=${typeCategoryId}&status=${eventName}&limit=3000`,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        setChooseProductList(res.data.models);
      });
    }
  }, [typeCategoryId]);

  const getCategoryProduct = () => {
    // Получение категорий продукта
    apiGet({
      url: '/category-type/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setProductCategoryList(res.data.models);
    });
  };

  useEffect(() => {
    getCategoryProduct();
  }, []);

  const getTypesOfCategoryOnChange = (value) => {
    setProductCategoryId(value);
  };

  const getOriginalTypesOfCategoryOnChange = (value) => {
    setTypeCategoryId(value.id);
  };

  const getProductsIdCategoryOnChange = (value) => {
    let checkKey = 0;
    productSendList?.map((item) => {
      if (item.id === value.id) checkKey = 1;
    });

    if (checkKey !== 1)
      setProductSendList((prevState) => [
        ...prevState,
        { id: value.id, name: value.name },
      ]);
  };

  const deleteProductSendList = (id) => {
    const result = productSendList?.filter((item) => item.id !== id);
    setProductSendList(result);
  };

  const handleCloseDialog = () => {
    setProductSendList([]);
    setShow(false);
    setValue('date', '');
  };

  const handlerChangeRoleInput = (event, value) => {
    setRoleInputValue(value);

    if (value !== null) {
      getUsers(value);
    } else setUserOfRoles([]);
  };

  return (
    <div>
      <StyledButton
        sx={{ width: 150, m: 2 }}
        variant='contained'
        color={'secondary'}
        onClick={getDate}
      >
        Новое событие
      </StyledButton>
      <Dialog
        fullWidth
        open={show}
        maxWidth='md'
        scroll='body'
        TransitionComponent={Transition}
      >
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit(submit)}
        >
          <DialogContent
            sx={{
              pb: 4,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Создать событие
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                [theme.breakpoints.between(0, 560)]: {
                  flexDirection: 'column',
                },
              }}
            >
              <FormControl sx={{ width: '66%' }}>
                <Controller
                  name='name'
                  control={control}
                  render={({ field: { value, onBlur } }) => (
                    <Autocomplete
                      sx={{
                        mr: 2,
                        [theme.breakpoints.between(0, 560)]: { mr: 0 },
                      }}
                      onBlur={onBlur}
                      fullWidth={false}
                      name='name'
                      defaultValue={eventsNameList.value}
                      getOptionLabel={(option) => option.value || ''}
                      onChange={(event, value) => {
                        if (value !== null) {
                          eventNameOnChange(value);
                          if (value.key === 1 || value.key === 2) {
                            setRoleInputValue({
                              name: 'Водитель',
                              role: 'driver',
                            });
                            getUsers({ name: 'Водитель', role: 'driver' });
                          } else {
                            setRoleInputValue([]);
                          }
                        } else {
                          return;
                        }
                      }}
                      disablePortal
                      options={eventsNameList}
                      renderInput={(params) => (
                        <TextField
                          required={true}
                          {...params}
                          label='Список задач'
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                sx={{
                  width: 190,
                  [theme.breakpoints.between(0, 560)]: { width: '100%' },
                }}
              >
                <Controller
                  name='date'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      name='date'
                      sx={{ [theme.breakpoints.between(0, 560)]: { mt: 1.4 } }}
                      label='Дата события'
                      type='date'
                      required={true}
                      defaultValue={todayDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                sx={{
                  marginLeft: '1%',
                  width: '18%',
                  [theme.breakpoints.between(0, 560)]: { width: '100%' },
                }}
              >
                <Controller
                  name='time'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      name='date'
                      sx={{ [theme.breakpoints.between(0, 560)]: { mt: 1.4 } }}
                      label='Время события'
                      type='time'
                      required={true}
                      defaultValue={todayTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: 'flex',
                mt: 2,
                [theme.breakpoints.between(0, 560)]: {
                  flexDirection: 'column',
                },
              }}
            >
              <FormControl fullWidth>
                <Controller
                  name='role'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Autocomplete
                      sx={{
                        mr: 1,
                        [theme.breakpoints.between(0, 560)]: { mr: 0 },
                      }}
                      onBlur={onBlur}
                      name='role'
                      inputValue={roleInputValue?.name || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, value) => {
                        handlerChangeRoleInput(event, value);
                      }}
                      disablePortal
                      options={allRoles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label='Роли'
                        />
                      )}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth>
                <Controller
                  name='user_id'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Autocomplete
                      sx={{
                        ml: 1,
                        mb: 2,
                        [theme.breakpoints.between(0, 560)]: { mt: 1.4, ml: 0 },
                      }}
                      onBlur={onBlur}
                      name='user_id'
                      noOptionsText={'Нужно выбрать роль'}
                      defaultValue={userOfRoles?.username}
                      getOptionLabel={(option) =>
                        option?.profile?.surname + ' ' + option.profile?.name ||
                        ''
                      }
                      onChange={(event, value) => {
                        if (value !== null) usersHandlerOnChange(value);
                      }}
                      disablePortal
                      options={userOfRoles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label='Пользователи'
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              {/*<button onClick={() => console.log(userOfRoles)}>show</button>*/}
            </Box>

            <TextFieldController
              name={'comment'}
              label={'Описание'}
              multiline={true}
              maxRows={4}
              control={control}
            />

            <Divider
              sx={{ mt: 3, mb: 2 }}
              textAlign='center'
            >
              Добавить товары
            </Divider>

            <div>
              <Box
                sx={{
                  display: 'flex',
                  my: 2,
                  [theme.breakpoints.between(0, 560)]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <FormControl fullWidth>
                  <Controller
                    name='productCategory'
                    control={control}
                    render={({ field: { value, onBlur } }) => (
                      <Autocomplete
                        sx={{
                          mr: 1,
                          [theme.breakpoints.between(0, 560)]: { mr: 0 },
                        }}
                        onBlur={onBlur}
                        name='productCategory'
                        defaultValue={productCategoryList?.name}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => {
                          if (value !== null) {
                            getTypesOfCategory();
                            getTypesOfCategoryOnChange(value.id);
                          } else {
                            setChooseProductList([]);
                            setTypeCategoryList([]);
                          }
                        }}
                        disablePortal
                        options={productCategoryList}
                        renderOption={(props, productCategoryList) => (
                          <Box
                            component='li'
                            {...props}
                            key={productCategoryList.id}
                          >
                            {productCategoryList.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // required={true}
                            label='Категории товаров'
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Controller
                    name='productType'
                    control={control}
                    render={({ field: { value, onBlur } }) => (
                      <Autocomplete
                        sx={{
                          ml: 1,
                          [theme.breakpoints.between(0, 560)]: {
                            mt: 1.4,
                            ml: 0,
                          },
                        }}
                        onBlur={onBlur}
                        name='productType'
                        noOptionsText={'Нужно выбрать категорию'}
                        value={typeCategoryList?.name}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => {
                          if (value !== null) {
                            getOriginalTypesOfCategoryOnChange(value);
                          } else return;
                        }}
                        disablePortal
                        options={typeCategoryList}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Типы товаров'
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Box>

              <FormControl fullWidth>
                <Controller
                  name='productList'
                  control={control}
                  render={({ field: { value, onBlur } }) => (
                    <Autocomplete
                      onBlur={onBlur}
                      name='productList'
                      noOptionsText={'Нужно выбрать тип или категорию'}
                      defaultValue={chooseProductList?.name}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={(event, value) => {
                        if (value !== null) {
                          getProductsIdCategoryOnChange(value);
                        } else return;
                      }}
                      disablePortal
                      options={chooseProductList}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, chooseProductList) => (
                        <Box
                          component='li'
                          {...props}
                          key={chooseProductList.id}
                        >
                          {chooseProductList.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Список товаров'
                        />
                      )}
                    />
                  )}
                />
              </FormControl>

              {!!productSendList.length && (
                <Typography
                  sx={{ ml: 1 }}
                  variant={'subtitle2'}
                >
                  Товары события:
                </Typography>
              )}

              <Box sx={{ display: 'flex' }}>
                {productSendList.map((item) => (
                  <Box
                    sx={{
                      border: 1,
                      borderRadius: 1,
                      background: '#ececec',
                      m: 1,
                      p: '0 5px',
                    }}
                    key={item.id}
                  >
                    {item.name}
                    <IconButton
                      sx={{ ml: '4px' }}
                      size='small'
                      onClick={() => deleteProductSendList(item.id)}
                    >
                      <DeleteForever />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              disabled={disableButton}
              variant='contained'
              sx={{ marginRight: 1 }}
              type={'submit'}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={handleCloseDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogAddNewEvent;
