import React from 'react';
import { useForm } from 'react-hook-form';
import { PermissionWrapper } from '../../../common/permissionWrapper/PermissionWrapper';
import Box from '@mui/material/Box';
import DialogAddNewUser from '../../../common/dialogs/dialogAddNewUser';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';
import { StaffsTable } from './elems/staffsTable';

export const UsersListPage = () => {
  return (
    <div>
      <HeaderPage title='Сотрудники'>
        <PermissionWrapper permits={['user', 'create']}>
          <Box>
            <DialogAddNewUser />
          </Box>
        </PermissionWrapper>
      </HeaderPage>

      <StaffsTable />
    </div>
  );
};
