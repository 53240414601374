import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import React, { useState, forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { apiGet, apiPost } from '../../../../api';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { theme } from '../../componentColors';
import errorHandler from '../../../layouts/errorHandler';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const DialogAddNewContract = ({
  userId,
  getContract,
  isIconButton = false,
}) => {
  const [show, setShow] = useState(false);
  const [contractStatus, setContractStatus] = useState([]);
  const [stopDateContract, setStopDateContract] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [todayDate, setTodayDate] = useState('');

  const { handleSubmit, control, reset, setValue } = useForm();

  const getTodayDate = () => {
    let arr = [];
    const newDate = new Date().toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setTodayDate(arr.join('-'));
  };

  useEffect(() => {
    getTodayDate();
  }, []);

  const createNewContract = (formData) => {
    formData.start = todayDate;
    formData.end = stopDateContract;

    setDisableButton(true);

    if (formData.status === undefined) formData.status = 1;

    apiPost({
      url: `/client-contract/create?id=${userId}`,
      postData: formData,
    })
      .then((res) => {
        if (res.error) {
          setDisableButton(false);
          return errorHandler(res.data);
        }
        toast.success('Договор создан');
        getContract();
        setDisableButton(false);
        setShow(false);
      })
      .catch((e) => console.log(e));
  };

  const closeDialog = () => {
    setShow(false);
    setDisableButton(false);
    setStopDateContract(null);
    reset();
    getTodayDate();
  };

  useEffect(() => {
    // Получение списка статусов для договора
    apiGet({
      url: '/client-contract/status-list',
    }).then((res) => {
      if (res.error)
        return toast.error(
          'Ошибка получения статусов - пожалуйста перезагрузите страницу',
        );
      setContractStatus(res.data);
    });
  }, []);

  //Получаем и записываем имя договора
  useEffect(() => {
    apiGet({
      url: `/client-contract/generate-contract-number?id=${userId}`,
    }).then((res) => {
      setValue('number', res.data);
    });
  }, [show]);

  const startDateOnChange = (e) => {
    setTodayDate(e.target.value);
  };

  const stopDateOnChange = (e) => {
    setStopDateContract(e.target.value);
  };

  return (
    <div>
      <Box sx={{ textAlign: 'right' }}>
        {isIconButton ? (
          <Tooltip title='Добавить новый договор'>
            <IconButton
              sx={{ color: '#fff', marginLeft: 1 }}
              onClick={() => setShow(true)}
              component='span'
            >
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            variant='contained'
            color={'secondary'}
            onClick={() => setShow(true)}
          >
            Добавить новый договор
          </Button>
        )}
      </Box>
      <Dialog
        fullWidth
        open={show}
        maxWidth='sm'
        scroll='body'
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit(createNewContract)}
        >
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Добавить новый договор{' '}
              </Typography>
            </Box>
            <FormControl
              fullWidth
              sx={{ mb: 2 }}
            >
              <Controller
                name='number'
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label='Имя договора'
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
            <Controller
              name='description'
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  fullWidth
                  value={value || ''}
                  label='Комментарий'
                  onBlur={onBlur}
                  onChange={onChange}
                  name='description'
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                my: 2,
                [theme.breakpoints.between(0, 480)]: {
                  flexDirection: 'column',
                },
              }}
            >
              <TextField
                onChange={startDateOnChange}
                fullWidth
                sx={{ mr: 2, [theme.breakpoints.between(0, 480)]: { mr: 0 } }}
                name='date'
                label='С какого'
                type='date'
                value={todayDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                onChange={stopDateOnChange}
                fullWidth
                sx={{ [theme.breakpoints.between(0, 480)]: { mt: 2 } }}
                name='date'
                label='По какое'
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <FormControl fullWidth>
              <Controller
                name='status'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    required
                    select
                    name='status'
                    onBlur={onBlur}
                    onChange={onChange}
                    label={'Статус'}
                    defaultValue={1}
                  >
                    {contractStatus.map((item) => (
                      <MenuItem
                        key={item?.key}
                        value={item?.key}
                      >
                        {item?.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              disabled={disableButton}
              variant='contained'
              sx={{ marginRight: 1 }}
              type={'submit'}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={closeDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogAddNewContract;
