import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { editBalance } from '../../../helpers/BalanceHelper';
import { dateFormat } from '../../../helpers/DateHelper';

const EachProductWrapper = ({
  children,
  statusText = null,
  price = '-',
  status,
  product_id,
  type,
  link = true,
  rentalContract,
  isEnabled = false,
}) => {
  const [bgColorStatus, setBgColorStatus] = useState('');

  const gradientStyled = `linear-gradient(to left, ${
    rentalContract?.payDifference !== null ? '#91da9b' : '#f17d7d'
  } ${
    rentalContract?.payDifference * 2 || rentalContract?.delayPeriod
  }%, transparent 10%)`;

  useEffect(() => {
    if (status === 1) setBgColorStatus('#f6b002');
    if (status === 2) setBgColorStatus('#FFDF8C');
    if (status === 3) setBgColorStatus('#C5DCB3');
    if (status === 4) setBgColorStatus('#5094BA');
    if (status === 5) setBgColorStatus('#FF7A00');
    if (status === 6) setBgColorStatus('#F6F6F6');
    if (status === 7) setBgColorStatus('#BD0303');
    if (status === 8) setBgColorStatus('#A0A0A0');
    if (status === 9) setBgColorStatus('#517ae3');
  }, []);

  const style9 = {
    background: gradientStyled,
    backgroundColor: '#f6b002',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style2 = {
    background: gradientStyled,
    backgroundColor: '#FFDF8C',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style7 = {
    background: gradientStyled,
    backgroundColor: '#C5DCB3',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style8 = {
    background: gradientStyled,
    backgroundColor: '#5094BA',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style3 = {
    background: gradientStyled,
    backgroundColor: '#FF7A00',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style1 = {
    background: gradientStyled,
    backgroundColor: '#F6F6F6',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style5 = {
    background: gradientStyled,
    backgroundColor: '#BD0303',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style4 = {
    background: gradientStyled,
    backgroundColor: '#A0A0A0',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const style6 = {
    background: gradientStyled,
    backgroundColor: '#517ae3',
    border: 1,
    borderRadius: 1,
    display: 'flex',
    m: 0.2,
  };

  const tooltipWrapper = {
    color: 'black',
    textAlign: 'center',
    p: 1,
    opacity: 1,
    '& .MuiTypography-root': { color: '#000000' },
  };

  const tooltipInRentProduct = (
    <>
      <Box sx={tooltipWrapper}>
        <Typography
          sx={{ color: '#000000' }}
          variant={'subtitle2'}
        >
          {rentalContract?.duration === null ||
          rentalContract?.duration === undefined
            ? ' '
            : 'Освободится: ' + rentalContract?.duration}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: 1,
            my: 1,
            background: bgColorStatus,
            p: 1,
          }}
        >
          <Typography>{type?.name}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: 1,
            my: 1,
            background: '#e3e3e3',
            p: 1,
          }}
        >
          <Typography
            variant={'subtitle2'}
            sx={{ mr: 2 }}
          >
            Тариф объекта
          </Typography>

          {/*<Typography>{rentalContract?.price === null ? '' : rentalContract?.price} Р в месяц</Typography>*/}
          <Typography>{editBalance(price)}/месяц</Typography>
        </Box>

        {statusText === null ? (
          <></>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 1,
              my: 1,
              background: bgColorStatus,
              p: 1,
            }}
          >
            <Typography variant={'subtitle2'}>Статус объекта</Typography>
            <Typography>{statusText}</Typography>
          </Box>
        )}

        {rentalContract?.payDifference !== null && rentalContract !== null ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              my: 1,
              p: 1,
              borderRadius: 1,
              backgroundColor: '#acf3af',
            }}
          >
            <Typography variant={'subtitle2'}>Конец оплаты через:</Typography>
            <Typography>{rentalContract?.payDifference} дней</Typography>
          </Box>
        ) : rentalContract?.delayPeriod !== null && rentalContract !== null ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              my: 1,
              p: 1,
              borderRadius: 1,
              backgroundColor: '#ff8b8b',
            }}
          >
            <Typography variant={'subtitle2'}>Дней просрочки:</Typography>
            <Typography>{rentalContract?.delayPeriod} дней</Typography>
          </Box>
        ) : (
          <></>
        )}
        {rentalContract?.id === null || rentalContract === null ? (
          <></>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
                alignItems: 'center',
                my: 1,
                background: '#e3e3e3',
                p: 1,
              }}
            >
              {/*<Typography variant={'subtitle2'}>Текущий клиент</Typography>*/}
              <Button
                component={RouterLink}
                to={'/client/' + rentalContract?.client?.id}
              >
                <Typography>
                  {rentalContract?.client?.fullName === null
                    ? '-'
                    : rentalContract?.client?.fullName}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                borderRadius: 1,
                alignItems: 'center',
                my: 1,
                background: '#e3e3e3',
                p: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography variant={'subtitle2'}>Доп соглашение:</Typography>
              <Button
                component={RouterLink}
                to={'/additional-agreement/' + rentalContract?.id}
              >
                <Typography>{rentalContract?.id}</Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
                my: 1,
                background: '#e3e3e3',
                p: 1,
              }}
            >
              <Typography variant={'subtitle2'}>Тариф соглашения</Typography>
              <Typography>
                {rentalContract?.sum === null ? '' : rentalContract?.sum} Р в
                месяц
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                borderRadius: 1,
                alignItems: 'center',
                my: 1,
                background: '#e3e3e3',
                p: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography variant={'subtitle2'}>Оплачено до </Typography>
              <Typography>
                {dateFormat(rentalContract?.paidUpTo) || ' - '}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );

  return (
    <Box
      sx={
        status === 1
          ? style1
          : status === 2
          ? style2
          : status === 3
          ? style3
          : status === 4
          ? style4
          : status === 5
          ? style5
          : status === 6
          ? style6
          : status === 7
          ? style7
          : status === 8
          ? style8
          : style9
      }
    >
      {isEnabled ? (
        <Tooltip
          disableFocusListener={true}
          componentsProps={{
            tooltip: {
              sx: {
                border: '1px black solid',
                bgcolor: 'common.white',
                '& .MuiTooltip-arrow': {
                  color: 'common.black',
                },
              },
            },
          }}
          arrow
          color={'black'}
          // inside tooltip
          // title={status === 6 ? tooltip6 : isEnabled ? tooltipInRentProduct : <></>}
          title={isEnabled ? tooltipInRentProduct : <></>}
        >
          <Button
            sx={{ p: 0 }}
            component={RouterLink}
            to={'/product/' + product_id}
          >
            {children}
          </Button>
        </Tooltip>
      ) : (
        <Button
          sx={{ p: 0, cursor: !link && 'default' }}
          color={'black'}
          component={link ? RouterLink : Box}
          to={'/product/' + product_id}
        >
          {children}
        </Button>
      )}
    </Box>
  );
};

export default EachProductWrapper;
