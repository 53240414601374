import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { theme } from '../../../common/componentColors';
import { Tooltip, IconButton, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import React, { useState, useEffect, useMemo } from 'react';
import { apiGet, apiPost } from '../../../../api';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import toast from 'react-hot-toast';
import errorHandler from '../../../layouts/errorHandler';
import { dateReverseFormat } from '../../../../helpers/DateHelper';
import { editBalance } from '../../../../helpers/BalanceHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setRefetch } from '../../../../store/refetch/refetch';

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between(0, 400)]: {
    margin: 0,
    marginTop: 12,
  },
}));

const InvoiceForPaymentDialog = ({
  rentalProductId,
  invoiceList,
  addArgId,
}) => {
  const { control, setValue, handleSubmit, watch, reset } = useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [sortPeriod, setSortPeriod] = useState([]);
  const dispatch = useDispatch();
  const { refetch } = useSelector((state) => state.refetch);
  const ID = useMemo(() => (rentalProductId ? rentalProductId : addArgId), []);

  const closeDialog = () => {
    setIsVisible(false);
    reset();
  };

  const showModal = () => {
    setIsVisible(true);
  };

  const createNewInvoice = (data) => {
    apiPost({
      url: `/payment-transaction/create?id=${ID}&company_accounts_id=${watch(
        'name',
      )}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Оплата успешно создана');
      closeDialog();
      dispatch(setRefetch(!refetch));
    });
  };

  useEffect(() => {
    if (!watch('period')) {
      setValue('period', 2);
    }
    apiGet({
      url: `/payment-transaction/calculate-period?id=${ID}&period=${watch(
        'period',
      )}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setValue('sum', res.data.sum);
      setValue('payment_from', res.data.start);
      setValue('payment_by', res.data.end);
    });
  }, [watch('period')]);

  useEffect(() => {
    apiGet({
      url: `/payment-transaction/get-company-accounts?id=${ID}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setValue('name', res.data.id);
    });
  }, [isVisible]);

  useEffect(() => {
    apiGet({
      url: '/payment-transaction/period-list',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setSortPeriod(res.data);
    });
  }, []);

  return (
    <div>
      {rentalProductId ? (
        <Tooltip title={'Создать счет на оплату'}>
          <IconButton
            color={'primary'}
            component='span'
            onClick={showModal}
            sx={{
              backgroundColor: '#fff',
              mr: 1,
              '&:hover': { backgroundColor: '#c2c2c2' },
            }}
          >
            <RequestQuoteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <StyledButton
          onClick={showModal}
          variant={'contained'}
        >
          Создать новую оплату
        </StyledButton>
      )}
      <Dialog
        fullWidth
        onClose={closeDialog}
        open={isVisible}
        maxWidth='sm'
        scroll='body'
      >
        <form>
          <DialogContent
            sx={{
              pb: 8,
              px: { xs: 2, sm: 4 },
              pt: { xs: 8, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography
                variant='h5'
                sx={{ mb: 3 }}
              >
                Создать новую оплату
              </Typography>
            </Box>
            <Controller
              name='name'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                // <FormControl fullWidth>
                <TextField
                  onChange={onChange}
                  value={value || ''}
                  select
                  fullWidth
                  label={'Куда направить средства'}
                >
                  {invoiceList.map((invoiceItem) => (
                    <MenuItem
                      key={invoiceItem.id}
                      value={invoiceItem.id}
                    >
                      {invoiceItem.name}
                    </MenuItem>
                  ))}
                </TextField>
                // </FormControl>
              )}
            />
            <Controller
              name='sum'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  sx={{ my: 2 }}
                  value={value || ''}
                  label='Сумма'
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
            <Controller
              name='period'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    // defaultValue=""
                    value={value || ''}
                    onChange={onChange}
                    select
                    fullWidth
                    label={'Период'}
                  >
                    {sortPeriod.map((period) => (
                      <MenuItem
                        key={period.key}
                        value={period.key}
                      >
                        {period.value}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
            <Controller
              name='comment'
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  sx={{ my: 2 }}
                  value={value || ''}
                  label='Комментарий'
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
            <Divider sx={{ my: 2 }}>Дата начала и конца оплаты</Divider>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 2,
                [theme.breakpoints.between(0, 460)]: {
                  flexDirection: 'column',
                  columnGap: 0,
                  rowGap: 2,
                },
              }}
            >
              <Controller
                name='payment_from'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    type={'date'}
                    value={dateReverseFormat(value)}
                    label='С какого'
                    onChange={onChange}
                    fullWidth
                  />
                )}
              />

              <Controller
                name='payment_by'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    type={'date'}
                    value={dateReverseFormat(value)}
                    label='По какое'
                    onChange={onChange}
                    fullWidth
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              variant='contained'
              sx={{ marginRight: 1 }}
              onClick={handleSubmit(createNewInvoice)}
            >
              Создать
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={closeDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default InvoiceForPaymentDialog;
