import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        height: 200,
        alignItems: 'center',
        my: 4,
      }}
    >
      <Box sx={{ fontSize: 24, textAlign: 'center' }}>
        У вас нет прав доступа к этой странице или такой страницы не существует
        &#129300;
      </Box>
      <Box sx={{ fontSize: 64, fontWeight: '900' }}>404</Box>
      <Button variant={'primary'}>
        <Link
          style={{ textDecoration: 'none', fontSize: 20, color: '#551a8b' }}
          to='/'
        >
          На главную
        </Link>
      </Button>
    </Box>
  );
};

export default Error;
