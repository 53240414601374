import React from 'react';
import EachContractInfo from '../../../layouts/eachContractInfo';

const EachContract = () => {
  return (
    <div>
      <EachContractInfo />
    </div>
  );
};

export default EachContract;
