import { useEffect, useState } from 'react';

export const usePermission = (permits) => {
  const [access, setAccess] = useState({});

  useEffect(() => {
    const userAccess = JSON.parse(window.localStorage.getItem('userAccess'));
    setAccess(userAccess);
  }, []);

  if (!permits.length) {
    throw new Error('usePermission() must have at least 1 parameter');
  }

  if (!permits[1]) {
    return access?.[permits[0]];
  }

  return access?.[permits[0]]?.[permits[1]];
};
