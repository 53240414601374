import React, { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { styles } from './styles';
import { FormError } from './FormError';

export const CustomSelect = ({
  name,
  rules,
  onClear,
  options = [],
  notFoundContent = 'Ничего не найдено',
  allOptions = false,
  label,
  icon,
  allowClear = true,
  handleChange,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isError = !!errors[name]?.message;
  const selectRef = useRef(null);

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => {
          const isClearIconVisible = allowClear && !!value;
          const changeInput = (e) => {
            const { value } = e.target;
            handleChange?.(name, value);
            onChange(value);
          };

          const resetFields = () => {
            handleChange?.(name, '');
            onChange('');
            onClear?.();
          };
          return (
            <Box sx={{ position: 'relative', width: 'fit-content' }}>
              <TextField
                label={label || 'Выберите опцию'}
                select
                InputProps={{
                  sx: {
                    textAlign: 'start',
                    width: '100%',
                    ...props?.InputProps?.sx,
                  },
                }}
                ref={selectRef}
                InputLabelProps={{
                  shrink: !!value,
                }}
                error={isError}
                onBlur={onBlur}
                onChange={changeInput}
                value={value || ''}
                {...props}
              >
                {allOptions && options.length && (
                  <MenuItem value=''>Все</MenuItem>
                )}
                {!!options?.length ? (
                  options?.map(({ value, key }) => (
                    <MenuItem
                      key={key}
                      value={key}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <div style={{ padding: '0 12px 0 12px', opacity: 0.5 }}>
                    {notFoundContent}
                  </div>
                )}
              </TextField>

              {isClearIconVisible && (
                <IconButton
                  size='small'
                  sx={{ ...styles.formClearIcon }}
                  onClick={resetFields}
                >
                  <CloseOutlined />
                </IconButton>
              )}

              <FormError name={name} />
            </Box>
          );
        }}
      />
    </FormControl>
  );
};
