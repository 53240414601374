import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGet } from '../../api';

const initialState = {
  authToken: '',
  isUserLogin: false,
  userList: [],
  userRole: '',
  eachUserData: [],
  allRoles: [],
  staff: [],
  userProfile: [],
  userFullName: null,
  userAccessData: [],
};

const userInfoSlice = createSlice({
  name: 'UserLogin',
  initialState,
  reducers: {
    setIsUserLogin: (state, action) => {
      state.isUserLogin = action.payload;
    },

    setUsersList: (state, action) => {
      state.userList = action.payload;
    },

    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },

    setStaff: (state, action) => {
      state.staff = action.payload;
    },

    setEachUserData: (state, action) => {
      state.eachUserData = action.payload;
    },

    setAllRoles: (state, action) => {
      state.allRoles = action.payload;
    },

    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },

    setUserFullName: (state, action) => {
      state.userFullName = action.payload;
    },

    setUserAccessData: (state, action) => {
      state.userAccessData = action.payload;
    },
  },
  extraReducers: {
    // [getAccessUser.pending]: () => console.log('pending'),
    // [getAccessUser.fulfilled]: () => console.log('fulfilled'),
    // [getAccessUser.rejected]: () => console.log('rejected'),
  },
});

export const {
  setUsersList,
  setStaff,
  setUserFullName,
  setUserRole,
  setEachUserData,
  setAllRoles,
  setUserProfile,
  setUserAccessData,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
