export const STATUS_TRANSACTION_COLORS = {
  1: '#ffffffd0',
  2: '#06bf1859',
  3: '#d2a9a9',
  4: '#0c899960',
};

export const STATUS_TRANSACTION_COLORS_TABLE = {
  1: 'transparent',
  2: '#ff7777',
  3: '#8af68f',
};
