import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import { setReloadEvents, setShowingDialog } from '../../../../store/app';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const ConfirmDialog = ({
  title,
  onHide = (e) => {},
  buttonTitle,
  event,
  name,
  colorBtn = 'abortButton',
}) => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.app.showDialog === name);

  const closeDialog = () => {
    dispatch(setShowingDialog(null));

    onHide();
    dispatch(setReloadEvents(true));
  };

  const confirmHandleClick = async () => {
    await event();
    closeDialog();
  };

  return (
    <div>
      <Dialog
        sx={{ zIndex: 99999 }}
        fullWidth
        open={isVisible}
        maxWidth='xs'
        scroll='body'
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            p: 3,
            position: 'relative',
            alignItems: 'center',
            fontSize: 24,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {title}
        </DialogContent>
        <DialogActions sx={{ p: 4, justifyContent: 'center' }}>
          <Button
            variant='contained'
            color={colorBtn}
            sx={{ marginRight: 1 }}
            onClick={confirmHandleClick}
          >
            {buttonTitle}
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
