import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

export const Spinner = ({ spinnerSize = 24, textBool = true }) => {
  return (
    <div
      style={{
        height: '86vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{ alignItems: 'center' }}
        spacing={2}
      >
        <CircularProgress size={spinnerSize} />

        {textBool && <span>Загрузка ...</span>}
      </Stack>
    </div>
  );
};
