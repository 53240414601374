import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientList: [],
  clientContract: [],
  clientInfo: [],
  clientAmountOnPage: 5,
  clientStatus: false,
  clientTypeStatus: null,
};

const clientInfoSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClientList: (state, action) => {
      state.clientList = action.payload;
    },

    setClientContract: (state, action) => {
      state.clientContract = action.payload;
    },

    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },

    setClientAmountOnPage: (state, action) => {
      state.clientAmountOnPage = action.payload;
    },

    setClientTypeStatus: (state, action) => {
      state.clientTypeStatus = action.payload;
    },

    setClientStatus: (state, action) => {
      state.clientStatus = action.payload;
    },
  },
});

export const {
  setClientList,
  setClientContract,
  setClientInfo,
  setClientAmountOnPage,
  setClientTypeStatus,
  setClientStatus,
} = clientInfoSlice.actions;

export default clientInfoSlice.reducer;
