import React, { useEffect, useState } from 'react';
import {
  ButtonGroup,
  IconButton,
  InputAdornment,
  Pagination,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import { apiGet } from '../../../../api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import errorHandler from '../../../layouts/errorHandler';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { theme } from '../../../common/componentColors';

const ButtonsGroupBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 620)]: {
    flexWrap: 'wrap',
  },
}));

const DatePickersBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 434)]: {
    marginTop: 10,
  },
}));

const DatePickersWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 615)]: {
    marginTop: 20,
  },
  [theme.breakpoints.between(0, 434)]: {
    flexDirection: 'column',
  },
}));

// const PaginationMedia = styled(Pagination)(({theme}) => ({
//     [theme.breakpoints.between(0, 426)]: {
//         '.css-1xu9kt2-MuiButtonBase-root-MuiPaginationItem-root': {
//             minWidth: 22, height: 22
//         }
//     },
// }));

const HistoryHeader = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 446)]: {
    flexWrap: 'wrap-reverse',
    justifyContent: 'center',
  },
}));

const HistoryHeaderInput = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.between(0, 446)]: {
    marginTop: 14,
    marginRight: 0,
  },
}));

const HistoryHeaderTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between(0, 446)]: {
    fontSize: 28,
  },
}));

const HistoryLog = () => {
  const [productLog, setProductLog] = useState([]);
  const [clientLog, setClientLog] = useState([]);
  const [buttonColor, setButtonColor] = useState(false);

  // Пагинация
  const [clientLastPage, setClientLastPage] = useState(1);
  const [productLastPage, setProductLastPage] = useState(1);
  const [clientPage, setClientPage] = useState(1);
  const [productPage, setProductPage] = useState(1);

  // Роли системы
  const [roleList, setRoleList] = useState([]);
  const [userOfRoles, setUserOfRoles] = useState([]);
  const [userId, setUserId] = useState('');

  // Поиск по событию
  const [searchField, setSearchField] = useState('');

  // Вывод пользователей
  const [isUserFetch, setIsUserFetch] = useState(false);

  // Даты для поиска
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchStopDate, setSearchStopDate] = useState(null);

  // Выбранная роль для поиска
  const [currentRole, setCurrentRole] = useState('');
  const [todayDate, setTodayDate] = useState('');
  const [userRole, setUserRole] = useState('');

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    let arr = [];
    const newDate = new Date().toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setTodayDate(arr.join('-'));
    const userRoleLocal = localStorage.getItem('userRole');
    setUserRole(userRoleLocal);
  }, []);

  const clientPaginationOnChange = (event, value) => {
    setClientPage(value);
  };

  const productPaginationOnChange = (event, value) => {
    setProductPage(value);
  };

  useEffect(() => {
    if (searchStopDate === null) {
      apiGet({
        url: `/product-log/index?page=${productPage}&role=${currentRole}&action=${searchField}&user_id=${userId}&limit=10`,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        setProductLog(res.data.models);
        setProductLastPage(res.data.lastPage);
      });

      // Получить всю историю лога клиента
      apiGet({
        url: `/client-log/index?page=${clientPage}&role=${currentRole}&action=${searchField}&user_id=${userId}&limit=10`,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        setClientLog(res.data.models);
        setClientLastPage(res.data.lastPage);
      });
    } else {
      // Получить всю историю лога товара
      apiGet({
        url: `/product-log/index?page=${productPage}&role=${currentRole}&fromDateCreate=${searchStartDate}&toDateCreate=${searchStopDate}&action=${searchField}&user_id=${userId}&limit=10`,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        setProductLog(res.data.models);
        setProductLastPage(res.data.lastPage);
      });

      // Получить всю историю лога клиента
      apiGet({
        url: `/client-log/index?page=${clientPage}&role=${currentRole}&fromDateCreate=${searchStartDate}&toDateCreate=${searchStopDate}&action=${searchField}&user_id=${userId}&limit=10`,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        setClientLog(res.data.models);
        setClientLastPage(res.data.lastPage);
      });
    }
  }, [
    productPage,
    clientPage,
    userId,
    searchField,
    searchStartDate,
    searchStopDate,
    currentRole,
  ]);

  const clientsOrProducts = (isColor) => {
    setButtonColor(isColor);
  };

  useEffect(() => {
    // Получение всех ролей системы
    apiGet({
      url: `/user-role/index`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setRoleList(res.data);
    });
  }, []);

  const chooseRoleOnChange = (e) => {
    setIsUserFetch(false);
    setCurrentRole(e.target.value);

    if (e.target.value === '') {
      setUserId('');
      return setIsUserFetch(false);
    }

    // Получение всех пользователей по роли
    apiGet({
      url: `/user/index?role=${e.target.value}`,
    }).then((res) => {
      if (res.error) return toast.error('ошибка получения пользователей');
      setUserOfRoles(res.data.models);
      setIsUserFetch(true);
    });
  };

  useEffect(() => {
    let arr = [];
    const newDate = new Date().toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setTodayDate(arr.join('-'));
  }, []);

  const chooseRoleofUserOnChange = (e) => {
    setUserId(e.target.value);
    setProductPage(1);
    setClientPage(1);
  };

  const searchFieldOnChange = (e) => {
    setSearchField(e.target.value);
  };

  const startDateSearch = (e) => {
    setSearchStartDate(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setSearchStartDate(arr.join('-'));
  };

  const stopDateSearch = (e) => {
    setSearchStopDate(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setSearchStopDate(arr.join('-'));
  };

  return (
    <div>
      <HistoryHeader
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <HistoryHeaderInput
          color={'secondary'}
          onChange={searchFieldOnChange}
          sx={{ mr: 5 }}
          fullWidth
          value={searchField}
          label={'Поиск по событию'}
          placeholder='Что ищем?'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                {searchField !== '' ? (
                  <IconButton onClick={() => setSearchField('')}>
                    <ClearRoundedIcon />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
        <HistoryHeaderTypography
          variant={'h4'}
          align={'center'}
        >
          История
        </HistoryHeaderTypography>
      </HistoryHeader>
      <br />
      <Divider />
      <br />
      <div>
        <Divider
          sx={{ my: 2 }}
          textAlign={'center'}
        >
          Поиск по пользователю
        </Divider>
        <Box>
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            onChange={chooseRoleOnChange}
            defaultValue={''}
            select
            label={'Роль'}
          >
            <MenuItem value={''}>Все</MenuItem>
            {roleList.map((item) => (
              <MenuItem
                key={item.role}
                value={item.role}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>

          {!isUserFetch ? (
            <></>
          ) : (
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              onChange={chooseRoleofUserOnChange}
              defaultValue={''}
              select
              label={'Пользователь'}
            >
              <MenuItem value={''}>Все</MenuItem>)
              {userOfRoles?.map((item) => (
                <MenuItem
                  key={item?.id}
                  value={item?.id}
                >
                  {item?.username}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
      </div>
      <ButtonsGroupBox
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: 1.5,
          alignItems: 'center',
        }}
        className='history-top'
      >
        <ButtonGroup
          disableElevation
          variant='contained'
          className='history-btn-group'
        >
          <Button
            sx={{ height: 40 }}
            color={!buttonColor ? 'secondary' : 'primary'}
            onClick={() => clientsOrProducts(false)}
          >
            Клиенты
          </Button>
          <Button
            sx={{ height: 40 }}
            color={buttonColor ? 'secondary' : 'primary'}
            onClick={() => clientsOrProducts(true)}
          >
            Товары
          </Button>
        </ButtonGroup>

        <DatePickersWrap
          sx={{
            display: 'flex',
            marginLeft: 1.5,
            [theme.breakpoints.between(0, 620)]: {
              marginTop: 2,
              marginLeft: 0,
            },
          }}
          className='history-datepicker-wrap'
        >
          <LocalizationProvider
            localeText={{
              okButtonLabel: 'Ок',
              cancelButtonLabel: 'Отменить',
            }}
            dateAdapter={AdapterDateFns}
            adapterLocale={ru}
          >
            <DatePickersBox sx={{ mr: 2 }}>
              <DatePicker
                inputProps={{
                  placeholder: 'дд.мм.гггг',
                }}
                name='date'
                label='С какого'
                value={searchStartDate}
                onChange={(newValue) => startDateSearch(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </DatePickersBox>
            <DatePickersBox>
              <DatePicker
                inputProps={{
                  placeholder: 'дд.мм.гггг',
                }}
                name='date'
                label='По какое'
                value={searchStopDate}
                onChange={(newValue) => stopDateSearch(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </DatePickersBox>
          </LocalizationProvider>
        </DatePickersWrap>
      </ButtonsGroupBox>
      <Divider
        sx={{ mb: 2, mt: 4 }}
        textAlign={'center'}
      >
        История
      </Divider>
      {!buttonColor ? (
        <>
          {clientLog.map((client) => (
            <Card
              raised
              key={client?.id}
              sx={{ my: 2, p: 2 }}
            >
              <Typography
                variant={'subtitle2'}
                align={'right'}
              >
                {client?.action}
              </Typography>

              <Divider />

              <Typography
                variant={'subtitle1'}
                color={'#70b7e0'}
                align={'right'}
              >
                {client?.createdAt}
              </Typography>

              <Divider
                textAlign={'left'}
                sx={{ my: 2 }}
              >
                Пользователь
              </Divider>

              <Box sx={{ alignItems: 'center' }}>
                <Typography variant={'subtitle2'}>
                  {client?.user?.email === null ? '' : client?.user?.email}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant={'subtitle2'}>
                    {client?.user?.profile?.surname === null
                      ? ''
                      : client?.user?.profile?.surname}
                  </Typography>

                  <Typography
                    sx={{ pl: 1 }}
                    variant={'subtitle2'}
                  >
                    {client?.user?.profile?.name === null
                      ? ''
                      : client?.user?.profile?.name}
                  </Typography>
                </Box>
                <Typography variant={'subtitle2'}>
                  {client?.user?.role[0]?.roleTranslate === null
                    ? ''
                    : client?.user?.role[0]?.roleTranslate}
                </Typography>
              </Box>
            </Card>
          ))}

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              siblingCount={!matches ? 0 : 1}
              size={!matches ? 'small' : 'medium'}
              onChange={clientPaginationOnChange}
              count={clientLastPage}
              variant='outlined'
              shape='rounded'
            />
          </Box>
        </>
      ) : (
        <>
          {productLog.map((product) => (
            <Card
              raised
              sx={{ my: 2, p: 2 }}
              key={product?.id}
            >
              <Box key={product?.id}>
                <Typography
                  variant={'subtitle2'}
                  align={'right'}
                >
                  {product?.action}
                </Typography>
                <Divider />
                <Typography
                  variant={'subtitle1'}
                  color={'#d7d7d7'}
                  align={'right'}
                >
                  {product?.createdAt}
                </Typography>
              </Box>

              <Divider
                textAlign={'left'}
                sx={{ my: 2 }}
              >
                Пользователь
              </Divider>

              <Box sx={{ alignItems: 'center' }}>
                <Typography variant={'subtitle2'}>
                  {product?.user?.email === null ? '' : product?.user?.email}
                </Typography>

                <Box sx={{ display: 'flex' }}>
                  <Typography variant={'subtitle2'}>
                    {product?.user?.profile?.surname === null
                      ? ''
                      : product?.user?.profile?.surname}
                  </Typography>
                  <Typography
                    sx={{ pl: 1 }}
                    variant={'subtitle2'}
                  >
                    {product?.user?.profile?.name === null
                      ? ''
                      : product?.user?.profile?.name}
                  </Typography>
                </Box>

                <Typography variant={'subtitle2'}>
                  {product?.user?.role[0]?.roleTranslate === null
                    ? ''
                    : product?.user?.role[0]?.roleTranslate}
                </Typography>
              </Box>
            </Card>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              siblingCount={!matches ? 0 : 1}
              onChange={productPaginationOnChange}
              count={productLastPage}
              variant='outlined'
              shape='rounded'
            />
          </Box>
        </>
      )}
    </div>
  );
};
// }

export default HistoryLog;
