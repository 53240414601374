import React, { useEffect, useState } from 'react';
import './index.scss';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box, CircularProgress, Container, TextField } from '@mui/material';
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ru } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip as Tooltips,
  Legend,
  Filler,
  ArcElement,
} from 'chart.js';

import { dataFillMount, optionsLine } from './statisticsData';
import { styled } from '@mui/material/styles';
import { apiGet } from '../../../../api';
import errorHandler from '../../../layouts/errorHandler';
import { useSelector } from 'react-redux';
import { editBalance } from '../../../../helpers/BalanceHelper';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltips,
  Legend,
  Filler,
  ArcElement,
  PointElement,
  LineElement,
);
const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.between(0, 750)]: {
    padding: 0,
  },
}));

const Statistics = () => {
  const [startDateBar, setStartDateBar] = useState('');
  const [endDateBar, setEndDateBar] = useState('');
  const open = useSelector((state) => state.layout.open);
  const [startDateBarSecond, setStartDateBarSecond] = useState('');
  const [endDateBarSecond, setEndDateBarSecond] = useState('');
  const [startDateLine, setStartDateLine] = useState('');
  const [endDateLine, setEndDateLine] = useState('');
  const [loaderChartsPie, setLoaderChartsPie] = useState(false);
  const [loaderChartsBar, setLoaderChartsBar] = useState(false);
  const [loaderChartsBarSecond, setLoaderChartsBarSecond] = useState(false);
  const [loaderChartsLine, setLoaderChartsLine] = useState(false);
  const [latePaymentsTotalSum, setLatePaymentsTotalSum] = useState(0);
  const [occupancy, setOccupancy] = useState([]);
  const [latePayments, setLatePayments] = useState([]);
  const [receiptPayments, setReceiptPayments] = useState([]);
  const [revenuePayments, setRevenuePayments] = useState([]);
  const [occupancyMonth, setOccupancyMonth] = useState([]);

  const changeStartBar = (e) => {
    setStartDateBar(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setStartDateBar(arr.join('-'));
  };
  const changeEndBar = (e) => {
    setEndDateBar(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setEndDateBar(arr.join('-'));
  };

  const changeStartBarSecond = (e) => {
    setStartDateBarSecond(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setStartDateBarSecond(arr.join('-'));
  };
  const changeEndBarSecond = (e) => {
    setEndDateBarSecond(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setEndDateBarSecond(arr.join('-'));
  };

  const changeStartLine = (e) => {
    setStartDateLine(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setStartDateLine(arr.join('-'));
  };
  const changeEndLine = (e) => {
    setEndDateLine(e);
    let arr = [];
    const newDate = new Date(e).toLocaleDateString('ru-RU').split('.');
    newDate.map((item) => {
      arr.unshift(item);
    });
    setEndDateLine(arr.join('-'));
  };

  const getPie = () => {
    setLoaderChartsPie(true);
    apiGet({
      url: `/statistics/occupancy-quantity`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setOccupancy(res.data);
      setLoaderChartsPie(false);
    });
  };

  const dataPie = {
    labels: [
      dataFillMount.freeLab,
      dataFillMount.busyLab,
      dataFillMount.unAvailLab,
    ],
    responsive: true,
    datasets: [
      {
        data: [occupancy.free, occupancy.busy, occupancy.unavailable],
        backgroundColor: [
          'rgba(106,225,119,0.7)',
          'rgba(253,241,60,0.65)',
          'rgba(255,0,0,1)',
        ],
        borderColor: ['rgb(255,255,255)'],
        borderWidth: 2,
      },
    ],
  };

  const getDoughnut = () => {
    apiGet({
      url: `/statistics/late-payments`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      if (res.data.models === null) {
        setLatePayments([]);
        setLatePaymentsTotalSum(0);
      } else {
        setLatePayments(res.data.models);
        setLatePaymentsTotalSum(res.data);
      }
    });
  };

  const dataDoughnut = {
    labels: latePayments?.map((i) => `${i?.days} / Платежей: ${i.count}`),
    responsive: true,
    datasets: [
      {
        data: latePayments?.map((i) => i?.sum),
        backgroundColor: [
          'rgba(26,250,1,1)',
          'rgba(255,169,0,1)',
          'rgb(246,221,12)',
          'rgb(227,66,7)',
          'rgba(255,0,0,1)',
        ],
        borderColor: ['rgb(255,255,255)'],
        borderWidth: 2,
      },
    ],
  };

  const optionsDoughnut = {
    responsive: true,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return context?.formattedValue + ' ₽';
          },
        },
      },
    },
  };

  useEffect(() => {
    setLoaderChartsBar(true);

    apiGet({
      url: `/statistics/monthly-payment-receipt?to=${endDateBar}&from=${startDateBar}`,
    }).then((res) => {
      if (res.error) {
        setLoaderChartsBar(false);
        return errorHandler(res.data);
      }
      if (res.data === null) {
        setReceiptPayments([]);
      } else {
        setReceiptPayments(res.data);
        setLoaderChartsBar(false);
      }
    });
  }, [endDateBar]);

  const dataBar = {
    labels: receiptPayments[0]?.period.map((m) =>
      m.date === null ? m.month : m.month + ' ' + m.date,
    ),
    responsive: true,
    datasets: receiptPayments.map((i) => ({
      label: i.labels,
      backgroundColor: i.color,
      data: i.period.map((item) => item.totalSum),
    })),
  };

  useEffect(() => {
    setLoaderChartsBarSecond(true);
    apiGet({
      url: `/statistics/revenue-by-month?to=${endDateBarSecond}&from=${startDateBarSecond}`,
    }).then((res) => {
      if (res.error) {
        setLoaderChartsBarSecond(false);
        return errorHandler(res.data);
      }
      if (res.data === null) {
        setRevenuePayments([]);
      } else {
        setRevenuePayments(res.data);
        setLoaderChartsBarSecond(false);
      }
    });
  }, [endDateBarSecond]);

  const dataBar2 = {
    labels: revenuePayments.map((m) =>
      m.date === null ? m.month : m.month + ' ' + m.date,
    ),
    responsive: true,
    datasets: [
      {
        label: 'Выручка',
        data: revenuePayments.map((sum) => sum.totalSum),
        backgroundColor: 'rgba(255, 179, 0,0.6)',
      },
    ],
  };

  useEffect(() => {
    setLoaderChartsLine(true);
    apiGet({
      url: `/statistics/occupancy-month?to=${endDateLine}&from=${startDateLine}`,
    }).then((res) => {
      if (res.error) {
        setLoaderChartsLine(false);
        return errorHandler(res.data);
      }
      if (res.data === null) {
        setReceiptPayments([]);
      } else {
        setOccupancyMonth(res.data);
        setLoaderChartsLine(false);
      }
    });
  }, [endDateLine]);

  const dataLine = {
    labels: occupancyMonth.map((m) =>
      m.date === null ? m.month : m.month + ' ' + m.date,
    ),
    responsive: true,
    datasets: [
      {
        label: 'Заполненность',
        data: occupancyMonth.map((sum) => sum.count),
        fill: true,
        backgroundColor: 'rgba(255, 179, 0,0.6)',
        borderColor: 'rgb(53, 162, 235)',
      },
    ],
  };

  useEffect(() => {
    getPie();
    getDoughnut();
  }, []);

  return (
    <div>
      <HeaderPage title='Статистика' />

      <StyledContainer className='charts-container'>
        <>
          <div className='charts-top'>
            <div className='charts'>
              <div className='charts-pie'>
                <h4 className='charts-title'>Заполненность по количеству</h4>
                {loaderChartsPie ? (
                  <div className='loader-charts-wrap'>
                    <CircularProgress color='primary' />
                  </div>
                ) : (
                  <>
                    <div className='charts-pie-canvas'>
                      <Pie data={dataPie} />
                    </div>
                    <div className='charts-pie-bottom'>
                      <Box className='charts-pie-bottom-head'>
                        <h4>
                          <span>{occupancy.busy}</span>
                          <br />
                          Занято
                        </h4>
                        <h4>
                          <span>{occupancy.free}</span>
                          <br />
                          Свободно
                        </h4>
                        <h4>
                          <span>{occupancy.unavailable}</span>
                          <br />
                          Недоступно
                        </h4>
                      </Box>
                      <Typography
                        sx={{
                          m: 0,
                          textAlign: 'center',
                          fontWeight: '600',
                          fontSize: 16,
                          marginTop: 1.5,
                          marginBottom: 2,
                        }}
                      >
                        Заполненность - {occupancy.occupancy_percent} %
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
            {latePayments.length === 0 ? (
              <></>
            ) : (
              <div className='charts'>
                <div
                  className='charts-doughnut'
                  style={{ position: 'relative' }}
                >
                  <h4 className='charts-title'>Просроченные платежи</h4>
                  {loaderChartsPie ? (
                    <div className='loader-charts-wrap'>
                      <CircularProgress color='primary' />
                    </div>
                  ) : (
                    <>
                      <div className='charts-doughnut-canvas'>
                        <Doughnut
                          data={dataDoughnut}
                          options={optionsDoughnut}
                        />
                      </div>
                      <div className='charts-totalsum'>
                        На сумму {editBalance(latePaymentsTotalSum?.total_sum)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <Box>
            <div className='charts'>
              <Box className='charts-bar'>
                <div className='charts-header'>
                  <h4 className='charts-title'>Поступление оплат по месяцам</h4>
                  <div className='charts-date'>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ru}
                    >
                      <Box
                        className='charts-date-wrap'
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ margin: 1 }}>
                          <DatePicker
                            label='С какого'
                            inputProps={{
                              placeholder: 'дд.мм.гггг',
                            }}
                            value={startDateBar || null}
                            onChange={(newValue) => changeStartBar(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        <Box sx={{ margin: 1 }}>
                          <DatePicker
                            label='По какое'
                            inputProps={{
                              placeholder: 'дд.мм.гггг',
                            }}
                            value={endDateBar || null}
                            onChange={(newValue) => changeEndBar(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </div>
                </div>
                {loaderChartsBar ? (
                  <div className='loader-charts-wrap-bottom'>
                    <CircularProgress color='primary' />
                  </div>
                ) : (
                  <Box
                    className={
                      !open ? 'overflow-canvas-show-menu' : 'overflow-canvas'
                    }
                  >
                    <Box sx={{ minWidth: 1120 }}>
                      <Bar data={dataBar} />
                    </Box>
                  </Box>
                )}
              </Box>
            </div>

            <Box className='charts'>
              <Box className='charts-bar-second'>
                <div className='charts-header'>
                  <h4 className='charts-title'>Выручка по месяцам</h4>
                  <div className='charts-date'>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ru}
                    >
                      <Box
                        className='charts-date-wrap'
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ margin: 1 }}>
                          <DatePicker
                            label='С какого'
                            inputProps={{
                              placeholder: 'дд.мм.гггг',
                            }}
                            value={startDateBarSecond || null}
                            onChange={(newValue) =>
                              changeStartBarSecond(newValue)
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        <Box sx={{ margin: 1 }}>
                          <DatePicker
                            label='По какое'
                            inputProps={{
                              placeholder: 'дд.мм.гггг',
                            }}
                            value={endDateBarSecond || null}
                            onChange={(newValue) =>
                              changeEndBarSecond(newValue)
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </div>
                </div>
                {loaderChartsBarSecond ? (
                  <div className='loader-charts-wrap-bottom'>
                    <CircularProgress color='primary' />
                  </div>
                ) : (
                  <Box
                    className={
                      !open ? 'overflow-canvas-show-menu' : 'overflow-canvas'
                    }
                  >
                    <Box sx={{ minWidth: 1120 }}>
                      <Bar data={dataBar2} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>

            <Box className='charts'>
              <Box className='charts-line'>
                <div className='charts-header'>
                  <h4 className='charts-title'>Заполненность по месяцам</h4>
                  <div className='charts-date'>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ru}
                    >
                      <Box
                        className='charts-date-wrap'
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ margin: 1 }}>
                          <DatePicker
                            label='С какого'
                            inputProps={{
                              placeholder: 'дд.мм.гггг',
                            }}
                            value={startDateLine || null}
                            onChange={(newValue) => changeStartLine(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        <Box sx={{ margin: 1 }}>
                          <DatePicker
                            label='По какое'
                            inputProps={{
                              placeholder: 'дд.мм.гггг',
                            }}
                            value={endDateLine || null}
                            onChange={(newValue) => changeEndLine(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </div>
                </div>
                {loaderChartsLine ? (
                  <div className='loader-charts-wrap-bottom'>
                    <CircularProgress color='primary' />
                  </div>
                ) : (
                  <Box
                    className={
                      !open ? 'overflow-canvas-show-menu' : 'overflow-canvas'
                    }
                  >
                    <Box sx={{ minWidth: 1120 }}>
                      <Line
                        options={optionsLine}
                        data={dataLine}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      </StyledContainer>
    </div>
  );
};

export default Statistics;
