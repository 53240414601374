import React, { useEffect } from 'react';
import TableContracts from '../../../common/tables/contracts';
import { useRedirect } from '../../../../hooks/useRedirect';

const Contracts = () => {
  useRedirect();

  return (
    <div>
      <TableContracts />
    </div>
  );
};

export default Contracts;
