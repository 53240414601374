import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  confirmDialog: false,
  showDialog: null,
  isCategoryPropChange: null,
  reloadEvents: null,
};

const appInfoSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setShowingDialog: (state, action) => {
      state.showDialog = action.payload;
    },

    setCategoryProp: (state, action) => {
      state.isCategoryPropChange = action.payload;
    },

    setReloadEvents: (state, action) => {
      state.reloadEvents = action.payload;
    },
  },
});

export const {
  setShowingDialog,
  setCategoryProp,
  setReloadEvents,
  setAuthToken,
} = appInfoSlice.actions;

export default appInfoSlice.reducer;
