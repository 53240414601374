import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const useRedirect = (role = 'driver') => {
  const navigate = useNavigate();

  useEffect(() => {
    const userRoleLocal = localStorage.getItem('userRole');
    if (userRoleLocal === role) {
      navigate('/taskPage');
    }
  }, []);
};
