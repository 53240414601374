import { Box } from '@mui/material';

export const ClientPaymentTransactionsTableFooter = ({
  received,
  exhibited,
  balanceClient,
}) => {
  return (
    <div style={{ display: 'flex', columnGap: 12, flexWrap: 'wrap' }}>
      <Box
        align='center'
        sx={{ fontWeight: '700', backgroundColor: '#fff' }}
      >
        Получено : {received || 0}
      </Box>

      <Box
        align='center'
        sx={{ fontWeight: '700', backgroundColor: '#fff' }}
      >
        Начислено : {exhibited || 0}
      </Box>

      <Box
        align='center'
        sx={{ fontWeight: '700', backgroundColor: '#fff' }}
      >
        Баланс : {balanceClient || 0}
      </Box>
    </div>
  );
};
